import { OrderType } from '../../components/DataTable/types';

export interface TransferItemEntry {
  id: string;
  merchant: string;
  durum: number;
  musteri: string;
  tam_adi: string;
  miktar: string;
  sure: string;
  backuser: string;
  creditCardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  birthDate: string;
  securityNumber: string;
  idNumber: string;
  verilen_credit_card_no: string;
}

export interface TransferItemInfoParams {
  id: string;
}

export interface CreditCardTransferItemInfoFields {
  creditCardDeposit: {
    backuser_id: number;
    backuser: string;
    merchant: string;
    durum: number;
    islem_id: string;
    musteri: string;
    tam_adi: string;
    olusturma: string;
    guncelleme: string;
    ip: string;
    musteri_cevrim_ici: boolean;
    musteri_son_durum: string;
    miktar: string;
    creditCardNumber: string;
    expirationMonth: string;
    expirationYear: string;
    birthDate: string;
    securityNumber: string;
    idNumber: string;
    verilen_credit_card_no: string;
  };
}

export const CreditCardTransferItemInfoInitial = {
  creditCardDeposit: {
    backuser_id: 0,
    backuser: '',
    merchant: '',
    durum: 0,
    islem_id: '',
    musteri: '',
    tam_adi: '',
    olusturma: '',
    guncelleme: '',
    ip: '',
    musteri_cevrim_ici: false,
    musteri_son_durum: '',
    miktar: '',
    creditCardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    birthDate: '',
    securityNumber: '',
    idNumber: '',
    verilen_credit_card_no: ''
  },
};

export interface TransferItemCreditCardRegisterFilterParams {
  isim: string;
  creditCardNumber: string;
  expirationMonth: string;
  expirationYear: string;
  birthDate: string;
  securityNumber: string;
  idNumber: string;
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export const TransferItemStatus = [
  'Müşteri hesap bilgisi bekliyor...',
  'Hesap bilgisi müşteriye gönderildi...',
  'Müşteri yatırdım butonuna bastı...',
  'Başarılı',
  'Reddedildi',
];

export const TransferItemStatusEnum = [
  'gray',
  'yellow',
  'green',
  'green',
  'red',
];
