import './LabeledContainer.scss';
import React from 'react';

interface LabeledContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
}

const LabeledContainer: React.FC<LabeledContainerProps> = ({
  label,
  children,
  style = {},
  className,
  ...props
}) => {
  return (
    <div
      style={{ ...style }}
      {...props}
      className={`labeled-container ${className ? className : ''}`}
    >
      {label && <div className="label-div">{label}:</div>}
      <div style={{ height: '100%' }}>{children}</div>
    </div>
  );
};

export default LabeledContainer;
