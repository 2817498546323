import React, { useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import { useActions, useSelector } from '../../redux/hooks';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing, omitIfEmpty } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

const CreateManuelDeposit: React.FC = () => {
  const {
    global: {
      merchantSelectList,
      bankSelectList,
      bankRegisterSelectListDeposit,
      paparaRegisterSelectListDeposit,
      cryptoRegisterSelectListDeposit
    },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);

  const { showMessage, setLoading } = useActions();

  const [merchant, setMerchant] = useState<SelectEntry>();
  const [method, setMethod] = useState('Havale');
  const [kullanici_id, setUserId] = useState('');
  const [kullanici_adi, setUserName] = useState('');
  const [tam_adi, setUserFullName] = useState('');
  const [kasa_id, setRegisterId] = useState<SelectEntry>();
  const [miktar, setAmount] = useState('');
  const [trx, setTrx] = useState('');
  const [data, setData] = useState('');
  const [banka_adi, setBank] = useState<SelectEntry>();

  const submitDisabledDeposit = isMissing(
    role_yonetim_listesi['Manuel İşlemler'] === '1'
      ? [
          merchant?.id,
          kasa_id?.id,
          banka_adi?.id,
          kullanici_id,
          kullanici_adi,
          tam_adi,
          miktar,
          trx,
        ]
      : [merchant?.id, kasa_id?.id, banka_adi?.id, tam_adi, miktar]
  );

  const submitDisabledPapara = isMissing(
    role_yonetim_listesi['Manuel İşlemler'] === '1'
      ? [
          merchant?.id,
          kasa_id?.id,
          kullanici_id,
          kullanici_adi,
          tam_adi,
          miktar,
          trx,
        ]
      : [merchant?.id, kasa_id?.id, tam_adi, miktar]
  );

  const submitDisabledKripto = isMissing(
    role_yonetim_listesi['Manuel İşlemler'] === '1'
      ? [
          merchant?.id,
          kasa_id?.id,
          kullanici_id,
          kullanici_adi,
          tam_adi,
          miktar,
          trx,
        ]
      : [merchant?.id, kasa_id?.id, tam_adi, miktar]
  );

  const clearInputs = () => {
    setMerchant(undefined);
    setUserId('');
    setUserName('');
    setUserFullName('');
    setRegisterId(undefined);
    setAmount('');
    setTrx('');
    setData('');
    setBank(undefined);
  };

  const createManuelDeposit = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'post',
        url: '/api/v1/manuel_yatirim',
        data: omitIfEmpty({
          merchant: merchant?.id,
          method,
          kullanici_id,
          kullanici_adi,
          tam_adi,
          kasa_id: kasa_id?.id,
          miktar,
          trx,
          data,
          banka_adi: banka_adi?.id,
        }),
      },
      showMessage
    );
    if (!rejected) {
      showMessage({
        message: 'Manuel Yatırım Yaratıldı',
        icon: 'information',
      });
      clearInputs();
    }
  };

  useEffect(() => {
    if (method !== 'Havale') {
      setBank(undefined);
    }
    setRegisterId(undefined);
  }, [method]);

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Manüel Yatırım Oluştur</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Merchant">
                <Select
                  value={merchant}
                  onChange={(entry) => setMerchant(entry)}
                  list={merchantSelectList}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Method">
                <select
                  value={method}
                  onChange={(event) => {
                    setMethod(event.target.value);
                  }}
                >
                  <option value="Havale">Havale</option>
                  <option value="Papara">Papara</option>
                  <option value="Kripto">Kripto</option>
                </select>
              </LabeledContainer>
            </Column>
          </Row>
          {role_yonetim_listesi['Manuel İşlemler'] === '1' && (
            <Row width="100%">
              <Column width="50%">
                <LabeledContainer label="Kullanıcı ID">
                  <input
                    value={kullanici_id}
                    onChange={(event) => setUserId(event.target.value)}
                  />
                </LabeledContainer>
              </Column>
              <Column width="50%">
                <LabeledContainer label="Kullanıcı Adı">
                  <input
                    value={kullanici_adi}
                    onChange={(event) => setUserName(event.target.value)}
                  />
                </LabeledContainer>
              </Column>
            </Row>
          )}
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Müşterinin Tam Adı">
                <input
                  value={tam_adi}
                  onChange={(event) => setUserFullName(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Kasa">
                {method === 'Havale' && (
                  <Select
                    value={kasa_id}
                    onChange={(entry) => setRegisterId(entry)}
                    list={bankRegisterSelectListDeposit}
                  />
                )}
                {method === 'Papara' && (
                  <Select
                    value={kasa_id}
                    onChange={(entry) => setRegisterId(entry)}
                    list={paparaRegisterSelectListDeposit}
                  />
                )}
                {method === 'Kripto' && (
                  <Select
                    value={kasa_id}
                    onChange={(entry) => setRegisterId(entry)}
                    list={cryptoRegisterSelectListDeposit}
                  />
                )}
              </LabeledContainer>
            </Column>
          </Row>
          {role_yonetim_listesi['Manuel İşlemler'] === '1' && (
            <Row width="100%">
              <Column width="50%">
                <LabeledContainer label="TRX">
                  <input
                    value={trx}
                    onChange={(event) => setTrx(event.target.value)}
                  />
                </LabeledContainer>
              </Column>
              <Column width="50%">
                <LabeledContainer label="Data">
                  <input
                    value={data}
                    onChange={(event) => setData(event.target.value)}
                  />
                </LabeledContainer>
              </Column>
            </Row>
          )}
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Miktar">
                <input
                  type="number"
                  value={miktar}
                  onChange={(event) => setAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              {method === 'Havale' && (
                <LabeledContainer label="Banka">
                  <Select
                    value={banka_adi}
                    onChange={(entry) => setBank(entry)}
                    list={bankSelectList}
                  />
                </LabeledContainer>
              )}
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await createManuelDeposit();
                setLoading(false);
              }}
              color={Color.green}
              disabled={
                method === 'Havale'
                  ? submitDisabledDeposit
                  : (method === 'Papara' ? submitDisabledPapara : submitDisabledKripto)
              }
            >
              Oluştur
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default CreateManuelDeposit;
