import React from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import Container from '../../components/utils/Container/Container';
import Row from '../../components/utils/Row/Row';
import Column from '../../components/utils/Column/Column';
import Button, { Color } from '../../components/Button/Button';
import { useAsyncInterval } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

interface DepositDetailProps {
  id: string;
  fetchDepositList: () => void;
  closeDetails: () => void;
}

const DepositDetail: React.FC<DepositDetailProps> = ({
  id,
  fetchDepositList,
  closeDetails,
}) => {
  const { showMessage, getDepositInfoThunk, setLoading } = useActions();

  const {
    global: {
      depositInfo: { islem_detayi, islem_hareketleri, api_loglari },
    },
    auth: {
      currentUser: { role_yonetim_listesi, id: currentUserId },
    },
  } = useSelector((state) => state);

  const updateDeposit = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/yatirim/${id}`,
        data: {
          aktarim: 'Manuel aktarilan',
        },
      },
      showMessage
    );
    if (!rejected) {
      fetchDepositList();
      closeDetails();
    }
  };

  useAsyncInterval(
    async () => {
      await getDepositInfoThunk({ id, hidden: true });
    },
    2000,
    []
  );

  if (
    islem_detayi.backuser_id !== currentUserId &&
    islem_detayi.backuser &&
    role_yonetim_listesi['Üstlenmiş Yatırım Görme/İşlem'] === '0'
  ) {
    return (
      <Row style={{ fontSize: '2rem', color: 'red' }} height="100%">
        <div>
          Bu işlem<strong> {islem_detayi.backuser}</strong> kullanıcısının
          üzerindedir!
        </div>
      </Row>
    );
  }

  return (
    <Row>
      <Column width="100%">
        <div
          style={{
            textAlign: 'center',
            fontSize: '1.5rem',
            marginBottom: '20px',
          }}
        >
          <strong>İşlem detayı #{id}</strong>
        </div>
        <Row width="100%">
          <Column width="33%"></Column>
          <Column style={{ textAlign: 'center' }} width="33%">
            <div>
              Method: <strong>{islem_detayi.method}</strong>
            </div>
          </Column>
          <Column width="33%"></Column>
        </Row>
        {islem_detayi.durum === 'Reddedildi' &&
          role_yonetim_listesi[
            'Yatırım detayında Manüel yatırım oluşturma butonu'
          ] === '1' && (
            <Row width="100%" height="60px">
              <Button
                onClick={async () => {
                  setLoading(true);
                  await updateDeposit();
                  setLoading(false);
                }}
                color={Color.red}
              >
                BU İŞLEMİ MANUEL OLARAK YENİDEN OLUŞTURARAK ONAYLA!
              </Button>
            </Row>
          )}
        <Row width="100%">
          <Column style={{ textAlign: 'center' }} width="50%">
            <div>
              İşlem ID: <strong>{islem_detayi.islem_id}</strong>
            </div>
          </Column>
          <Column style={{ textAlign: 'center' }} width="50%">
            <div>
              Merchant: <strong>{islem_detayi.merchant}</strong>
            </div>
          </Column>
        </Row>
        <Row width="100%">
          <Column style={{ textAlign: 'center' }} width="50%">
            <div>
              Durum:{' '}
              <strong
                style={{
                  ...(islem_detayi.durum === 'Reddedildi'
                    ? { color: 'red' }
                    : {}),
                  ...(islem_detayi.durum === 'Basarili'
                    ? { color: 'green' }
                    : {}),
                }}
              >
                {islem_detayi.durum}
              </strong>
            </div>
          </Column>
          <Column style={{ textAlign: 'center' }} width="50%">
            <div>
              Kullanıcı ID: <strong>{islem_detayi.kullanici_id}</strong>
            </div>
          </Column>
        </Row>
        <Row width="100%">
          <Column style={{ textAlign: 'center' }} width="50%">
            <div>
              Miktar: <strong>{islem_detayi.miktar}</strong>
            </div>
          </Column>
          <Column style={{ textAlign: 'center' }} width="50%">
            <div>
              Kullanıcı Adı: <strong>{islem_detayi.kullanici_adi}</strong>
            </div>
          </Column>
        </Row>
        <Row width="100%">
          <Column style={{ textAlign: 'center' }} width="50%">
            <div>
              Servis Ücreti: <strong>{islem_detayi.servis_ucreti}</strong>
            </div>
          </Column>
          <Column style={{ textAlign: 'center' }} width="50%">
            <div>
              Tam İsim: <strong>{islem_detayi.tam_isim}</strong>
            </div>
          </Column>
        </Row>
        <Row width="100%">
          <Column style={{ textAlign: 'center' }} width="50%">
            <div>
              Oluşturma Zamanı: <strong>{islem_detayi.olusturma}</strong>
            </div>
          </Column>
          <Column style={{ textAlign: 'center' }} width="50%">
            <div>
              Güncelleme Zamanı: <strong>{islem_detayi.guncelleme}</strong>
            </div>
          </Column>
        </Row>
        {role_yonetim_listesi['Yatırım Detay Logları'] === '1' && (
          <>
            <div
              style={{
                textAlign: 'center',
                fontSize: '1.5rem',
                marginBottom: '20px',
              }}
            >
              <strong>İşlem Hareketleri</strong>
            </div>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <table style={{ width: '100%' }} className="data-table">
                <thead>
                  <tr className="data-table-headers">
                    <th className="data-table-header">#</th>
                    <th className="data-table-header">Açıklama</th>
                    <th className="data-table-header">IP</th>
                    <th className="data-table-header">Zaman</th>
                  </tr>
                </thead>
                <tbody>
                  {islem_hareketleri.map(({ id, ip, aciklama, zaman }) => (
                    <tr key={id} className="data-table-row">
                      <td className="data-table-column">{id}</td>
                      <td className="data-table-column">{aciklama}</td>
                      <td className="data-table-column">{ip}</td>
                      <td className="data-table-column">{zaman}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        {role_yonetim_listesi['Yatırım API Logları'] === '1' && (
          <>
            <div
              style={{
                textAlign: 'center',
                fontSize: '1.5rem',
                margin: '20px 0',
              }}
            >
              <strong>Callback API Logları</strong>
            </div>
            {api_loglari.map(
              ({ log_id, callback_url, islem, request, response }) => (
                <Container key={log_id}>
                  <Row
                    style={{
                      justifyContent: 'flex-start',
                      borderTop: '1px solid black',
                      paddingTop: '15px',
                      marginTop: '20px',
                    }}
                    width="100%"
                  >
                    <div>
                      Log ID: <strong>{log_id}</strong>
                    </div>
                  </Row>
                  <Row style={{ justifyContent: 'flex-start' }} width="100%">
                    <div>
                      İşlem: <strong>{islem}</strong>
                    </div>
                  </Row>
                  <Row style={{ justifyContent: 'flex-start' }} width="100%">
                    <div>
                      Callback URL:{' '}
                      <div
                        style={{ backgroundColor: '#f2f3f5', color: '#F74B5C' }}
                      >
                        {callback_url}
                      </div>
                    </div>
                  </Row>
                  <Row width="100%">
                    <Column width="50%">
                      Request:
                      <textarea
                        style={{
                          height: '250px',
                          resize: 'none',
                          outline: 'none',
                        }}
                        readOnly={true}
                        value={`{${Object.entries(request).map(
                          ([key, value]) => `\n\t"${key}": "${value}"`
                        )}\n}`}
                      />
                    </Column>
                    <Column width="50%">
                      Response:
                      <textarea
                        style={{
                          height: '250px',
                          resize: 'none',
                          outline: 'none',
                        }}
                        readOnly={true}
                        value={`{${Object.entries(response).map(
                          ([key, value]) => `\n\t"${key}": "${value}"`
                        )}\n}`}
                      />
                    </Column>
                  </Row>
                </Container>
              )
            )}
          </>
        )}
      </Column>
    </Row>
  );
};

export default DepositDetail;
