import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataTableValue } from '../../../components/DataTable/types';
import { omitIfEmpty } from '../../../utils/utils';
import { BankRegisterDetailsParams } from '../../../pages/BankRegisters/types';
import { makeRequestThunk } from '../../../utils/request';

export const getBankRegisterDetailsThunk = createAsyncThunk(
  'global/getBankRegisterDetails',
  async (
    { page, ...params }: BankRegisterDetailsParams,
    { rejectWithValue }
  ): Promise<DataTableValue> => {
    const { data, rejected } = await makeRequestThunk<DataTableValue>(
      {
        method: 'get',
        url: '/api/v1/bankaKasaEkstre',
        params: {
          page,
          ...omitIfEmpty({ ...params }),
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
