import React, { ReactNode, useEffect, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import useDataTable from '../../components/DataTable/DataTable';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Button, { Color, Icon } from '../../components/Button/Button';
import { OrderType } from '../../components/DataTable/types';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import { makeRequest } from '../../utils/request';
import { isMissing } from '../../utils/utils';
import Modal from '../../components/Modal/Modal';
import BankRegistersInfo from '../BankRegisters/BankRegisterInfo';

const BanksSet: React.FC = () => {
  const { banksSetList, banksSetListLoading } = useSelector((state) => state.global);
  const {
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);

  const disableBankRegister = async (id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/bankSet/${id}`,
        data: {
          durum: 'Pasif',
          type: 'durum',
        },
      },
      showMessage
    );
    if (!rejected) {
      fetchBanksSetList();
    }
  };

  const enableBankRegister = async (id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/bankSet/${id}`,
        data: {
          durum: 'Active',
          type: 'durum',
        },
      },
      showMessage
    );
    if (!rejected) {
      fetchBanksSetList();
    }
  };

  const deleteBankRegister = async (id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'delete',
        url: `/api/v1/bankSet/${id}`,
      },
      showMessage
    );
    if (!rejected) {
      fetchBanksSetList();
    }
  };

  const [isBankListModalOpen, setIsBankListModalOpen] = useState(false);
  const { getBankSetThunk, showMessage, setLoading } = useActions();
  const [selectedId, setSelectedId] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [ad, setAd] = useState('');
  const [soyad, setSoyad] = useState('');
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');
  const [backuser, setBackuser] = useState('');
  const [durum, setStatus] = useState('true');
  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);
  const submitDisabled = isMissing([ad, soyad]);

  const fetchBanksSetList = () => {
    getBankSetThunk({
      page,
      pageCount: pagination,
      column,
      order,
      backuser,
      ad,
      soyad,
      durum
    });
  };

  const createBankSet = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'post',
        url: `/api/v1/banksSetList`,
        data: {
          ad,
          soyad
        },
      },
      showMessage
    );
    if (!rejected) {
      fetchBanksSetList();
      setAd('');
      setSoyad('');
    }
  };

  useEffect(() => {
    fetchBanksSetList();
  }, [page, order, column, pagination, backuser, ad, soyad, durum]);

  const showProcessColumn = true;

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    if (index == 7 && showProcessColumn) {
      return (
        <Column padding="0" margin="0">
          <Row width="100%" padding="0" height="40px">
            <Column width="50%">
              {row[4] === 'Active' && (
                <Button
                  onClick={async () => {
                    const result = window.confirm(
                      'Banka Setini pasife almak istediğinize emin misiniz?'
                    );
                    if (result) {
                      await disableBankRegister(row[0] as number);
                    }
                  }}
                  color={Color.purple}
                  icon={Icon.close}
                  tooltip="Pasif yap"
                  disabled={
                    role_yonetim_listesi['Banka Set Listesi'] === '0'
                  }
                />
              )}
              {row[4] !== 'Active' && (
                <Button
                  onClick={async () => {
                    const result = window.confirm(
                      'Banka Setini aktife almak istediğinize emin misiniz?'
                    );
                    if (result) {
                      await enableBankRegister(row[0] as number);
                    }
                  }}
                  color={Color.green}
                  icon={Icon.sync}
                  tooltip="Aktif yap"
                  
                />
              )}
            </Column>
            <Column width="50%" margin="0">
              <Button
                onClick={async () => {
                  const result = window.confirm(
                    'Banka Kasayı silmek istediğinize emin misiniz?'
                  );
                  if (result) {
                    await deleteBankRegister(row[0] as number);
                  }
                }}
                color={Color.red}
                icon={Icon.trash}
                tooltip="Kalıcı olarak sil"
                disabled={
                  role_yonetim_listesi['Banka Set Listesi'] === '0'
                }
              />
            </Column>
          </Row>
          <Row width="100%" padding="0" height="40px">
            <Column width="100%">
              <Button
                onClick={() => {
                  setSelectedId(row[0] as string);
                  setSelectedName(row[2] as string);
                  setSelectedName(row[1] + " " + row[2])
                  setIsBankListModalOpen(true);
                }}
                color={Color.green}
                icon={Icon.plus}
                tooltip="Bankaları Listele"
                disabled={
                  role_yonetim_listesi['Banka Set Listesi'] === '0' || row[4] === 0
                }
              />
            </Column>
          </Row>
        </Column>
      );
    }
    
  };

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Modal
        onChange={(isOpen) => {
          setIsBankListModalOpen(isOpen);
        }}
        open={isBankListModalOpen}
        width="60vw"
      >
        <BankRegistersInfo name={selectedName} />
      </Modal>
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Banka Kasa Set Listesi</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="40%">
              <LabeledContainer label="Ad">
                <input
                  value={ad}
                  onChange={(event) => setAd(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="40%">
              <LabeledContainer label="Soyad">
                <input
                  value={soyad}
                  onChange={(event) => setSoyad(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="20%">
              <Row padding="0" margin="auto 0" height="50px">
                <Button
                  onClick={async () => {
                    setLoading(true);
                    await createBankSet();
                    setLoading(false);
                  }}
                  color={Color.green}
                  disabled={submitDisabled}
                >
                  Ekle
                </Button>
              </Row>
            </Column>
          </Row>
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination,
                searchText
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
                setBackuser(searchText);
              }}
              value={banksSetList}
              loading={banksSetListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              extraColumns={
                showProcessColumn ? ['İşlem'] : []
              }
              search={true}
              download={true}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default BanksSet;
