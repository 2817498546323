import React, { useEffect, useState } from 'react';
import DPicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';
import Moment from 'moment';

interface DatePickerProps {
  onChange: (startDate: string, endDate: string) => void;
  initialDates?: [Date, Date] | undefined;
  value?: string | undefined;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  initialDates,
  value,
}) => {
  const [startDate, setStartDate] = useState<Date | undefined>(
    initialDates ? initialDates[0] : undefined
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    initialDates ? initialDates[1] : undefined
  );

  useEffect(() => {
    const startDateString =
      Moment(startDate).format('DD/MM/yyyy') !== 'Invalid date'
        ? Moment(startDate).format('DD/MM/yyyy')
        : '';
    const endDateString =
      Moment(endDate).format('DD/MM/yyyy') !== 'Invalid date'
        ? Moment(endDate).format('DD/MM/yyyy')
        : '';
    if (!startDate || !endDate) onChange('', '');
    else onChange(startDateString, endDateString);
  }, [startDate, endDate]);

  const onDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <div className="date-picker-container-div">
      <DPicker
        selected={startDate}
        onChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        dateFormat="dd/MM/yyyy"
        value={value}
      />
    </div>
  );
};

export default DatePicker;
