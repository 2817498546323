import { createAsyncThunk } from '@reduxjs/toolkit';
import { SelectEntry } from '../../../components/MultiSelect/types';
import { makeRequestThunk } from '../../../utils/request';

export interface BankEntry {
  id: number;
  isim: string;
  durum: boolean;
}

export const getBankListThunk = createAsyncThunk(
  'global/getBankList',
  async (_, { rejectWithValue }): Promise<SelectEntry[]> => {
    const {
      data: { results },
      rejected,
    } = await makeRequestThunk<{
      results: BankEntry[];
    }>(
      {
        method: 'get',
        url: '/api/v1/banks',
        params: {
          pageCount: 100000,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return results
      .filter((e) => e.durum)
      .map((e) => ({
        id: e.id,
        text: e.isim,
      }))
      .sort((a, b) => a.id - b.id);
  }
);
