import React, { FC, useEffect, useState } from "react";
import QRCode from 'qrcode';
import { useForm, SubmitHandler } from "react-hook-form";
import { object, string, TypeOf } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useActions, useSelector } from "../../redux/hooks";
import { makeRequest } from "../../utils/request";


type TwoFactorAuthProps = {
    otpauth_url: string;
    base32: string;
    user_id: string;
    closeModal: () => void;
    onInformationUpdate: (data: boolean) => void; // Prop for sending data to the parent
};

const twoFactorAuthSchema = object({
    token: string().min(1, "Authentication code is required"),
});

type TwoFactorAuthInput = TypeOf<typeof twoFactorAuthSchema>;

const TwoFactorAuth: FC<TwoFactorAuthProps> = ({
    otpauth_url,
    base32,
    user_id,
    closeModal,
    onInformationUpdate
}) => {
    const [qrcodeUrl, setqrCodeUrl] = useState("");
    const {
        auth: {
            currentUser,
        },
    } = useSelector((state) => state);
    const { setLoading, showMessage } = useActions();

    const {
        handleSubmit,
        register,
        formState: { errors },
        setFocus,
    } = useForm<TwoFactorAuthInput>({
        resolver: zodResolver(twoFactorAuthSchema),
    });

    const btnCancel = {
        padding: '0.5rem 1rem',
        backgroundColor: 'red',
        borderRadius: '0.25rem',
        color: 'white',
        cursor: 'pointer',
        width: '160px',
        border: 'none',
        margin: '0px 10px'
      }
      
    const btnConfirm = {
        padding: '0.5rem 1rem',
        backgroundColor: '#4caf50',
        color: '#fff',
        border: 'none',
        borderRadius: '0.25rem',
        cursor: 'pointer',
        width: '160px'
      }

    const verifyOtp = async (token: string) => {
        try {
            const { data } = await makeRequest<{ otp_verified: string; }>({
                method: "POST",
                url: "/api/otp/verify",
                data: {
                    token,
                    user_id,
                },
            }, showMessage);

            // store.setRequestLoading(false);
            // store.setAuthUser(user);
            closeModal();
            onInformationUpdate(true);
        } catch (error: any) {
            // store.setRequestLoading(false);
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.response.data.detail ||
                error.message ||
                error.toString();
            
            onInformationUpdate(false);
           
        }
    };

    const onSubmitHandler: SubmitHandler<TwoFactorAuthInput> = (values) => {
        verifyOtp(values.token);
    };

    useEffect(() => {
        QRCode.toDataURL(otpauth_url).then(setqrCodeUrl);
    }, []);

    useEffect(() => {
        setFocus("token");
    }, [setFocus]);

    return (
        <div aria-hidden={true} className="overflow" style={{ textAlign: 'center' }}>
            <div className="relative p-4 w-full max-w-xl h-full md:h-auto left-1/2 -translate-x-1/2">
                <div className="relative bg-white rounded-lg shadow">
                    <h3 style={{ color: '#3F51B5' }}>İki Faktörlü Kimlik Doğrulama (2FA)</h3>
                    <div className="p-6 space-y-4">
                        <div>
                        <ol>
                            <li>
                                Google Authenticator'ı (IOS - Android) veya Authy'yi (IOS - Android).
                            </li>
                            <li>Kimlik doğrulayıcı uygulamasında "+" simgesini seçin.</li>
                            <li>
                                "Barkod (veya QR kodu) tarayın" seçeneğini seçin ve bu barkodu taramak için telefonun kamerasını kullanın.
                            </li>
                        </ol>
                        </div>
                        <div>
                            <h4>QR Kodunu Tarat</h4>
                            <div className="flex justify-center">
                                <img
                                    className="block w-64 h-64 object-contain"
                                    src={qrcodeUrl}
                                    alt="qrcode url"
                                />
                            </div>
                        </div>
                        <div>
                            <h4>Veya Uygulamanıza Kod Girin</h4>
                            <p className="text-sm">Gizli Anahtar: {base32} (Base32 tarafından kodlanmış)</p>
                        </div>
                        <div>
                            <h4>Doğrulama Kodu</h4>
                            <p className="text-sm">Ayarı değiştirmek için lütfen kimlik doğrulama kodunu doğrulayın:</p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmitHandler)}>
                            <input
                                {...register("token")}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/4 p-2.5"
                                placeholder="Authentication Code"
                            />
                            <p className="mt-2 text-xs text-red-600">
                                {errors.token ? errors.token.message : null}
                            </p>

                            <div>
                                <button
                                    type="button"
                                    onClick={closeModal}
                                    style={btnCancel}
                                >
                                    Kapat
                                </button>
                                <button type="submit" style={btnConfirm}>
                                    Doğrula & Etkinleştir
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TwoFactorAuth;
