import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PayFixRegisterInfoFields,
  PayFixRegisterInfoParams,
} from '../../../pages/Payfix/types';
import { makeRequestThunk } from '../../../utils/request';

export const getPayFixRegisterInfoThunk = createAsyncThunk(
  'global/getPayFixRegisterInfo',
  async (
    { id }: PayFixRegisterInfoParams,
    { rejectWithValue }
  ): Promise<PayFixRegisterInfoFields> => {
    const { data, rejected } = await makeRequestThunk<PayFixRegisterInfoFields>(
      {
        method: 'get',
        url: `/api/v1/payfixKasa/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;
    return data
  }
);
