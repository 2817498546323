import { createAsyncThunk } from '@reduxjs/toolkit';
import { SelectEntry } from '../../../components/MultiSelect/types';
import { makeRequestThunk } from '../../../utils/request';

interface UserEntry {
  id: number;
  kullanici_adi: string;
  durum: boolean;
}

export const getWholeUserListThunk = createAsyncThunk(
  'global/getWholeUserListThunk',
  async (_, { rejectWithValue }): Promise<SelectEntry[]> => {
    const {
      data: { results },
      rejected,
    } = await makeRequestThunk<{ results: UserEntry[] }>(
      {
        method: 'get',
        url: '/api/userList',
        params: {
          pageCount: 100000,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;
    console.log(results);
    return results.map(({ id, kullanici_adi }) => ({ id, text: kullanici_adi })).sort((a, b) => a.id - b.id);
  }
);
