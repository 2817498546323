export interface CurrentPaparaFields {
  info: CurrentPaparaInfo[];
  price_range: [number, number];
  toplam_kasa: number;
  siradaki_kasa: number;
  ayrilmis_kasa: number;
}

interface CurrentPaparaFieldsWithoutDetails {
  info: CurrentPaparaInfo[];
}

export interface CurrentPaparaInfo {
  id: number;
  kasa_id: string;
  limit: string;
  hesap: string;
  bakiye: string;
  gelen: string;
  islem_adedi: string;
  kullanim: string;
  onay_sayisi: string;
  kisiler: string[];
  max_kullanici_sayisi: string;
  yatirim_kullanim_sayisi: string;
}

export interface CurrentPapara {
  toplam: number;
  siradaki1: CurrentPaparaFields;
  siradaki2: CurrentPaparaFields;
  siradaki3: CurrentPaparaFields;
  ayrilmisHesaplar: CurrentPaparaFieldsWithoutDetails;
}

export const CurrentPaparaInitial: CurrentPapara = {
  toplam: 0,
  siradaki1: {
    info: [],
    price_range: [0, 0],
    toplam_kasa: 0,
    siradaki_kasa: 0,
    ayrilmis_kasa: 0,
  },
  siradaki2: {
    info: [],
    price_range: [0, 0],
    toplam_kasa: 0,
    siradaki_kasa: 0,
    ayrilmis_kasa: 0,
  },
  siradaki3: {
    info: [],
    price_range: [0, 0],
    toplam_kasa: 0,
    siradaki_kasa: 0,
    ayrilmis_kasa: 0,
  },
  ayrilmisHesaplar: {
    info: [],
  },
};
