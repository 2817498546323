import React, { ReactNode, useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import { useActions, useSelector } from '../../redux/hooks';
import Button, { Color, Icon } from '../../components/Button/Button';
import { OrderType } from '../../components/DataTable/types';
import DataTable from '../../components/DataTable/DataTable';
import useDataTable from '../../components/DataTable/DataTable';
import { makeRequest } from '../../utils/request';

interface NewCreditCardTransferDetailTableProps {
  id: string;
}

const NewCreditCardTransferDetailTable: React.FC<NewCreditCardTransferDetailTableProps> = ({
  id,
}) => {
  const { newTransferCreditCardRegisters, newTransferCreditCardRegistersLoading } =
    useSelector((state) => state.global);

  const {
    getNewCreditCardTransferRegistersThunk,
    getNewCreditCardTransferInfoThunk,
    getNewCreditCardTransfersThunk,
    showMessage,
    setLoading,
  } = useActions();

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');

  const [isim, setName] = useState('');
  // const [trx_kodu, setTRXKodu] = useState('');
  // const [selectedCryptoType, setSelectedCryptoType] = useState('');
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [idNumber, setIdNumber] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState('');
  const [securityNumber, setSecurityNumber] = useState('');

  const fetchNewTransferCreditCardRegistersList = () => {
    getNewCreditCardTransferRegistersThunk({
      isim,
      // trx_kodu,
      // selectedCryptoType,
      creditCardNumber,
      idNumber,
      expirationMonth,
      expirationYear,
      securityNumber,
      birthDate,
      page,
      pageCount: pagination,
      order,
      column,
    });
  };

  useEffect(() => {
    fetchNewTransferCreditCardRegistersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination]);

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    console.log(index, value);
    console.log('something is wrong');
    if ([3, 6, 7, 8].includes(index)) {
      return value + ' TL';
    }
    if (index === 9) {
      if (value === true) {
        return 'Aktif';
      } else if (value === false) return 'Pasif';
    }
  };

  const onRowClick = async (index: number, row: React.ReactNode[]) => {
    const result = window.confirm(
      'Bu hesabı göndermek istediğinize emin misiniz?'
    );
    if (result) {
      setLoading(true);
      const { rejected } = await makeRequest(
        {
          method: 'patch',
          url: `/api/v1/transactions/creditCardDeposits/${id}`,
          data: {
            type: 'Hesap Gonderildi',
            cryptoKasa: row[0],
          },
        },
        showMessage
      );
      if (!rejected) {
        getNewCreditCardTransferInfoThunk({ id });
        getNewCreditCardTransfersThunk();
      }
      setLoading(false);
    }
  };


  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
      // reference must be same for addEventListener and removeEventListener
      // = can't use inline arrow function!
      function listenerNewTransferCreditCardRegister(event: { code: string; }) {
          if (event.code === 'Enter') {
            setItWorks(!itWorks);
            fetchNewTransferCreditCardRegistersList();
          }
      }
      fetchNewTransferCreditCardRegistersList();

      document.addEventListener('keypress', listenerNewTransferCreditCardRegister);
      return () => {
        document.removeEventListener('keypress', listenerNewTransferCreditCardRegister);
      }
  }, [!itWorks]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Column width="100%">
        <Row width="100%">
          <Column width="25%">
            <LabeledContainer label="İsim">
              <input
                value={isim}
                onChange={(event) => setName(event.target.value)}
              />
            </LabeledContainer>
          </Column>
          <Column width="25%">
            <LabeledContainer label="Kredi Kart No">
              <input
                type="number"
                value={creditCardNumber}
                onChange={(event) => setCreditCardNumber(event.target.value)}
              />
            </LabeledContainer>
          </Column>
          <Column width="25%">
            <LabeledContainer label="Kredi Kart No">
              <input
                type="number"
                value={expirationMonth}
                onChange={(event) => setExpirationMonth(event.target.value)}
              />
            </LabeledContainer>
          </Column>
          <Column width="25%">
            <LabeledContainer label="Kredi Kart No">
              <input
                type="number"
                value={expirationYear}
                onChange={(event) => setExpirationYear(event.target.value)}
              />
            </LabeledContainer>
          </Column>
          <Column width="25%">
            <LabeledContainer label="Kredi Kart No">
              <input
                type="number"
                value={expirationYear}
                onChange={(event) => setExpirationYear(event.target.value)}
              />
            </LabeledContainer>
          </Column>
          <Column width="25%">
            <LabeledContainer label="Kredi Kart No">
              <input
                type="number"
                value={securityNumber}
                onChange={(event) => setSecurityNumber(event.target.value)}
              />
            </LabeledContainer>
          </Column>
          <Column width="25%">
            <LabeledContainer label="Kredi Kart No">
              <input
                type="number"
                value={idNumber}
                onChange={(event) => setIdNumber(event.target.value)}
              />
            </LabeledContainer>
          </Column>
          <Column width="25%">
            <LabeledContainer label="Kredi Kart No">
              <input
                type="number"
                value={birthDate}
                onChange={(event) => setBirthDate(event.target.value)}
              />
            </LabeledContainer>
          </Column>
          <Column width="50%" height="80px">
            <Row height="40px" padding="0">
              <Button
                onClick={() => {
                  setItWorks(!itWorks);
                  if (page === 1) fetchNewTransferCreditCardRegistersList();
                  setCurrentPage(1);
                }}
                color={Color.orange}
                icon={Icon.search}
                loading={newTransferCreditCardRegistersLoading}
              >
                Filtrele
              </Button>
            </Row>
          </Column>
        </Row>
        <Row width="100%">
          <DataTable
            onPageUpdate={(pageNumber, orderColumn, orderType, pagination) => {
              setCurrentPage(pageNumber);
              setOrderColumn(orderColumn);
              setOrderType(orderType);
              setPagination(pagination);
            }}
            value={newTransferCreditCardRegisters}
            loading={newTransferCreditCardRegistersLoading}
            page={page}
            pagination={pagination}
            onColumnRender={onColumnRender}
            onRowClick={onRowClick}
          />
        </Row>
      </Column>
    </Row>
  );
};

export default NewCreditCardTransferDetailTable;
