import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequestThunk } from '../../utils/request';

interface loginProps {
  email: string;
  password: string;
}

interface LoginResponse {
  otp_enabled: boolean; // Adjust the type as per your actual response
  // Add other properties if present in the response
}

export const loginThunk = createAsyncThunk(
  'auth/login',
  async (
    { email, password }: loginProps,
    { rejectWithValue }
  ): Promise<any> => {
    const { rejected, data } = await makeRequestThunk<LoginResponse>(
      {
        method: 'POST',
        url: '/api/login',
        data: {
          email,
          password,
        },
      },
      rejectWithValue
    );

    
    
    if (rejected) return rejected;
  }
);
