import { createAsyncThunk } from '@reduxjs/toolkit';
import { CurrentUserFields } from '../../../pages/Users/types';
import { makeRequestThunk } from '../../../utils/request';

export const getCurrentUserThunk = createAsyncThunk(
  'global/getCurrentUser',
  async (_, { rejectWithValue }): Promise<CurrentUserFields> => {
    const { data, rejected } = await makeRequestThunk<CurrentUserFields>(
      {
        method: 'get',
        url: `/api/user`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
