import React from 'react';

interface StatusIconProps {
  status: StatusIconKey;
}

export type StatusIconKey = 'approved' | 'rejected' | 'waiting';

enum StatusIconEnum {
  approved = 'check',
  rejected = 'times',
  waiting = 'hourglass-half',
}

enum StatusIconTitleEnum {
  approved = 'Onaylandı',
  rejected = 'Reddedildi',
  waiting = 'Beklemede',
}

const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
  return (
    <div className={`data-table-status-icon ${status} tooltip`}>
      <span className="tooltiptext">{StatusIconTitleEnum[status]}</span>
      <i className={`las la-${StatusIconEnum[status]}`} />
    </div>
  );
};

export default StatusIcon;
