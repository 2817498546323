import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CryptoRegisterInfoFields,
  CryptoRegisterInfoParams,
} from '../../../pages/CryptoRegisters/types';
import { makeRequestThunk } from '../../../utils/request';

export const getCryptoRegisterInfoThunk = createAsyncThunk(
  'global/getCryptoRegisterInfo',
  async (
    { id }: CryptoRegisterInfoParams,
    { rejectWithValue }
  ): Promise<CryptoRegisterInfoFields> => {
    const { data, rejected } = await makeRequestThunk<CryptoRegisterInfoFields>(
      {
        method: 'get',
        url: `/api/v1/cryptoKasa/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;
    
    console.log('asdasdadlaskhdla ', data);
    return data;
  }
);
