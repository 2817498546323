import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequestThunk } from '../../../utils/request';

export interface BankRegisterEntry {
  id: number;
  isim: string;
  banka: string;
  bakiye: string;
  durum: boolean;
  yatirim_adet: string;
  cekim_adet: string;
}

export const getBankRegisterListThunk = createAsyncThunk(
  'global/getBankRegisterList',
  async (_, { rejectWithValue }): Promise<BankRegisterEntry[]> => {
    const {
      data: { results },
      rejected,
    } = await makeRequestThunk<{
      results: BankRegisterEntry[];
    }>(
      {
        method: 'get',
        url: '/api/v1/bankaKasa',
        params: {
          pageCount: 100000,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return results;
  }
);
