import './styles.scss';
import React from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import { useSelector } from '../../redux/hooks';
import Column from '../../components/utils/Column/Column';
import CreditCardTransferItem from './CreditCardTransferItem';

const NewCreditCardTransfer: React.FC = () => {
  const { newCreditCardTransferList } = useSelector((state) => state.global);

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Yeni Yatırım İşlemleri</strong>
            </h3>
          </div>
        }
      >
        <Column width="90%">
          {newCreditCardTransferList.map((transferItem, index) => {
            return (
              <CreditCardTransferItem
                key={transferItem.id}
                transferItem={transferItem}
              />
            );
          })}
        </Column>
      </Box>
    </Row>
  );
};

export default NewCreditCardTransfer;
