import React, { useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { SelectEntry } from '../../components/MultiSelect/types';

const EditMFTRegister: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isim, setName] = useState('');
  const [hesap_no, setAccount] = useState('');
  const [mail_adresi, setMailAddress] = useState('');
  const [mail_sifresi, setMailPassword] = useState('');
  const [min_yatirim, setMinDeposit] = useState('');
  const [max_yatirim, setMaxDeposit] = useState('');
  const [durum, setStatus] = useState('true');
  const [ozel_not, setNote] = useState('');
  const [mail_server, setMailServer] = useState('');
  const [yatirim_adet, setDepositAmount] = useState('');
  const [cekim_adet, setWithdrawAmount] = useState('');
  const [gelen, setIncoming] = useState('');
  const [giden, setOutgoing] = useState('');
  const [manuel, setManuel] = useState('');
  const [pasife_alinma_nedeni, setDisableReason] = useState('');
  const [odeme_alma_durumu, setPaymentStatus] = useState('');
  const [aylik_komisyonsuz_max_tutar, setMonthlyCommissionMaxAmount] =
    useState('');
  const [kullanan_kisi_sayisi, setUserCount] = useState('');
  const [kullanim_yatirim_sayisi, setUserDepositCount] = useState('');
  const [selectedMerchants, setSelectedMerchants] = useState<SelectEntry[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<SelectEntry[]>([]);

  const submitDisabled = isMissing([
    isim,
    hesap_no,
    cekim_adet,
    yatirim_adet,
    gelen,
    giden,
    kullanan_kisi_sayisi,
    kullanim_yatirim_sayisi,
    manuel,
    mail_adresi,
    mail_sifresi,
    min_yatirim,
    max_yatirim,
  ]);

  const {
    getMFTRegisterInfoThunk,
    showMessage,
    setLoading,
    getWholeMerchantListThunk,
    getWholeUserListThunk
  } = useActions();

  const { mftRegisterInfo, merchantWholeSelectList, userWholeSelectList } = useSelector(
    (state) => state.global
  );

  const getMFTRegisterInfo = async () => {
    setLoading(true);
    await getMFTRegisterInfoThunk({ id });
    await getWholeMerchantListThunk();
    await getWholeUserListThunk();
    setLoading(false);
  };

  useEffect(() => {
    getMFTRegisterInfo();
  }, []);

  useEffect(() => {
    setName(mftRegisterInfo.isim);
    setAccount(mftRegisterInfo.hesap_no);
    setMailAddress(mftRegisterInfo.mail_adresi);
    setMailPassword(mftRegisterInfo.mail_sifresi);
    setMinDeposit(mftRegisterInfo.min_yatirim);
    setMaxDeposit(mftRegisterInfo.max_yatirim);
    setStatus(mftRegisterInfo.durum);
    setNote(mftRegisterInfo.ozel_not);
    setMailServer(mftRegisterInfo.mail_server);
    setDepositAmount(mftRegisterInfo.yatirim_adet);
    setWithdrawAmount(mftRegisterInfo.cekim_adet);
    setIncoming(mftRegisterInfo.gelen);
    setOutgoing(mftRegisterInfo.giden);
    setManuel(mftRegisterInfo.manuel);
    setDisableReason(mftRegisterInfo.pasife_alinma_nedeni);
    setPaymentStatus(mftRegisterInfo.odeme_alma_durumu);

    setMonthlyCommissionMaxAmount(
      mftRegisterInfo.aylik_komisyonsuz_max_tutar
    );
    setUserCount(mftRegisterInfo.kullanan_kisi_sayisi);
    setUserDepositCount(mftRegisterInfo.kullanim_yatirim_sayisi);
  }, [mftRegisterInfo]);

  useEffect(() => {
    if (merchantWholeSelectList.length > 0) {
      setSelectedMerchants(
        merchantWholeSelectList.filter(
          ({ id }) =>
            mftRegisterInfo.merchants.findIndex((e) => e == id) !== -1
        )
      );
    }
  }, [mftRegisterInfo, merchantWholeSelectList.length]);

  useEffect(() => {
    if (userWholeSelectList.length > 0) {
      setSelectedUsers(
        userWholeSelectList.filter(
          ({ id }) =>
          mftRegisterInfo.users.findIndex((e) => e == id) !== -1
        )
      );
    }
  }, [mftRegisterInfo, userWholeSelectList.length]);

  const editMFTRegister = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'put',
        url: `/api/v1/mftKasa/${id}`,
        data: {
          isim,
          hesap_no,
          yatirim_adet,
          cekim_adet,
          gelen,
          giden,
          manuel,
          mail_adresi,
          mail_sifresi,
          min_yatirim,
          max_yatirim,
          durum,
          ozel_not,
          pasife_alinma_nedeni,
          mail_server,
          odeme_alma_durumu,
          aylik_komisyonsuz_max_tutar,
          kullanan_kisi_sayisi,
          kullanim_yatirim_sayisi,
          merchants: selectedMerchants.map(({ id }) => id),
          users: selectedUsers.map(({id}) => id)
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/mft-kasalar');
    }
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>MFT Kasa Oluştur</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Hesap İsmi">
                <input
                  value={isim}
                  onChange={(event) => setName(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Hesap No">
                <input
                  type="number"
                  value={hesap_no}
                  onChange={(event) => setAccount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Yatırım Adedi">
                <input
                  type="number"
                  value={yatirim_adet}
                  onChange={(event) => setDepositAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Çekim Adedi">
                <input
                  type="number"
                  value={cekim_adet}
                  onChange={(event) => setWithdrawAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Gelen Toplam">
                <input
                  type="number"
                  value={gelen}
                  onChange={(event) => setIncoming(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Giden Toplam">
                <input
                  type="number"
                  value={giden}
                  onChange={(event) => setOutgoing(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Manuel Toplam">
                <input
                  type="number"
                  value={manuel}
                  onChange={(event) => setManuel(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Mail Adresi">
                <input
                  type="email"
                  value={mail_adresi}
                  onChange={(event) => setMailAddress(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Mail Şifresi">
                <input
                  value={mail_sifresi}
                  onChange={(event) => setMailPassword(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Min. Yatırım">
                <input
                  value={min_yatirim}
                  onChange={(event) => setMinDeposit(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Max. Yatirim">
                <input
                  value={max_yatirim}
                  onChange={(event) => setMaxDeposit(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Durum">
                <select
                  value={durum}
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <option value="true">Aktif</option>
                  <option value="false">Pasif</option>
                </select>
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Ödeme Alma Durumu">
                <select
                  value={odeme_alma_durumu}
                  onChange={(event) => setPaymentStatus(event.target.value)}
                >
                  <option value="Tamamen Ucretsiz">Tamamen Ücretsiz</option>
                  <option value="MFT Komisyon Aliyor">
                    MFT Komisyon Alıyor
                  </option>
                </select>
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Aylık Komisyonsuz Max Ulaşabileceği Tutar:(Nedir?)">
                <input
                  type="number"
                  value={aylik_komisyonsuz_max_tutar}
                  onChange={(event) =>
                    setMonthlyCommissionMaxAmount(event.target.value)
                  }
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Özel Not">
                <input
                  value={ozel_not}
                  onChange={(event) => setNote(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Pasife Alınma Nedeni">
                <input
                  value={pasife_alinma_nedeni}
                  onChange={(event) => setDisableReason(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Kullanım Kişi Count">
                <input
                  type="number"
                  value={kullanan_kisi_sayisi}
                  onChange={(event) => setUserCount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Kullanım Yatırım Count">
                <input
                  type="number"
                  value={kullanim_yatirim_sayisi}
                  onChange={(event) => setUserDepositCount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Mail Server">
                <select
                  value={mail_server}
                  onChange={(event) => setMailServer(event.target.value)}
                >
                  <option value="Yandex Mail">Yandex Mail</option>
                  <option value="SystemX Pro Mail">SystemX Pro Mail</option>
                </select>
              </LabeledContainer>
            </Column>
            <Column width="50%"></Column>
          </Row>
          <Row>
            <Column width="100%">
              <LabeledContainer label="Merchantlar">
                <MultiSelect
                  value={selectedMerchants}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedMerchants(selectedElements);
                  }}
                  list={merchantWholeSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row>
            <Column width="100%">
              <LabeledContainer label="Userlar">
                <MultiSelect
                  value={selectedUsers}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedUsers(selectedElements);
                  }}
                  list={userWholeSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await editMFTRegister();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Düzenle
            </Button>
          </Row>
          {/*<Row width="100%" height="60px">
            <Button
              onClick={async () => {
              }}
              color={Color.orange}
            >
              Yeni Yatırım Var mı Kontrol Et
            </Button>
          </Row>*/}
        </Column>
      </Box>
    </Row>
  );
};

export default EditMFTRegister;
