import React, { ReactNode, useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { useActions, useSelector } from '../../redux/hooks';
import { SelectEntry } from '../../components/MultiSelect/types';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color, Icon } from '../../components/Button/Button';
import useDataTable from '../../components/DataTable/DataTable';
import DatePicker from '../../components/DatePicker/DatePicker';
import StatusIcon, {
  StatusIconKey,
} from '../../components/DataTable/StatusIcon';
import { OrderType } from '../../components/DataTable/types';
import Modal from '../../components/Modal/Modal';
import DepositDetail from './DepositDetail';
import { makeRequest } from '../../utils/request';
import { DepositInfoFields } from './types';

const Deposits: React.FC = () => {
  const {
    global: { merchantSelectList, depositList, depositListLoading },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);
  const [selectedMerchants, setSelectedMerchants] = useState<SelectEntry[]>([]);
  const { getDepositsThunk, getDepositInfoThunk, setLoading, showMessage } = useActions();

  const [currentId, setCurrentId] = useState('');

  const [islem_id, setProcessId] = useState('');
  const [kullanici_id, setUserId] = useState('');
  const [kullanici_adi, setUserName] = useState('');
  const [tam_isim, setFullName] = useState('');
  const [method, setMethod] = useState('');
  const [yatirim_durum, setStatus] = useState('');
  const [aktarim, setTransfer] = useState('');
  const [ip, setIP] = useState('');
  const [olusturma, setCreateDate] = useState('');
  const [guncelleme, setUpdateDate] = useState('');
  const [merchantCommaList, setMerchantCommaList] = useState('');

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);


  useEffect(() => {
    if (merchantSelectList.length > 0) setSelectedMerchants(merchantSelectList);
  }, [merchantSelectList]);

  useEffect(() => {
    setMerchantCommaList(selectedMerchants.map((e) => e.id).join(','));
  }, [selectedMerchants]);

  const updateTransferStatusToManuel = async (id: string) => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/yatirim/${id}`,
        data: {
          aktarim: 'Manuel aktarilan',
        },
      },
      showMessage
    );
    if (!rejected) {
      fetchDepositList();
    }
  };

  const copyDepositInfo = async (row: React.ReactNode[]) => {
    const { data, rejected } = await makeRequest<DepositInfoFields>(
      {
        method: 'get',
        url: `/api/v1/yatirim/${row[0]}`,
        params: {
          hidden: true,
        },
      },
      showMessage
    );
    if (!rejected) {
      await window.navigator.clipboard.writeText(
        `Müşteri Tam Adı:${row[7]}
        Miktar: ${row[4]}
        Yatırılan Banka Adı: ${row[8]}
        Banka Kasa İsmi: ${data.islem_detayi.kasa_adi}
        Merchant: ${row[3]}
        Method: Yatırım`
      );
      showMessage({
        message: 'Bilgiler panoya kopyalandı',
        icon: 'information',
      });
    }
  };

  const onHeaderRender = (value: string, index: number) => {
    if (index === 10) return 'Detay';
  };

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    if (index === 0) {
      let statusIcon: StatusIconKey = 'approved';
      if (value === 'Onaylandi') {
        statusIcon = 'approved';
      } else if (value === 'Beklemede') {
        statusIcon = 'waiting';
      } else if (value === 'Reddedildi') {
        statusIcon = 'rejected';
      }
      return (
        <Row width="100%" margin="0" padding="0">
          <Column>
            <StatusIcon status={statusIcon} />
          </Column>
        </Row>
      );
    }
    if ([3, 4].includes(index)) {
      return value + ' TL';
    }
    if (index === 10) {
      return (
        <Row width="100%" margin="0" padding="0">
          <Column width="35px" height="35px" margin="0" padding="0">
            <Button
              onClick={async () => {
                setCurrentId(row[0] as string);
                setLoading(true);
                await getDepositInfoThunk({ id: row[0] as string });
                setLoading(false);
                setIsDetailsOpen(true);
              }}
              color={Color.orange}
              icon={Icon.search}
              disabled={role_yonetim_listesi['Yatırım Bilgileri'] === '0'}
            />
          </Column>
          {row[1] === 'Onaylandi' && value === 'API ile aktarilan' && (
            <Column width="35px" height="35px" margin="0 0 0 10px" padding="0">
              <Button
                onClick={() => {}}
                color={Color.green}
                icon={Icon.checkCircle}
                tooltip="Hesabına API ile aktarıldı!"
              />
            </Column>
          )}
          {row[1] === 'Onaylandi' && value === 'Manuel aktarilan' && (
            <Column width="35px" height="35px" margin="0 0 0 10px" padding="0">
              <Button
                onClick={() => {}}
                color={Color.purple}
                icon={Icon.checkCircle}
                tooltip="Hesabına manüel aktarıldı!"
              />
            </Column>
          )}
          {row[1] === 'Onaylandi' && value === 'Aktarilmamis' && (
            <Column width="35px" height="35px" margin="0 0 0 10px" padding="0">
              <Button
                onClick={async () => {
                  const result = window.confirm(
                    'Bakiyeyi üyenin hesabına manüel olarak aktardığınıza ve bu işlemin bakiye transferi durumunu Manüel aktarıldı olarak kaydetmek istediğinize emin misiniz?'
                  );
                  if (result) {
                    await updateTransferStatusToManuel(row[0] as string);
                  }
                }}
                color={Color.red}
                icon={Icon.exclamation}
                tooltip="Hesabına aktarılmadı! Bakiyeyi üyenin hesabına manüel olarak aktardığımı ve daha sonra bu butona tıkladığımı onaylıyorum!"
                disabled={
                  role_yonetim_listesi[
                    'Yatırım Manüel Hesabına Aktardım Butonu'
                  ] === '0'
                }
              />
            </Column>
          )}
        </Row>
      );
    }

    if (index === 11) {
      return (
        <Row width="100%" margin="0" padding="0">
          <Column width="35px" height="35px" margin="0" padding="0">
            <Button
              onClick={async () => {
                await copyDepositInfo(row);
              }}
              color={Color.gray}
              icon={Icon.copy}
            />
          </Column>
        </Row>
      );
    }
  };


  const fetchDepositList = () => {
    if (role_yonetim_listesi['Havale İşlemlerini Görme'] === "1" && role_yonetim_listesi['Papara İşlemlerini Görme'] === "0") setMethod('Havale');
    else if (role_yonetim_listesi['Papara İşlemlerini Görme'] === '1' && role_yonetim_listesi['Havale İşlemlerini Görme'] === "0") setMethod('Papara');
    
    getDepositsThunk({
      islem_id,
      kullanici_id,
      kullanici_adi,
      tam_isim,
      ip,
      method,
      yatirim_durum,
      aktarim,
      olusturma: olusturma ? olusturma.split(' - ').join(',') : '',
      guncelleme: guncelleme ? guncelleme.split(' - ').join(',') : '',
      page,
      pageCount: pagination,
      column,
      order,
      merchant: merchantCommaList,
    });
  };

  const closeDetails = () => {
    setIsDetailsOpen(false);
  };


  useEffect(() => {
    fetchDepositList();
  }, [page, column, order, pagination])

  

  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
      // reference must be same for addEventListener and removeEventListener
      // = can't use inline arrow function!
      
      function listenerDeposits(event: { code: string; }) {
          if (event.code === 'Enter') {
            setItWorks(!itWorks);
            fetchDepositList();
          }
      }

      
      fetchDepositList();

    document.addEventListener('keypress', listenerDeposits);
    return () => {
    document.removeEventListener('keypress', listenerDeposits);
    }
  }, [!itWorks]);




  const DataTable = useDataTable<11>();

  return (
    <Row width="100%">
      <Modal
        onChange={(isOpen) => {
          setIsDetailsOpen(isOpen);
        }}
        open={isDetailsOpen}
        width="80vw"
      >
        <DepositDetail
          id={currentId}
          fetchDepositList={fetchDepositList}
          closeDetails={closeDetails}
        />
      </Modal>
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Yatırımlar</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%" padding="0">
            <Column width="65%">
              <Row>
                <Column width="20%">
                  <LabeledContainer label="İşlem ID">
                    <input
                      value={islem_id}
                      onChange={(event) => setProcessId(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Kullanıcı ID">
                    <input
                      value={kullanici_id}
                      onChange={(event) => setUserId(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Kullanıcı Adı">
                    <input
                      value={kullanici_adi}
                      onChange={(event) => setUserName(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Tam İsim">
                    <input
                      value={tam_isim}
                      onChange={(event) => setFullName(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Method">
                    <select
                      value={method}
                      onChange={(event) => setMethod(event.target.value)}
                    >
                      <option value="">Hepsi</option>
                      <option value="Havale">Havale</option>
                      <option value="Papara">Papara</option>
                      <option value="Kripto">Kripto</option>
                      <option value="MFT">MFT</option>
                      <option value="CreditCard">Kredi Kart</option>
                      <option value="Payco">Payco</option>
                    </select>
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="20%">
                  <LabeledContainer label="IP">
                    <input
                      value={ip}
                      onChange={(event) => setIP(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Durum">
                    <select
                      value={yatirim_durum}
                      onChange={(event) => setStatus(event.target.value)}
                    >
                      <option value="">Hepsi</option>
                      <option value="Beklemede">Beklemede</option>
                      <option value="Onaylandi">Başarılı</option>
                      <option value="Reddedildi">Reddedilmiş</option>
                    </select>
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Aktarım">
                    <select
                      value={aktarim}
                      onChange={(event) => setTransfer(event.target.value)}
                    >
                      <option value="">Hepsi</option>
                      <option value="API ile aktarilan">
                        API ile aktarılan
                      </option>
                      <option value="Manuel aktarilan">Manuel aktarılan</option>
                      <option value="Aktarilmamis">Aktarılmamış</option>
                    </select>
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Oluşturma">
                    <DatePicker
                      value={olusturma}
                      onChange={(startDate, endDate) => {
                        if (startDate && endDate)
                          setCreateDate(startDate + ' - ' + endDate);
                        else setCreateDate('');
                      }}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="20%">
                  <LabeledContainer label="Güncelleme">
                    <DatePicker
                      value={guncelleme}
                      onChange={(startDate, endDate) => {
                        if (startDate && endDate)
                          setUpdateDate(startDate + ' - ' + endDate);
                        else setUpdateDate('');
                      }}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
            <Column width="35%">
              <Row>
                <MultiSelect
                  value={selectedMerchants}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedMerchants(selectedElements);
                  }}
                  list={merchantSelectList}
                />
              </Row>
              <Row height="40px">
                <Button
                  onClick={() => {
                    setItWorks(!itWorks);
                    if (page === 1) fetchDepositList();
                    setCurrentPage(1);
                  }}
                  color={Color.orange}
                  icon={Icon.search}
                  loading={depositListLoading}
                >
                  Filtrele
                </Button>
              </Row>
            </Column>
          </Row>
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={depositList}
              loading={depositListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              onHeaderRender={onHeaderRender}
              columnsToIgnore={[0]}
              extraColumns={['Kopyala']}
              download={true}
              type="Deposit"
              durum={yatirim_durum}
              guncelleme={guncelleme ? guncelleme.split(' - ').join(',') : ''}
              merchants={merchantCommaList}             
              method={method}
              addRows={(rows) => {
                if (rows.length === 0) return [];
                const rowsSum = rows.reduce((acc, row, index, arr) => {
                  return row.map((e, i) => {
                    return (e as number) + (acc[i] as number);
                  });
                }, new Array(rows[0].length).fill(0));
                return [
                  [
                    '',
                    '',
                    '',
                    <b>
                      <i>{rowsSum[4].toFixed(2) + ' TL'}</i>
                    </b>,
                    <b>
                      <i>{rowsSum[5].toFixed(2) + ' TL'}</i>
                    </b>,
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                  ],
                ];
              }}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default Deposits;
