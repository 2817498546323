import { createAsyncThunk } from '@reduxjs/toolkit';
import { CurrentPapara } from '../../../pages/CurrentPaparaList/types';
import { makeRequestThunk } from '../../../utils/request';

export const getCurrentPaparaListThunk = createAsyncThunk(
  'global/getCurrentPaparaList',
  async (_, { rejectWithValue }): Promise<CurrentPapara> => {
    const { data, rejected } = await makeRequestThunk<CurrentPapara>(
      {
        method: 'get',
        url: '/api/v1/guncelPapara',
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
