import React, { ReactNode, useEffect, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import useDataTable from '../../components/DataTable/DataTable';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import DatePicker from '../../components/DatePicker/DatePicker';
import Button, { Color, Icon } from '../../components/Button/Button';
import { OrderType } from '../../components/DataTable/types';
import { makeRequest } from '../../utils/request';

const PaparaMails: React.FC = () => {
  const {
    global: { paparaMailList, paparaMailListLoading },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);

  const { getPaparaMailsThunk, showMessage } = useActions();

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);

  const [nereye, setToWhere] = useState('');
  const [tam_isim, setFullName] = useState('');
  const [durum, setStatus] = useState('');
  const [olusturma, setCreateDate] = useState('');
  const [guncelleme, setUpdateDate] = useState('');

  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    if (index === 4) {
      return value + ' TL';
    }
    if (index === 8) {
      return (
        <Row width="100%" margin="0" padding="0">
          <Column width="35px" height="35px" margin="0" padding="0">
            <Button
              onClick={async () => {
                const result = window.confirm(
                  'Papara Maili silmek istediğinize emin misiniz?'
                );
                if (result) {
                  await deletePaparaMail(row[0] as number);
                }
              }}
              color={Color.red}
              icon={Icon.trash}
            />
          </Column>
        </Row>
      );
    }
  };

  const fetchPaparaMailList = () => {
    getPaparaMailsThunk({
      nereye,
      tam_isim,
      durum,
      olusturma: olusturma ? olusturma.split(' - ').join(',') : '',
      guncelleme: guncelleme ? guncelleme.split(' - ').join(',') : '',
      column,
      order,
      page,
      pageCount: pagination,
    });
  };

  const deletePaparaMail = async (id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'delete',
        url: `/api/v1/paparaMailler/${id}`,
      },
      showMessage
    );
    if (!rejected) {
      fetchPaparaMailList();
    }
  };

  const deleteUnnecessaryPaparaMails = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'get',
        url: `/api/v1/paparaMailler?type=sil`,
      },
      showMessage
    );
    if (!rejected) {
      fetchPaparaMailList();
    }
  };

  useEffect(() => {
    fetchPaparaMailList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination]);

  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
    // reference must be same for addEventListener and removeEventListener
    // = can't use inline arrow function!
    function listenerPaparaMails(event: { code: string; }) {
        if (event.code === 'Enter') {
            setItWorks(!itWorks);
            fetchPaparaMailList();
        }
    }
    fetchPaparaMailList();

    document.addEventListener('keypress', listenerPaparaMails);
    return () => {
    document.removeEventListener('keypress', listenerPaparaMails);
    }
  }, [!itWorks]);

  const DataTable = useDataTable<10>();

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Papara Mailler</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="15%">
              <LabeledContainer label="Nereye">
                <input
                  value={nereye}
                  onChange={(event) => setToWhere(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="15%">
              <LabeledContainer label="Tam İsim">
                <input
                  value={tam_isim}
                  onChange={(event) => setFullName(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="15%">
              <LabeledContainer label="Durum">
                <select
                  value={durum}
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <option value="">Hepsi</option>
                  <option value="Aktarildi">Aktarıldı</option>
                  <option value="Beklemede">Beklemede</option>
                </select>
              </LabeledContainer>
            </Column>
            <Column width="15%">
              <LabeledContainer label="Oluşturma">
                <DatePicker
                  value={olusturma}
                  onChange={(startDate, endDate) => {
                    if (startDate && endDate)
                      setCreateDate(startDate + ' - ' + endDate);
                    else setCreateDate('');
                  }}
                />
              </LabeledContainer>
            </Column>
            <Column width="15%">
              <LabeledContainer label="Güncelleme">
                <DatePicker
                  value={guncelleme}
                  onChange={(startDate, endDate) => {
                    if (startDate && endDate)
                      setUpdateDate(startDate + ' - ' + endDate);
                    else setUpdateDate('');
                  }}
                />
              </LabeledContainer>
            </Column>
            <Column width="12%" height="60px">
              <Button
                onClick={() => {
                  setItWorks(!itWorks);
                  if (page === 1) fetchPaparaMailList();
                  setCurrentPage(1);
                }}
                color={Color.orange}
                icon={Icon.search}
                loading={paparaMailListLoading}
              >
                Filtrele
              </Button>
            </Column>
            {role_yonetim_listesi['Papara Mailleri Gereksizleri Sil'] ===
              '1' && (
              <Column width="12%" height="60px">
                <Button
                  onClick={async () => {
                    const result = window.confirm(
                      'Gereksiz papara mailleri silmek istediğinize emin misiniz?'
                    );
                    if (result) {
                      await deleteUnnecessaryPaparaMails();
                    }
                  }}
                  color={Color.red}
                  icon={Icon.trash}
                >
                  Gereksizleri Sil
                </Button>
              </Column>
            )}
          </Row>
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={paparaMailList}
              loading={paparaMailListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              extraColumns={['İşlem']}
              download={true}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default PaparaMails;
