import './Button.scss';
import React, { useEffect, useState } from 'react';
import { useAsyncTimeout } from '../../utils/utils';

export enum Color {
  orange = '#FFA264',
  green = '#28D094',
  red = '#FF4961',
  blue = '#1E9FF2',
  purple = '#a435b9',
  black = '#000000',
  white = '#FFFFFF',
  gray = '#c1c1c1',
}

export enum Icon {
  search = 'search',
  trash = 'trash',
  plus = 'plus',
  minus = 'minus',
  list = 'list',
  close = 'times-circle',
  sync = 'sync',
  university = 'university',
  edit = 'edit',
  exclamation = 'exclamation-circle',
  checkCircle = 'check-circle',
  check = 'check',
  lock = 'unlock-alt',
  moon = 'moon',
  sun = 'sun',
  copy = 'copy',
  download = 'download',
}

interface ButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: string | undefined;
  color?: Color;
  textColor?: Color;
  icon?: Icon;
  onClick: () => void;
  circle?: boolean | undefined;
  disabled?: boolean | undefined;
  loading?: boolean | undefined;
  tooltip?: string | undefined;
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  color,
  textColor,
  icon,
  circle,
  disabled,
  loading = false,
  style,
  tooltip,
  className,
}) => {
  const [showLoading, setShowLoading] = useState(false);

  useAsyncTimeout(
    () => {
      setShowLoading(loading);
    },
    500,
    [loading]
  );

  useEffect(() => {
    if (!loading) setShowLoading(loading);
  }, [loading]);

  return (
    <div className="button-container-div">
      <button
        disabled={disabled}
        onClick={() => {
          if (loading || disabled) return;
          onClick();
        }}
        className={`button-element${tooltip ? ' tooltip' : ''}${
          showLoading ? ' loading' : ''
        }${className ? ' ' + className : ''}`}
        style={{
          backgroundColor: color,
          border: '1px solid',
          borderColor: color,
          ...(textColor ? { color: textColor } : {}),
          ...(circle ? { borderRadius: '50%' } : {}),
          ...style,
        }}
      >
        {tooltip && !disabled && <span className="tooltiptext">{tooltip}</span>}
        {icon && !showLoading && <i className={`las la-${icon}`} />}
        {children && !showLoading && <span>{children}</span>}
        {showLoading && (
          <i
            className="las la-spinner"
            style={{
              animation: 'rotating 1s linear infinite',
              fontSize: '1.5rem',
            }}
          />
        )}
      </button>
    </div>
  );
};

export default Button;
