import React, { useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import Row from '../../components/utils/Row/Row';
import Column from '../../components/utils/Column/Column';
import Button, { Color } from '../../components/Button/Button';
import NewCreditCardTransferDetailTable from './NewCreditCardTransferDetailTable';
import { TransferItemStatus } from './types';
import { useAsyncInterval } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

interface NewTransferDetailProps {
  id: string;
  closeModal: () => void;
}

const NewCreditCardTransferDetail: React.FC<NewTransferDetailProps> = ({
  id,
  closeModal,
}) => {
  const {
    global: {
      newCreditCardTransferInfo: { creditCardDeposit: info },
    },
    auth: {
      currentUser: { id: currentUserId },
    },
  } = useSelector((state) => state);

  const [newAmount, setNewAmount] = useState('');

  const {
    getNewCreditCardTransferInfoThunk,
    showMessage,
    setLoading,
    getNewCreditCardTransfersThunk,
  } = useActions();

  useAsyncInterval(
    async () => {
      await getNewCreditCardTransferInfoThunk({ id });
    },
    2000,
    []
  );

  const updateAmount = async (amount: string) => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/transactions/creditCardDeposits/${id}`,
        data: {
          type: 'Miktari Duzenle',
          price: parseFloat(amount),
        },
      },
      showMessage
    );
    if (!rejected) {
      showMessage({
        message: `İşlem miktarı ${amount} TL olarak güncellenmiştir`,
        icon: 'information',
      });
      setNewAmount('');
    }
  };

  type NewTransferOperationType =
    | 'Islemi Uzerine Al'
    | 'Islemi Birak'
    | 'Islemi Onayla'
    | 'Islemi Reddet'
    | 'Fiyat Duzenle'
    | 'Yatirimi Gerceklestirdim';

  const updateStatus = async (
    operation: NewTransferOperationType,
    denyReason?: string
  ) => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/transactions/creditCardDeposits/${id}`,
        data: {
          type: operation,
          reddedilme_nedeni: denyReason,
        },
      },
      showMessage
    );
    if (!rejected) {
      if (operation === 'Islemi Onayla') {
        showMessage({
          message: 'İşlem onaylandı',
          icon: 'information',
        });
      } else if (operation === 'Islemi Reddet') {
        showMessage({
          message: 'İşlem Reddedildi',
          icon: 'information',
        });
      } else if (operation === 'Islemi Birak') {
        showMessage({
          message: 'İşlem bırakılmıştır',
          icon: 'information',
        });
      }
      await getNewCreditCardTransferInfoThunk({ id });
      getNewCreditCardTransfersThunk();
    }
  };

  return (
    <Row width="100%">
      <Column width="100%">
        {info.backuser_id !== currentUserId && info.backuser && (
          <Row style={{ fontSize: '1.5rem', color: 'red' }}>
            <div>
              <strong>{info.backuser}</strong> işlemi üzerine aldı!
            </div>
          </Row>
        )}
        <Row width="100%">
          <Column width="25%">
            <div>
              Merchant: <strong>{info.merchant}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
              Method: <strong>Kripto</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
              Durum: <strong>{TransferItemStatus[info.durum]}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
              İşlem ID: <strong>{info.islem_id}</strong>
            </div>
          </Column>
        </Row>
        <Row width="100%">
          <Column width="25%">
            <div>
              Müşteri: <strong>{info.musteri}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
            Müşteri TC No: <strong>{info.idNumber}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
            Müşteri Dogum Yili: <strong>{info.birthDate}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
              Kredi Kard No: <strong>{info.creditCardNumber}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
              Kredi Kard Ay: <strong>{info.expirationMonth}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
              Kredi Kard Yil: <strong>{info.expirationYear}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
              Kredi Kard Guvenlik Kodu: <strong>{info.securityNumber}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
              Oluşturma zamanı: <strong>{info.olusturma}</strong>
            </div>
          </Column>
        </Row>
        <Row width="100%">
          <Column width="25%">
            <div>
              Tam adı: <strong>{info.tam_adi}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
              Miktar: <strong>{info.miktar}</strong> TL
            </div>
          </Column>
          <Column width="25%">
            <div>
              Verilen Kredi Kart No: <strong>{info.verilen_credit_card_no}</strong>
            </div>
          </Column>
          <Column width="25%">
            <div>
              Güncellenme zamanı: <strong>{info.guncelleme}</strong>
            </div>
          </Column>
        </Row>
        <Row width="100%">
          <Column width="33%">
            <div style={{ textAlign: 'center' }}>
              Müşteri şu an{' '}
              <strong>
                {info.musteri_cevrim_ici ? 'çevrimiçi' : 'çevrimdışı'}
              </strong>{' '}
              olarak görünüyor...
            </div>
          </Column>
          <Column width="33%">
            <div style={{ textAlign: 'center' }}>
              IP: <strong>{info.ip}</strong>
            </div>
          </Column>
          <Column width="33%">
            <div style={{ textAlign: 'center' }}>
              <strong>{info.musteri_son_durum}</strong>
            </div>
          </Column>
        </Row>
        {!info.backuser && (
          <Row width="100%" height="50px">
            <Button
              onClick={async () => {
                setLoading(true);
                await updateStatus('Islemi Uzerine Al');
                setLoading(false);
              }}
              color={Color.purple}
            >
              İŞLEMİ ÜZERİNE AL !
            </Button>
          </Row>
        )}
        {info.backuser && info.durum === 0 && (
          <NewCreditCardTransferDetailTable id={id} />
        )}
        {info.backuser && (
          <Row width="100%" height="50px">
            <Column width={[1, 2].includes(info.durum) ? '25%' : '33%'}>
              <Button
                onClick={async () => {
                  const result = window.confirm(
                    'Bu işlemi onaylamak istediğinize emin misiniz?'
                  );
                  if (result) {
                    setLoading(true);
                    await updateStatus('Islemi Onayla');
                    setLoading(false);
                  }
                }}
                color={Color.green}
              >
                ONAYLA
              </Button>
            </Column>
            <Column width={[1, 2].includes(info.durum) ? '25%' : '33%'}>
              <Button
                onClick={async () => {
                  const denyReason = window.prompt(
                    'Reddetme nedenini yazınız(Bu bilgi müşteriye gösterilecektir).'
                  );
                  setLoading(true);
                  await updateStatus('Islemi Reddet', denyReason ? denyReason : "Sureciniz sistem tarafindan reddedilmistir.");
                  setLoading(false);
                }}
                color={Color.red}
              >
                REDDET
              </Button>
            </Column>
            <Column width={[1, 2].includes(info.durum) ? '25%' : '33%'}>
              <Button
                onClick={async () => {
                  setLoading(true);
                  await updateStatus('Islemi Birak');
                  setLoading(false);
                }}
                color={Color.blue}
              >
                BIRAK
              </Button>
            </Column>
            {[1, 2].includes(info.durum) && (
              <React.Fragment>
                <Column width="15%" height="100%">
                  <input
                    style={{ height: '100%' }}
                    type="number"
                    value={newAmount}
                    onChange={(event) => setNewAmount(event.target.value)}
                    placeholder="Yeni miktar(TL)"
                  />
                </Column>
                <Column width="10%">
                  <Button
                    onClick={async () => {
                      setLoading(true);
                      await updateAmount(newAmount);
                      setLoading(false);
                    }}
                    color={Color.orange}
                    disabled={newAmount.trim() === ''}
                  >
                    DÜZENLE
                  </Button>
                </Column>
              </React.Fragment>
            )}
          </Row>
        )}
      </Column>
    </Row>
  );
};

export default NewCreditCardTransferDetail;
