import './styles.scss';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SelectEntry } from './types';
import Dropdown from './Dropdown';
import SelectBox from './SelectBox';

interface MultiSelectProps {
  onChange: (selectedElements: SelectEntry[]) => void;
  list: SelectEntry[];
  height?: string | undefined;
  width?: string | undefined;
  value: SelectEntry[];
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  onChange,
  list,
  height,
  width,
  value,
}) => {
  const input = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState('');
  const [mouseInside, setMouseInside] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedList, setSelectedList] = useState<SelectEntry[]>(value);

  useEffect(() => {
    setSelectedList(value);
  }, [value]);

  useEffect(() => {
    onChange(selectedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);

  const toggleSelect = ({ id, text }: SelectEntry) => {
    setSelectedList((prevState) => {
      let nextState = [...prevState];
      if (prevState.findIndex((e) => e.id === id) === -1) {
        nextState.push({ id, text });
      } else {
        nextState = nextState.filter((e) => e.id !== id);
      }
      return nextState;
    });
    setSearchText('');
  };

  const selectAll = () => {
    setSelectedList(list);
    setDropdownOpen(false);
  };

  const selectBoxList = useMemo(
    () =>
      value.map(({ id, text }) => {
        return (
          <SelectBox key={id} text={text} id={id} toggleSelect={toggleSelect} />
        );
      }),
    [value]
  );

  return (
    <div
      style={{ maxWidth: width, width }}
      tabIndex={0}
      className="multiselect"
      onMouseEnter={() => setMouseInside(true)}
      onMouseLeave={() => setMouseInside(false)}
      onBlur={() => {
        if (!mouseInside) setDropdownOpen(false);
      }}
      onClick={(event) => {
        event.stopPropagation();
        if (input.current) {
          input.current.focus();
          setDropdownOpen(true);
        }
      }}
    >
      <div style={{ height }} className="multiselect-container">
        <div
          onClick={(event) => {
            event.stopPropagation();
            setSelectedList([]);
          }}
          className="multiselect-deselectall-button"
        >
          <i className={`las la-times-circle`} />
        </div>
        {selectBoxList}
        <input
          ref={input}
          className="multiselect-input default"
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value);
          }}
          onKeyDown={({ key }) => {
            if (key === 'Backspace') {
              if (searchText === '' && value.length > 0) {
                const lastElement = value[value.length - 1];
                toggleSelect({ ...lastElement });
                setSearchText(lastElement.text + ' ');
              }
            }
          }}
        />
      </div>
      {dropdownOpen && (
        <Dropdown
          top={height}
          dataSource={list}
          selectedList={value}
          searchText={searchText}
          toggleSelect={toggleSelect}
          selectAll={selectAll}
        />
      )}
    </div>
  );
};

export default MultiSelect;
