import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DashboardFilterParams,
  DashboardInfoFields,
} from '../../../pages/Dashboard/types';
import { omitIfEmpty } from '../../../utils/utils';
import { makeRequestThunk } from '../../../utils/request';

export const getDashboardInfoThunk = createAsyncThunk(
  'global/getDashboardInfo',
  async (
    { ...params }: DashboardFilterParams,
    { rejectWithValue }
  ): Promise<DashboardInfoFields> => {
    const { data, rejected } = await makeRequestThunk<DashboardInfoFields>(
      {
        method: 'get',
        url: `/api/v1/dashboard`,
        params: {
          ...omitIfEmpty({ ...params }),
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
