import { OrderType } from '../../components/DataTable/types';

export interface TransferItemEntry {
  id: string;
  merchant: string;
  durum: number;
  musteri: string;
  banka: string;
  verilen_banka: string;
  verilen_hesap: string;
  tam_adi: string;
  miktar: string;
  sure: string;
  backuser: string;
}

export interface TransferItemInfoParams {
  id: string;
}

export interface PayfixTransferItemInfoFields {
  payfixDeposit: {
    backuser_id: number;
    backuser: string;
    merchant: string;
    durum: number;
    islem_id: string;
    banka: string;
    musteri: string;
    tam_adi: string;
    verilen_banka_adi: string;
    verilen_hesap: string;
    olusturma: string;
    guncelleme: string;
    ip: string;
    musteri_cevrim_ici: boolean;
    musteri_son_durum: string;
    miktar: string;
  };
}

export const PayfixTransferItemInfoInitial = {
  payfixDeposit: {
    backuser_id: 0,
    backuser: '',
    merchant: '',
    durum: 0,
    islem_id: '',
    banka: '',
    musteri: '',
    tam_adi: '',
    verilen_banka_adi: '',
    verilen_hesap: '',
    olusturma: '',
    guncelleme: '',
    ip: '',
    musteri_cevrim_ici: false,
    musteri_son_durum: '',
    miktar: '',
  },
};

export interface TransferItemPayfixRegisterFilterParams {
  isim: string;
  hesap_no: string;
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export const TransferItemStatus = [
  'Müşteri hesap bilgisi bekliyor...',
  'Hesap bilgisi müşteriye gönderildi...',
  'Müşteri yatırdım butonuna bastı...',
  'Başarılı',
  'Reddedildi',
];

export const TransferItemStatusEnum = [
  'gray',
  'yellow',
  'green',
  'green',
  'red',
];
