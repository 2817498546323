import { OrderType } from '../../components/DataTable/types';

export interface WithdrawFilterParams {
  islem_id: string;
  kullanici_id: string;
  kullanici_adi: string;
  cekim_durum: string;
  tam_isim: string;
  olusturma: string;
  method: string;
  guncelleme: string;
  merchant: string;
  hesap: string;
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export interface WithdrawPaparaInfoParams {
  id: string;
  hidden?: boolean;
}

export interface WithdrawPaparaInfoFields {
  islem_hareketleri: {
    id: number;
    aciklama: string;
    ip: string;
    zaman: string;
  }[];

  islem_detayi: {
    banka_id: string;
    banka_adi: string;
    backuser_id: number;
    backuser: string;
    method: string;
    islem_id: string;
    kullanici_adi: string;
    merchant: string;
    miktar: string;
    tam_adi: string;
    durum: string;
    servis_ucreti: string;
    hesap_iban: string;
    trx: string;
    olusturma: string;
    guncelleme: string;
    data: string;
    sube_kodu: string;
    hesap_no: string;
    tckn: string;
    kimlik_tarihi: string;
    dogum_tarihi: string;
  };

  api_loglari: {
    log_id: string;
    islem: string;
    callback_url: string;
    request: {
      sid: string;
      key: string;
      service: string;
      method: string;
      user_id: string;
      username: string;
      amount: string;
      currency: string;
      transaction_id: string;
      trx: string;
      status: string;
      data: string;
    };
    response: {
      code: string;
      message: string;
    };
  }[];
}

export const WithdrawPaparaInfoInitial = {
  islem_hareketleri: [],
  islem_detayi: {
    banka_id: '',
    banka_adi: '',
    backuser_id: 0,
    backuser: '',
    method: '',
    islem_id: '',
    kullanici_adi: '',
    merchant: '',
    miktar: '',
    tam_adi: '',
    durum: '',
    servis_ucreti: '',
    hesap_iban: '',
    trx: '',
    olusturma: '',
    guncelleme: '',
    data: '',
    sube_kodu: '',
    hesap_no: '',
    tckn: '',
    kimlik_tarihi: '',
    dogum_tarihi: '',
  },
  api_loglari: [],
};
