import { actions as menuActions } from './menuSlice';
import { actions as authActions } from './authSlice';
import { actions as dashboardActions } from './globalSlice';
import { actions as notificationActions } from './notificationSlice';

const actions = {
  ...menuActions,
  ...authActions,
  ...dashboardActions,
  ...notificationActions,
};

export default actions;
