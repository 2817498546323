import { createAsyncThunk } from '@reduxjs/toolkit';
import { RoleInfoFields, RoleInfoParams } from '../../../pages/Roles/types';
import { makeRequestThunk } from '../../../utils/request';

export const getRoleInfoThunk = createAsyncThunk(
  'global/getRoleInfo',
  async (
    { id }: RoleInfoParams,
    { rejectWithValue }
  ): Promise<RoleInfoFields> => {
    const { data, rejected } = await makeRequestThunk<RoleInfoFields>(
      {
        method: 'get',
        url: `/api/v1/roles/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
