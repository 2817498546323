import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PayfixTransferItemInfoFields,
  TransferItemInfoParams,
} from '../../../pages/NewPayfixTransfer/types';
import { makeRequestThunk } from '../../../utils/request';

export const getNewPayfixTransferInfoThunk = createAsyncThunk(
  'global/getNewPayfixTransferInfo',
  async (
    { id }: TransferItemInfoParams,
    { rejectWithValue }
  ): Promise<PayfixTransferItemInfoFields> => {
    const { data, rejected } =
      await makeRequestThunk<PayfixTransferItemInfoFields>(
        {
          method: 'get',
          url: `/api/v1/transactions/paycoDeposits/${id}`,
        },
        rejectWithValue
      );

    if (rejected) return rejected;

    return data;
  }
);
