import { createSlice } from '@reduxjs/toolkit';

export interface menuState {
  isLocked: boolean;
  darkThemeEnabled: boolean;
}

const initialState: menuState = {
  isLocked: true,
  darkThemeEnabled: false,
};

export const menuSlice = createSlice({
  name: 'menuSlice',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.isLocked = !state.isLocked;
    },
    toggleTheme: (state) => {
      state.darkThemeEnabled = !state.darkThemeEnabled;
    },
  },
});

export const actions = menuSlice.actions;

export default menuSlice.reducer;
