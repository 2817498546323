import React from 'react';

export type OrderType = 'asc' | 'desc' | '';

export interface DataTableValue {
  count: number;
  results: {
    [key: string]: React.ReactNode;
  }[];
  total_pages: number;
}

export const DataTableValueInitial = {
  count: 0,
  results: [],
  total_pages: 0,
};

export interface DataTableProps<L extends number> {
  onPageUpdate: (
    pageNumber: number,
    orderColumn: string,
    orderType: OrderType,
    pagination: number,
    searchText: string
  ) => void;
  value: DataTableValue;
  loading: boolean;
  page: number;
  pagination: number;
  onColumnRender?: (
    value: string | number | React.ReactNode,
    columnIndex: number,
    row: React.ReactNode[],
    columnName: string
  ) => React.ReactNode | undefined;
  onRowClick?: (rowIndex: number, row: React.ReactNode[]) => void;
  onHeaderRender?: (value: string, columnIndex: number) => string | undefined;
  extraColumns?: React.ReactNode[] | undefined;
  addRows?: (rows: React.ReactNode[][]) => React.ReactNode[][];
  columnsToIgnore?: number[];
  search?: boolean | undefined;
  download?: boolean | undefined;
  type?: string | undefined;
  durum?: string | undefined;
  guncelleme?: string | undefined;
  method?: string | undefined;
  merchants?: string | undefined;
}

export interface PageButtonProps {
  children: string | number;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string | undefined;
}

export interface PageButtonsProps {
  pageCount: number;
  currentPage: number;
  loading: boolean;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}
