import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import useDataTable from '../../components/DataTable/DataTable';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Button, { Color, Icon } from '../../components/Button/Button';
import { OrderType } from '../../components/DataTable/types';
import { makeRequest } from '../../utils/request';

const Banks: React.FC = () => {
  const { bankList, bankListLoading } = useSelector((state) => state.global);

  const { getBanksThunk, showMessage } = useActions();

  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);

  const [banksObjectState, setBanksObjectState] =
    useState<typeof banksObject>();

  const banksObject = useMemo(() => {
    const object: {
      [key: string]: any;
    } = {};
    if (bankList.results && bankList.results.length > 0) {
      bankList.results.forEach((bank) => {
        object[bank.id as string] = { ...bank };
        delete object[bank.id as string].id;
      });
    }
    setBanksObjectState(object);
    return object;
  }, [bankList]);

  const updateBank = async (id: number, data: { [key: string]: any }) => {
    const { rejected } = await makeRequest(
      {
        method: 'put',
        url: `/api/v1/banks/${id}`,
        data,
      },
      showMessage
    );
    if (!rejected) {
      fetchBankList();
      showMessage({
        message: 'Banka bilgileri güncellendi',
        icon: 'information',
      });
    }
  };

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[],
    columnName: string
  ) => {
    if ([2, 3].includes(index)) {
      return (
        <Row width="100px" padding="0" height="40px" margin="0 auto">
          <input
            type="number"
            value={banksObject[row[0] as string][columnName]}
            onChange={(event) => {
              setBanksObjectState((prevState) => {
                const newState = { ...prevState };
                newState[row[0] as string][columnName] = event.target.value
                  ? parseInt(event.target.value)
                  : '';
                return newState;
              });
            }}
          />
        </Row>
      );
    }
    if ([4, 5, 6].includes(index)) {
      return (
        <Row width="100px" padding="0" height="40px" margin="0 auto">
          <select
            style={{
              width: '100%',
              border: 'none',
              outline: 'none',
              fontSize: '1rem',
            }}
            value={banksObject[row[0] as string][columnName]}
            onChange={(event) => {
              setBanksObjectState((prevState) => {
                const newState = { ...prevState };
                newState[row[0] as string][columnName] = event.target.value;
                return newState;
              });
            }}
          >
            <option value="true">Aktif</option>
            <option value="false">Pasif</option>
          </select>
        </Row>
      );
    }

    if (index === 7) {
      return (
        <Row width="100%" margin="0" padding="0">
          <Column width="35px" height="35px" margin="0" padding="0">
            <Button
              onClick={async () => {
                if (banksObjectState) {
                  await updateBank(
                    row[0] as number,
                    banksObjectState[row[0] as string]
                  );
                }
              }}
              color={Color.green}
              icon={Icon.sync}
            />
          </Column>
        </Row>
      );
    }
  };

  const fetchBankList = () => {
    getBanksThunk({ page, pageCount: pagination, column, order });
  };

  useEffect(() => {
    fetchBankList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Bankalar</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={bankList}
              loading={bankListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              extraColumns={['Kaydet']}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default Banks;
