import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PaparaTransferItemInfoFields,
  TransferItemInfoParams,
} from '../../../pages/NewPaparaTransfer/types';
import { makeRequestThunk } from '../../../utils/request';

export const getNewPaparaTransferInfoThunk = createAsyncThunk(
  'global/getNewPaparaTransferInfo',
  async (
    { id }: TransferItemInfoParams,
    { rejectWithValue }
  ): Promise<PaparaTransferItemInfoFields> => {
    const { data, rejected } =
      await makeRequestThunk<PaparaTransferItemInfoFields>(
        {
          method: 'get',
          url: `/api/v1/transactions/paparaDeposits/${id}`,
        },
        rejectWithValue
      );

    if (rejected) return rejected;

    return data;
  }
);
