import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DepositInfoFields,
  DepositInfoParams,
} from '../../../pages/Deposits/types';
import { makeRequestThunk } from '../../../utils/request';

export const getDepositInfoThunk = createAsyncThunk(
  'global/getDepositInfo',
  async (
    { id, hidden }: DepositInfoParams,
    { rejectWithValue }
  ): Promise<DepositInfoFields> => {
    const { data, rejected } = await makeRequestThunk<DepositInfoFields>(
      {
        method: 'get',
        url: `/api/v1/yatirim/${id}`,
        params: {
          hidden,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
