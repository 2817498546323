import React, { useMemo } from 'react';
import DropdownItem from './DropdownItem';
import { SelectEntry } from './types';

interface DropdownProps {
  dataSource: SelectEntry[];
  selectedList: SelectEntry[];
  searchText: string;
  toggleSelect: (param: SelectEntry) => void;
  selectAll: () => void;
  top?: string | undefined;
}

const turkishToEnglish = (text:string) => {
  return text.replace('Ğ','g')
      .replace('Ü','u')
      .replace('Ş','s')
      .replace('I','i') 
      .replace('İ','i')
      .replace('Ö','o')
      .replace('Ç','c')
      .replace('ğ','g')
      .replace('ü','u')
      .replace('ş','s')
      .replace('ı','i')
      .replace('ö','o')
      .replace('ç','c');
}

const Dropdown: React.FC<DropdownProps> = ({
  dataSource,
  selectedList,
  searchText,
  toggleSelect,
  top,
  selectAll,
}) => {
  const listElements = useMemo(
    () =>
      dataSource
        .filter(({ id, text }) => {
          if (selectedList.find((e) => e.id === id)) return false;
          if (searchText) {
            return turkishToEnglish(text).toLowerCase().includes(turkishToEnglish(searchText).toLowerCase());
          }
          return true;
        })
        .map(({ id, text }) => (
          <DropdownItem
            key={id}
            onClick={(event) => {
              event.stopPropagation();
              toggleSelect({ id, text });
            }}
          >
            {text}
          </DropdownItem>
        )),
    [searchText, selectedList, dataSource, toggleSelect]
  );

  return (
    <div tabIndex={1} style={{ top }} className="multiselect-dropdown">
      <DropdownItem
        onClick={(event) => {
          event.stopPropagation();
          selectAll();
        }}
      >
        Hepsini Seç
      </DropdownItem>
      {listElements}
    </div>
  );
};

export default Dropdown;
