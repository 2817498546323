import './Select.scss';
import React, { useEffect, useState, useRef } from 'react';
import { SelectEntry } from '../MultiSelect/types';

interface SelectProps {
  onChange: (selectedElement: SelectEntry | undefined) => void;
  list: SelectEntry[];
  value: SelectEntry | undefined;
  placeholder?: string;
}

const Select = ({ onChange, list, value, placeholder }: SelectProps) => {
  const [selected, setSelected] = useState<SelectEntry>();
  const [searchText, setSearchText] = useState('');
  const [searchResult, setSearchResult] = useState<SelectEntry[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mouseInside, setMouseInside] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);

  const input = useRef<HTMLInputElement>(null);

  const turkishToEnglish = (text:string) => {
      return text.replace('Ğ','g')
          .replace('Ü','u')
          .replace('Ş','s')
          .replace('I','i') 
          .replace('İ','i')
          .replace('Ö','o')
          .replace('Ç','c')
          .replace('ğ','g')
          .replace('ü','u')
          .replace('ş','s')
          .replace('ı','i')
          .replace('ö','o')
          .replace('ç','c');
  }
  
  useEffect(() => {
    if (searchText) {
      setSearchResult(
        list.filter((entry) =>
          turkishToEnglish(entry.text).toLowerCase().includes(turkishToEnglish(searchText).toLowerCase())
        )
      );
    } else {
      setSearchResult([]);
    }
  }, [searchText]);

  useEffect(() => {
    setSearchText('');
    if (dropdownOpen && input.current) {
      input.current.focus();
    }
  }, [dropdownOpen]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div
      className="dropdown-container"
      tabIndex={0}
      onMouseEnter={() => setMouseInside(true)}
      onMouseLeave={() => setMouseInside(false)}
      onFocus={() => {
        if (mouseDown) return;
        setDropdownOpen(true);
      }}
      onBlur={() => {
        if (mouseDown) return;
        if (!mouseInside) setDropdownOpen(false);
      }}
    >
      <div
        className="dropdown-selector"
        onMouseDown={() => setMouseDown(true)}
        onMouseUp={() => {
          setMouseDown(false);
          setDropdownOpen((prevState) => !prevState);
        }}
      >
        <div>
          {selected ? selected.text : placeholder ? placeholder : 'Seçiniz...'}
        </div>
        <div>
          <i
            style={{ color: 'black' }}
            className={`las la-caret-${dropdownOpen ? 'up' : 'down'}`}
          />
        </div>
      </div>
      {dropdownOpen && (
        <div className="dropdown-option-list">
          <div>
            <input
              ref={input}
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </div>
          <div
            className="dropdown-option"
            onClick={() => {
              setDropdownOpen(false);
              onChange(undefined);
            }}
          >
            {placeholder ? placeholder : 'Seçiniz...'}
          </div>
          {(searchText ? searchResult : list)
            .filter(({ id }) => (selected ? selected.id !== id : true))
            .map((e) => (
              <div
                key={e.id}
                className="dropdown-option"
                onClick={() => {
                  setDropdownOpen(false);
                  onChange(e);
                }}
              >
                {e.text}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Select;
