import React, { ReactNode, useEffect, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { isMissing, omitIfEmpty } from '../../utils/utils';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Button, { Color, Icon } from '../../components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { makeRequest } from '../../utils/request';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import TwoFactorAuth from "../../components/TwoFactorAuth/TwoFactorAuth";


const User: React.FC = () => {
    const navigate = useNavigate();
    const [ id , setId ] = useState('');
    const [is_active, setIsActive] = useState('');
    const [kullanici_adi, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [isim, setName] = useState('');
    const [soyisim, setSurname] = useState('');
    const [new_password, setPassword] = useState('');
    const [iki_adimli_dogrulama, setTwoStepVerification] = useState('');
    const [iki_adimli_dogrulama_key, setTwoStepVerificationKey] = useState('0');
    const submitDisabled = isMissing([
      email,
      isim,
      soyisim,
      kullanici_adi,
    ]);

    const [openModal, setOpenModal] = useState(false);
    const [secret, setSecret] = useState({
        otpauth_url: "",
        base32: "",
    });
  
    const {
      getUserInfoThunk,
      showMessage,
      setLoading,
    } = useActions();
    
    const {
        auth: {
          currentUser,
        },
      } = useSelector((state) => state);
      
    const { userInfo, } = useSelector(
      (state) => state.global
    );

    const [otpEnabled, setOtpEnabled] = useState(userInfo.otp_enabled);
    const getUserInfo = async (id : string) => {
      setLoading(true);
      await getUserInfoThunk({ id });
      setLoading(false);
    };
    

    const generateQrCode = async () => {
      try {
          const response = await makeRequest(
          {
              method: 'post',
              url: '/api/otp/generate',
              data: {
                user_id: currentUser.id.toString(),
                email: userInfo.email
              },
          },
          showMessage
          );
      
          
          if (response.status === 200) {
            setOpenModal(true);
            setSecret({
                base32: response.data.base32,
                otpauth_url: response.data.otpauth_url,
            });
          } 
      } catch (error) {
          // Handle errors here, e.g., show an error message to the user
          console.error('Error generating OTP:', error);
      } finally {
          // store.setRequestLoading(false);
          console.log('what')
      }
    };

    const disableTwoFactorAuth = async () => {
      try {
          const response = await makeRequest(
          {
              method: 'post',
              url: '/api/otp/disable',
              data: {
                user_id: currentUser.id.toString()
              },
          },
          showMessage
          );
  
          if (response.status === 200) {
            setOtpEnabled(false);
          } else {
            setOtpEnabled(true);
          }
      } catch (error) {
          // Handle errors here, e.g., show an error message to the user
          console.error('Error generating OTP:', error);
      } finally {
          // store.setRequestLoading(false);
      }
      
      };
  
    useEffect(() => {
      getUserInfo(currentUser.id.toString());
    }, []);
  
    useEffect(() => {
      setIsActive(userInfo.is_active);
      setUserName(userInfo.kullanici_adi);
      setEmail(userInfo.email);
      setName(userInfo.isim);
      setSurname(userInfo.soyisim);
      setTwoStepVerification(userInfo.iki_adimli_dogrulama);
      setTwoStepVerificationKey(userInfo.iki_adimli_dogrulama_key);
    }, [userInfo]);

    useEffect(() => { //console.log('end of it'); 
    }, [otpEnabled]);
  
  
    const handleInformationUpdate = (data: boolean) => {
      setOtpEnabled(data);
    };
  
   
    
    const editCurrentUser = async () => {
        const { rejected } = await makeRequest(
            {
            method: 'put',
            url: `/api/user/${currentUser.id.toString()}`,
            data: omitIfEmpty({
                is_active,
                kullanici_adi,
                email,
                isim,
                soyisim,
                new_password,
                iki_adimli_dogrulama,
                iki_adimli_dogrulama_key,
            }),
            },
            showMessage
        );
        if (!rejected) {
            navigate('/dashboard');
        }
    };
  
    return (
      <Row width="100%">
        <Box
          boxTitle={
            <div style={{ textDecoration: 'underline' }}>
              <h3>
                <strong>Kullanıcı Düzenleme</strong>
              </h3>
            </div>
          }
        >
          <Column width="100%" margin="0">
            <Row width="100%">
              <Column width="25%">            
                <Row>
                  <Column width="100%">
                    <LabeledContainer label="İsim">
                      <input
                        value={isim}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </LabeledContainer>
                  </Column>
                </Row>
                <Row>
                  <Column width="100%">
                    <LabeledContainer label="İki adımlı doğrulama">
                      <select
                        value={iki_adimli_dogrulama}
                        onChange={(event) =>
                          setTwoStepVerification(event.target.value)
                        }
                      >
                        <option value="true">Açık</option>
                        <option value="false">Kapalı</option>
                      </select>
                    </LabeledContainer>
                  </Column>
                </Row>
                <Row>
                  <Column width="100%">
                    <LabeledContainer label="İki adımlı doğrulama key">
                      <input
                        value={iki_adimli_dogrulama_key}
                        onChange={(event) =>
                          setTwoStepVerificationKey(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                </Row>
              </Column>
              <Column width="25%">
                <Row>
                  <Column width="100%">
                    <LabeledContainer label="Email(Benzersiz)">
                        <Column>
                            <span style={{ padding: '0.6rem 1.25rem'}}>{email}</span>
                        </Column>
                    </LabeledContainer>
                  </Column>
                </Row>
                <Row>
                  <Column width="100%">
                    <LabeledContainer label="Soy İsim">
                      <input
                        value={soyisim}
                        onChange={(event) => setSurname(event.target.value)}
                      />
                    </LabeledContainer>
                  </Column>
                </Row>
                <Row>
                  <Column width="100%">
                    <LabeledContainer label="Yeni Şifre(Optional)">
                      <input
                        value={new_password}
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </LabeledContainer>
                  </Column>
                </Row>
              </Column>
              <Column width="50%">
                <Row>
                  <Column width="100%">
                    <LabeledContainer label="İşlemler">
                      <Row>
                        <Column width="50%">
                          Son Giriş Saati:{' '}
                          <span style={{ fontWeight: '700' }}>
                            {userInfo.son_giris_saati}
                          </span>
                        </Column>
                        <Column width="50%">
                          Son Ip:{' '}
                          <span style={{ fontWeight: '700' }}>
                            {userInfo.son_ip}
                          </span>
                        </Column>
                      </Row>
                      <Row>
                        <Column width="50%">
                          Son Giriş Tarihi:{' '}
                          <span style={{ fontWeight: '700' }}>
                            {userInfo.son_giris_tarihi}
                          </span>
                        </Column>
                        <Column width="50%">
                          Son Agent:{' '}
                          <span style={{ fontWeight: '700' }}>
                            {userInfo.son_agent}
                          </span>
                        </Column>
                      </Row>
                    </LabeledContainer>
                  </Column>
                </Row>
                <Row>
                <Column width="100%">
                  <LabeledContainer label="İki adımlı doğrulama key" style={{ textAlign: 'center' }}>
                    {otpEnabled ? (
                      <button
                        type="button"
                        style = {{
                          outline: 'none',
                          color: 'white',
                          backgroundColor: 'red',
                          padding: '10px 20px',
                          border: 'none',
                          borderRadius: '0.5rem',
                          fontWeight: '500',
                          fontSize: '1rem',
                          marginBottom: '0.5rem',
                          cursor: 'pointer'
                        }}
                        onClick={() => disableTwoFactorAuth()}
                      >
                        2FA KAPAT
                      </button>
                    ) : (
                      <button
                        type="button"
                        style={{
                            outline: 'none',
                            color: 'white',
                            backgroundColor: '#8B5CF6',
                            padding: '10px 20px',
                            border: 'none',
                            maxWidth: '250px',
                            borderRadius: '0.5rem',
                            fontWeight: '500',
                            fontSize: '1rem',
                            marginBottom: '0.5rem',
                            cursor: 'pointer'
                        }}
                        onClick={() =>
                          generateQrCode()
                        }
                      >
                        2FA AKTIF ET
                      </button>
                    )}
                  </LabeledContainer>
                </Column>
              </Row>
              </Column>
            </Row>
            <Row width="100%" height="60px">
              <Button
                onClick={async () => {
                  setLoading(true);
                  await editCurrentUser();
                  setLoading(false);
                }}
                color={Color.green}
                disabled={submitDisabled}
              >
                Düzenle
              </Button>
            </Row>
          </Column>
        </Box>
        {openModal && (
         <div
         style={{
           position: 'fixed',
           top: 0,
           left: 0,
           width: '100%',
           height: '100%',
           backgroundColor: 'rgba(0, 0, 0, 0.5)',
           zIndex: 1000,
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'center',
         }}
       >
         <div
           style={{
             backgroundColor: '#fff',
             padding: '20px',
             borderRadius: '5px',
           }}
         >
          <TwoFactorAuth
              base32={secret.base32}
              otpauth_url={secret.otpauth_url}
              user_id={currentUser.id.toString() as string}
              closeModal={() => setOpenModal(false)}

              onInformationUpdate={handleInformationUpdate}
            />
            </div>
          </div>
      )}
      </Row>
    );
  };
export default User;
