import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewPayfixTransferCheck: React.FC = () => {
  const {
    global: { newPayfixTransferCreated, newPayfixTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewPayfixTransfersThunk,
    setNewPayfixTransferCreated,
    setNewPayfixTransferStatusChanged,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni PayFix Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewPayfixTransfersThunk();
        restartTimeout();
      }
    },
    3000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      role_yonetim_listesi['Yeni PayFix Yatırımları / Sonuçlandırma'] === '1' &&
      userLoaded
    ) {
      getNewPayfixTransfersThunk();
    }
  }, [role_yonetim_listesi, userLoaded]);

  useEffect(() => {
    if (
      (newPayfixTransferCreated || newPayfixTransferStatusChanged) &&
      role_yonetim_listesi['Yeni PayFix Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      if (newPayfixTransferStatusChanged) {
        setSoundPlaying('update');
        document.title = 'Müşteri Yatırdım butonuna bastı...';
      } else if (newPayfixTransferCreated) {
        setSoundPlaying('bip');
        document.title = 'Yeni Yatırım isteği...';
      }
    }
  }, [
    newPayfixTransferCreated,
    newPayfixTransferStatusChanged,
    role_yonetim_listesi,
  ]);

  useAsyncTimeout(
    () => {
      if (newPayfixTransferCreated || newPayfixTransferStatusChanged){
        setNewPayfixTransferCreated(false);
        setNewPayfixTransferStatusChanged(false);
      }
      document.title = 'Backoffice | Kralpay';
    },
    2000,
    [newPayfixTransferCreated, newPayfixTransferStatusChanged]
  );
  
  return <></>;
};

export default NewPayfixTransferCheck;
