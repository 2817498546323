import './Box.scss';
import React from 'react';

interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  float?: boolean;
  boxTitle?: string | React.ReactNode | undefined;
}

const Box: React.FC<BoxProps> = ({
  children,
  className = '',
  style,
  float = false,
  boxTitle,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`box ${className} ${float ? 'float' : ''}`}
      style={{
        position: 'relative',
        paddingTop: boxTitle ? '80px' : 0,
        ...style,
      }}
    >
      {boxTitle && (
        <div
          className="box-title"
          style={{
            position: 'absolute',
            left: '1rem',
            top: '1rem',
            lineHeight: 0,
          }}
        >
          {boxTitle}
        </div>
      )}
      {children}
    </div>
  );
};

export default Box;
