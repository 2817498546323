import React from 'react';

interface DropdownItemProps {
  children: string;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const DropdownItem: React.FC<DropdownItemProps> = ({ children, onClick }) => {
  return (
    <div className="multiselect-dropdown-item" onClick={onClick}>
      {children}
    </div>
  );
};

export default DropdownItem;
