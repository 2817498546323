import './Modal.scss';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import ModalFrame from './ModalFrame';
import Overlay from './Overlay';

interface ModalProps {
  onChange: (isOpen: boolean) => void;
  onClose?: () => void;
  open: boolean;
  width?: string | undefined;
  height?: string | undefined;
  zIndex?: number | undefined;
}

const Modal: React.FC<ModalProps> = ({
  children,
  onChange,
  open,
  width,
  height,
  onClose,
  zIndex,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const [closed, setClosed] = useState(false);

  useEffect(() => {
    onChange(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (closed && onClose) onClose();
  }, [closed]);

  if (!isOpen) return null;

  return createPortal(
    <React.Fragment>
      <Overlay setIsOpen={setIsOpen} setClosed={setClosed} zIndex={zIndex} />
      <ModalFrame
        width={width}
        height={height}
        setIsOpen={setIsOpen}
        setClosed={setClosed}
        zIndex={zIndex}
      >
        {children}
      </ModalFrame>
    </React.Fragment>,
    document.body
  );
};

export default Modal;
