import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequestThunk } from '../../../utils/request';

export interface PaparaRegisterEntry {
  id: number;
  isim: string;
  bakiye: string;
  durum: boolean;
  yatirim_adet: string;
  cekim_adet: string;
}

export const getPaparaRegisterListThunk = createAsyncThunk(
  'global/getPaparaRegisterList',
  async (_, { rejectWithValue }): Promise<PaparaRegisterEntry[]> => {
    const {
      data: { results },
      rejected,
    } = await makeRequestThunk<{ results: PaparaRegisterEntry[] }>(
      {
        method: 'get',
        url: '/api/v1/paparaKasa',
        params: {
          pageCount: 100000,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return results;
  }
);
