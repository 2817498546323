import React, { useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useActions, useSelector } from '../../redux/hooks';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

const EditMerchant: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [site, setSite] = useState('');
  const [site_id, setSiteId] = useState('');
  const [durum, setStatus] = useState('true');
  const [entegrasyon_tipi, setIntegration] = useState('');
  const [paymentName, setPaymentName] = useState('');
  const [cekim_tolerans_limiti, setWithdrawLimit] = useState('0');
  const [otomatik_papara_cekme_izni, setAutoPaparaWithdraw] = useState('');
  const [merchant_key, setMerchantKey] = useState('');
  const [kullanilabilir_method, setAvailableMethod] = useState<SelectEntry[]>(
    []
  );
  const [havale_yatirim_komisyon, setTransferDepositCommission] = useState('0');
  const [havale_cekim_komisyon, setTransferWithdrawCommission] = useState('0');
  const [havale_minimum_yatirim, setTransferMinDeposit] = useState('0');
  const [havale_maximum_yatirim, setTransferMaxDeposit] = useState('0');
  const [kripto_yatirim_komisyon, setKriptoTransferDepositCommission] = useState('0');
  const [kripto_cekim_komisyon, setKriptoTransferWithdrawCommission] = useState('0');
  const [kripto_minimum_yatirim, setKriptoTransferMinDeposit] = useState('0');
  const [kripto_maximum_yatirim, setKriptoTransferMaxDeposit] = useState('0');
  const [papara_yatirim_komisyon, setPaparaDepositCommission] = useState('0');
  const [papara_cekim_komisyon, setPaparaWithdrawCommission] = useState('0');
  const [papara_minimum_yatirim, setPaparaMinDeposit] = useState('0');
  const [papara_maximum_yatirim, setPaparaMaxDeposit] = useState('0');
  const [mft_yatirim_komisyon, setMFTTransferDepositCommission] = useState('0');
  const [mft_cekim_komisyon, setMFTTransferWithdrawCommission] = useState('0');
  const [mft_minimum_yatirim, setMFTTransferMinDeposit] = useState('0');
  const [mft_maximum_yatirim, setMFTTransferMaxDeposit] = useState('0');
  const [payco_yatirim_komisyon, setPaycoTransferDepositCommission] = useState('0');
  const [payco_cekim_komisyon, setPaycoTransferWithdrawCommission] = useState('0');
  const [payco_minimum_yatirim, setPaycoTransferMinDeposit] = useState('0');
  const [payco_maximum_yatirim, setPaycoTransferMaxDeposit] = useState('0');
  const [rest_api_script_json, setAPIScript] = useState('');

  const [isPapara, setIsPapara] = useState(false);
  const [isTransfer, setIsTransfer] = useState(false);
  const [isKripto, setIsKripto] = useState(false);
  const [isMFT, setIsMFT] = useState(false);
  const [isPayco, setIsPayco] = useState(false);


  const submitDisabled = isMissing([site, cekim_tolerans_limiti]);

  const { getMerchantInfoThunk, showMessage, setLoading } = useActions();

  const { merchantInfo } = useSelector((state) => state.global);

  const availableMethodList: SelectEntry[] = [
    { id: 'Havale', text: 'Havale' },
    { id: 'Papara', text: 'Papara' },
    { id: 'Kripto', text: 'Kripto' },
    { id: 'MFT', text: 'MFT' },
    { id: 'Payco', text: 'Payco' },
  ];

  const getMerchantInfo = async () => {
    setLoading(true);
    await getMerchantInfoThunk({ id });
    setLoading(false);
  };

  useEffect(() => {
    if (kullanilabilir_method.find((e) => e.id === 'Havale'))
      setIsTransfer(true);
    else setIsTransfer(false);
    if (kullanilabilir_method.find((e) => e.id === 'Papara')) setIsPapara(true);
    else setIsPapara(false);
    if (kullanilabilir_method.find((e) => e.id === 'Kripto')) setIsKripto(true);
    else setIsKripto(false);
    if (kullanilabilir_method.find((e) => e.id === 'MFT')) setIsMFT(true);
    else setIsMFT(false);
    if (kullanilabilir_method.find((e) => e.id === 'Payco')) setIsPayco(true);
    else setIsPayco(false);
  }, [kullanilabilir_method]);

  useEffect(() => {
    getMerchantInfo();
  }, []);

  useEffect(() => {
    setSite(merchantInfo.site);
    setSiteId(merchantInfo.site_id);
    setStatus(merchantInfo.durum);
    setIntegration(merchantInfo.entegrasyon_tipi);
    setPaymentName(merchantInfo.paymentName);
    setWithdrawLimit(merchantInfo.cekim_tolerans_limiti);
    setAutoPaparaWithdraw(merchantInfo.otomatik_papara_cekme_izni);
    setAPIScript(() => {
      try {
        return JSON.stringify(JSON.parse(merchantInfo.rest_api_script_json));
      } catch (error) {}
      return JSON.stringify(merchantInfo.rest_api_script_json);
    });
    setMerchantKey(merchantInfo.merchant_key);
    setAvailableMethod(
      merchantInfo.kullanilabilir_method.map((e) => ({ id: e, text: e }))
    );
    setTransferDepositCommission(merchantInfo.havale_yatirim_komisyon);
    setTransferWithdrawCommission(merchantInfo.havale_cekim_komisyon);
    setTransferMinDeposit(merchantInfo.havale_minimum_yatirim);
    setTransferMaxDeposit(merchantInfo.havale_maximum_yatirim);
    setKriptoTransferDepositCommission(merchantInfo.kripto_yatirim_komisyon);
    setKriptoTransferWithdrawCommission(merchantInfo.kripto_cekim_komisyon);
    setKriptoTransferMinDeposit(merchantInfo.kripto_minimum_yatirim);
    setKriptoTransferMaxDeposit(merchantInfo.kripto_maximum_yatirim);
    setMFTTransferDepositCommission(merchantInfo.mft_yatirim_komisyon);
    setMFTTransferWithdrawCommission(merchantInfo.mft_cekim_komisyon);
    setMFTTransferMinDeposit(merchantInfo.mft_minimum_yatirim);
    setMFTTransferMaxDeposit(merchantInfo.mft_maximum_yatirim);
    setPaparaDepositCommission(merchantInfo.papara_yatirim_komisyon);
    setPaparaWithdrawCommission(merchantInfo.papara_cekim_komisyon);
    setPaparaMinDeposit(merchantInfo.papara_minimum_yatirim);
    setPaparaMaxDeposit(merchantInfo.papara_maximum_yatirim);
    setPaycoTransferDepositCommission(merchantInfo.payco_yatirim_komisyon);
    setPaycoTransferWithdrawCommission(merchantInfo.payco_cekim_komisyon);
    setPaycoTransferMinDeposit(merchantInfo.payco_minimum_yatirim);
    setPaycoTransferMaxDeposit(merchantInfo.payco_maximum_yatirim);
  }, [merchantInfo]);

  const editMerchant = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'put',
        url: `/api/v1/merchants/${id}`,
        data: {
          site,
          durum,
          entegrasyon_tipi,
          cekim_tolerans_limiti,
          otomatik_papara_cekme_izni,
          merchant_key,
          kullanilabilir_method: kullanilabilir_method.map((e) => e.id),
          havale_yatirim_komisyon,
          havale_cekim_komisyon,
          havale_minimum_yatirim,
          havale_maximum_yatirim,
          papara_yatirim_komisyon,
          papara_cekim_komisyon,
          papara_minimum_yatirim,
          papara_maximum_yatirim,
          kripto_yatirim_komisyon,
          kripto_cekim_komisyon,
          kripto_minimum_yatirim,
          kripto_maximum_yatirim,
          mft_yatirim_komisyon,
          mft_cekim_komisyon,
          mft_minimum_yatirim,
          mft_maximum_yatirim,
          payco_yatirim_komisyon,
          payco_cekim_komisyon,
          payco_minimum_yatirim,
          payco_maximum_yatirim,
          rest_api_script_json,
          paymentName
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/merchantlar');
    }
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Merchant Düzenleme</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Site">
                    <input
                      value={site}
                      onChange={(event) => setSite(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Durum">
                    <select
                      value={durum}
                      onChange={(event) => setStatus(event.target.value)}
                    >
                      <option value="true">Aktif</option>
                      <option value="false">Pasif</option>
                    </select>
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Entegrasyon Tipi">
                    <select
                      value={entegrasyon_tipi}
                      onChange={(event) => setIntegration(event.target.value)}
                    >
                      <option value="MANUEL">Manüel</option>
                      <option value="API">API</option>
                    </select>
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="PAYMENT API">
                    <select
                      value={paymentName}
                      onChange={(event) => setPaymentName(event.target.value)}
                    >
                      <option value="SOURCES">Sources</option>
                      <option value="CONSTRUCT">Construct</option>
                      <option value="OCEAN">Ocean</option>
                      <option value="M2">M2</option>
                      <option value="STONES">Stones</option>
                      <option value="FUNDIST">Fundist</option>
                    </select>
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Çekim Tölerans Limiti">
                    <input
                      type="number"
                      value={cekim_tolerans_limiti}
                      onChange={(event) => setWithdrawLimit(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Otomatik Papara Çekim İzni">
                    <select
                      value={otomatik_papara_cekme_izni}
                      onChange={(event) =>
                        setAutoPaparaWithdraw(event.target.value)
                      }
                    >
                      <option value="true">Aktif</option>
                      <option value="false">Pasif</option>
                    </select>
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
            <Column width="50%">
              <Row height="100%">
                <Column width="100%">
                  <LabeledContainer
                    label="Rest API Script (JSON)"
                    style={{ height: '100%' }}
                  >
                    <textarea
                      value={rest_api_script_json}
                      onChange={(event) => setAPIScript(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Kullanılabilir Methodlar">
                <MultiSelect
                  value={kullanilabilir_method}
                  height="50px"
                  onChange={(selectedElements) => {
                    setAvailableMethod(selectedElements);
                  }}
                  list={availableMethodList}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              {isTransfer && (
                <Row width="100%">
                  <Column width="25%">
                    <LabeledContainer
                      label="Havale Yatırım Komisyon"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={havale_yatirim_komisyon}
                        onChange={(event) =>
                          setTransferDepositCommission(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Havale Çekim Komisyon"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={havale_cekim_komisyon}
                        onChange={(event) =>
                          setTransferWithdrawCommission(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Havale Minimum Yatırım"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={havale_minimum_yatirim}
                        onChange={(event) =>
                          setTransferMinDeposit(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Havale Maximum Yatırım"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={havale_maximum_yatirim}
                        onChange={(event) =>
                          setTransferMaxDeposit(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                </Row>
              )}
              {isKripto && (
                <Row width="100%">
                  <Column width="25%">
                    <LabeledContainer
                      label="Kripto Yatırım Komisyon"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={kripto_yatirim_komisyon}
                        onChange={(event) =>
                          setKriptoTransferDepositCommission(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Kripto Çekim Komisyon"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={havale_cekim_komisyon}
                        onChange={(event) =>
                          setKriptoTransferWithdrawCommission(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Kripto Minimum Yatırım"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={kripto_minimum_yatirim}
                        onChange={(event) =>
                          setKriptoTransferMinDeposit(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Kripto Maximum Yatırım"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={kripto_maximum_yatirim}
                        onChange={(event) =>
                          setKriptoTransferMaxDeposit(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                </Row>
              )}

              {isMFT && (
                <Row width="100%">
                  <Column width="25%">
                    <LabeledContainer
                      label="MFT Yatırım Komisyon"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={mft_yatirim_komisyon}
                        onChange={(event) =>
                          setMFTTransferDepositCommission(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="MFT Çekim Komisyon"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={mft_cekim_komisyon}
                        onChange={(event) =>
                          setMFTTransferWithdrawCommission(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="MFT Minimum Yatırım"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={mft_minimum_yatirim}
                        onChange={(event) =>
                          setMFTTransferMinDeposit(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="MFT Maximum Yatırım"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={mft_maximum_yatirim}
                        onChange={(event) =>
                          setMFTTransferMaxDeposit(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                </Row>
              )}
              {isPapara && (
                <Row width="100%">
                  <Column width="25%">
                    <LabeledContainer
                      label="Papara Yatırım Komisyon"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={papara_yatirim_komisyon}
                        onChange={(event) =>
                          setPaparaDepositCommission(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Papara Çekim Komisyon"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={papara_cekim_komisyon}
                        onChange={(event) =>
                          setPaparaWithdrawCommission(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Papara Minimum Yatırım"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={papara_minimum_yatirim}
                        onChange={(event) =>
                          setPaparaMinDeposit(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Papara Maximum Yatırım"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={papara_maximum_yatirim}
                        onChange={(event) =>
                          setPaparaMaxDeposit(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                </Row>
              )}
              {isPayco && (
                <Row width="100%">
                  <Column width="25%">
                    <LabeledContainer
                      label="Payco Yatırım Komisyon"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={payco_yatirim_komisyon}
                        onChange={(event) =>
                          setPaycoTransferDepositCommission(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Payco Çekim Komisyon"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={payco_cekim_komisyon}
                        onChange={(event) =>
                          setPaycoTransferWithdrawCommission(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Payco Minimum Yatırım"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={payco_minimum_yatirim}
                        onChange={(event) =>
                          setPaycoTransferMinDeposit(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                  <Column width="25%">
                    <LabeledContainer
                      label="Payco Maximum Yatırım"
                      style={{ height: '130px' }}
                    >
                      <input
                        type="number"
                        value={payco_maximum_yatirim}
                        onChange={(event) =>
                          setPaycoTransferMaxDeposit(event.target.value)
                        }
                      />
                    </LabeledContainer>
                  </Column>
                </Row>
              )}
            </Column>
          </Row>
          <Row width="100%">
            <Column width="100%">
              <LabeledContainer>
                <Row style={{ justifyContent: 'flex-start' }}>
                  <span style={{ marginRight: '10px' }}>Merchant SID:</span>
                  <span
                    style={{
                      fontWeight: '700',
                    }}
                  >
                    {site_id}
                  </span>
                </Row>
                <Row style={{ justifyContent: 'flex-start' }}>
                  <span style={{ marginRight: '10px' }}>Merchant Key:</span>
                  <span
                    style={{
                      fontWeight: '700',
                    }}
                  >
                    {merchant_key}
                  </span>
                </Row>
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await editMerchant();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Düzenle
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default EditMerchant;
