import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  WithdrawCryptoInfoFields,
  WithdrawCryptoInfoParams,
} from '../../../pages/WithdrawsCrypto/types';
import { makeRequestThunk } from '../../../utils/request';

export const getWithdrawCryptoInfoThunk = createAsyncThunk(
  'global/getWithdrawCryptoInfo',
  async (
    { id, hidden }: WithdrawCryptoInfoParams,
    { rejectWithValue }
  ): Promise<WithdrawCryptoInfoFields> => {
    const { data, rejected } = await makeRequestThunk<WithdrawCryptoInfoFields>(
      {
        method: 'get',
        url: `/api/v1/cekim/${id}`,
        params: {
          hidden,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
