import './Column.scss';
import React from 'react';

interface ColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: string | number;
  height?: string | number;
  padding?: string | undefined;
  margin?: string | undefined;
}

const Column: React.FC<ColumnProps> = ({
  children,
  className = '',
  style = {},
  width = '',
  height = '',
  padding,
  margin,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`column ${className}`}
      style={{
        width,
        height,
        ...(padding ? { padding } : {}),
        ...(margin ? { margin } : {}),
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Column;
