import React from 'react';
import { UserPerformance } from './types';

interface PerformanceCardProps {
  onClick: () => void;
  hidden: boolean;
  data: UserPerformance;
  number: number;
}

const PerformanceCard: React.FC<PerformanceCardProps> = ({
  hidden,
  onClick,
  data,
  number,
}) => {
  return (
    <div className="performance-card">
      <div className="title" onClick={() => onClick()}>
        <div className="number">{number}.</div>
        <div className="name">{data.isim}</div>
        <div className="total">Toplam {data.toplam}</div>
      </div>
      <div className={`details${hidden ? ' hidden' : ''}`}>
        <div className="detail">
          <div>*</div>
          <div>Hesap verdi</div>
          <div>{data.hesap_vermek}</div>
        </div>
        <div className="detail">
          <div>*</div>
          <div>Yatırım onay</div>
          <div>{data.yatirim_onay}</div>
        </div>
        <div className="detail">
          <div>*</div>
          <div>Yatırım redd</div>
          <div>{data.yatirim_redd}</div>
        </div>
        <div className="detail">
          <div>*</div>
          <div>Çekim onay</div>
          <div>{data.cekim_onay}</div>
        </div>
        <div className="detail">
          <div>*</div>
          <div>Çekim redd</div>
          <div>{data.cekim_redd}</div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceCard;
