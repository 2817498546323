import React, { useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import Select from '../../components/Select/Select';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

interface PaparaRegisterIncreaseBalanceProps {
  id: string;
  name: string;
  callback?: () => void;
}

const PaparaRegisterDecreaseBalance: React.FC<
  PaparaRegisterIncreaseBalanceProps
> = ({ id, name, callback }) => {
  const { merchantSelectList } = useSelector((state) => state.global);

  const { setLoading, showMessage } = useActions();

  const [miktar, setAmount] = useState('');
  const [masraf, setCost] = useState('');
  const [aciklama, setDescription] = useState('');

  const [merchant, setSelectedMerchant] = useState<SelectEntry>();

  const submitDisabled = isMissing([merchant?.id, miktar, masraf]);

  const decreaseBalance = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/paparaKasa/${id}`,
        data: {
          type: 'bakiye_azalt',
          merchant: merchant?.id,
          miktar,
          masraf,
          aciklama,
        },
      },
      showMessage
    );
    if (callback && !rejected) callback();
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div>
            <h3>
              <strong>Kasa Bakiye Azaltma - {name}</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Merchant">
                <Select
                  value={merchant}
                  onChange={(entry) => setSelectedMerchant(entry)}
                  list={merchantSelectList}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Açıklama">
                <input
                  value={aciklama}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Miktar">
                <input
                  type="number"
                  value={miktar}
                  onChange={(event) => setAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Masraf">
                <input
                  type="number"
                  value={masraf}
                  onChange={(event) => setCost(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await decreaseBalance();
                setLoading(false);
              }}
              color={Color.red}
              disabled={submitDisabled}
            >
              Azalt
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default PaparaRegisterDecreaseBalance;
