import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewWithdrawCreditCardTransferCheck: React.FC = () => {
  const {
    global: { newWithdrawCreditCardTransferCreated, newWithdrawCreditCardTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewWithdrawCreditCardTransfersThunk,
    setNewWithdrawCreditCardTransferCreated,
    setNewWithdrawCreditCardTransferStatusChanged,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni Kredi Kart Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewWithdrawCreditCardTransfersThunk();
        restartTimeout();
      }
    },
    3000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      (newWithdrawCreditCardTransferCreated || newWithdrawCreditCardTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Kredi Kart Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      if (newWithdrawCreditCardTransferStatusChanged) {
        setSoundPlaying('update');
        document.title = 'Müşteri Yatırdım butonuna bastı...';
      } else if (newWithdrawCreditCardTransferStatusChanged) {
        setSoundPlaying('bip');
        document.title = 'Yeni Yatırım isteği...';
      }
    }
  }, [
    newWithdrawCreditCardTransferCreated,
    newWithdrawCreditCardTransferStatusChanged,
    role_yonetim_listesi,
  ]);


  useAsyncTimeout(
    () => {
      if (newWithdrawCreditCardTransferCreated || newWithdrawCreditCardTransferStatusChanged){
        setNewWithdrawCreditCardTransferCreated(false);
        setNewWithdrawCreditCardTransferStatusChanged(false);
      }
      document.title = 'Backoffice | Kralpay';
    },
    2000,
    [newWithdrawCreditCardTransferCreated, newWithdrawCreditCardTransferStatusChanged]
  );
  

  return <></>;
};

export default NewWithdrawCreditCardTransferCheck;
