import React, { useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import { useActions, useSelector } from '../../redux/hooks';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import Select from '../../components/Select/Select';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing, omitIfEmpty } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

const CreateManuelWithdraw: React.FC = () => {
  const {
    global: { merchantSelectList, bankSelectList },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);

  const { showMessage, setLoading } = useActions();

  const [merchant, setMerchant] = useState<SelectEntry>();
  const [method, setMethod] = useState('Havale');
  const [kullanici_id, setUserId] = useState('');
  const [kullanici_adi, setUserName] = useState('');
  const [tam_adi, setUserFullName] = useState('');
  const [iban, setIban] = useState('');
  const [hesap_no, setAccountNo] = useState('');
  const [sube_kodu, setBranchCode] = useState('');
  const [dogum_tarihi, setBirthDate] = useState('');
  const [tc_kimlik_no, setIDCode] = useState('');
  const [kimlik_verilis_son_kullanma_tarihi, setIDExpireDate] = useState('');
  const [miktar, setAmount] = useState('');
  const [banka_adi, setBank] = useState<SelectEntry>();
  const [trx, setTrx] = useState('');
  const [data, setData] = useState('');

  const submitDisabledDeposit = isMissing(
    role_yonetim_listesi['Manuel İşlemler'] === '1'
      ? [
          merchant?.id,
          banka_adi?.id,
          kullanici_id,
          kullanici_adi,
          tam_adi,
          iban,
          miktar,
        ]
      : [merchant?.id, banka_adi?.id, tam_adi, iban, miktar]
  );

  const submitDisabledPapara = isMissing(
    role_yonetim_listesi['Manuel İşlemler'] === '1'
      ? [
          merchant?.id,
          kullanici_id,
          kullanici_adi,
          tam_adi,
          hesap_no,
          miktar,
        ]
      : [merchant?.id, tam_adi, miktar]
  );

  const clearInputs = () => {
    setMerchant(undefined);
    setUserId('');
    setUserName('');
    setUserFullName('');
    setIban('');
    setAccountNo('');
    setBranchCode('');
    setBirthDate('');
    setIDCode('');
    setIDExpireDate('');
    setAmount('');
    setBank(undefined);
    setTrx('');
    setData('');
  };

  const createManuelWithdraw = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'post',
        url: '/api/v1/manuel_cekim',
        data: omitIfEmpty({
          merchant: merchant?.id,
          method,
          kullanici_id,
          kullanici_adi,
          tam_adi,
          iban,
          hesap_no,
          sube_kodu,
          dogum_tarihi,
          tc_kimlik_no,
          kimlik_verilis_son_kullanma_tarihi,
          miktar,
          banka_adi: banka_adi?.id,
          trx,
          data,
        }),
      },
      showMessage
    );
    if (!rejected) {
      showMessage({
        message: 'Manuel Çekim Yaratıldı',
        icon: 'information',
      });
      clearInputs();
    }
  };

  useEffect(() => {
    if (method !== 'Havale') {
      setBranchCode('');
      setIDExpireDate('');
      setBirthDate('');
      setIDCode('');
      setIban('');
      setBank(undefined);
    }
  }, [method]);

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Manüel Çekim Oluştur</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Merchant">
                <Select
                  value={merchant}
                  onChange={(entry) => setMerchant(entry)}
                  list={merchantSelectList}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Method">
                <select
                  value={method}
                  onChange={(event) => setMethod(event.target.value)}
                >
                  <option value="Havale">Havale</option>
                  <option value="Papara">Papara</option>
                  <option value="Kripto">Kripto</option>
                </select>
              </LabeledContainer>
            </Column>
          </Row>
          {role_yonetim_listesi['Manuel İşlemler'] === '1' && (
            <Row width="100%">
              <Column width="50%">
                <LabeledContainer label="Kullanıcı ID">
                  <input
                    value={kullanici_id}
                    onChange={(event) => setUserId(event.target.value)}
                  />
                </LabeledContainer>
              </Column>
              <Column width="50%">
                <LabeledContainer label="Kullanıcı Adı">
                  <input
                    value={kullanici_adi}
                    onChange={(event) => setUserName(event.target.value)}
                  />
                </LabeledContainer>
              </Column>
            </Row>
          )}
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Müşterinin Tam Adı">
                <input
                  value={tam_adi}
                  onChange={(event) => setUserFullName(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Hesap">
                <input
                  value={hesap_no}
                  onChange={(event) => setAccountNo(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          {method === 'Havale' &&
            role_yonetim_listesi['Manuel İşlemler'] === '1' && (
              <Row width="100%">
                <Column width="50%">
                  <LabeledContainer label="Kimlik Veriliş / Son Kullanma Tarihi">
                    <input
                      value={kimlik_verilis_son_kullanma_tarihi}
                      onChange={(event) => setIDExpireDate(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="50%">
                  <LabeledContainer label="Şube kodu">
                    <input
                      value={sube_kodu}
                      onChange={(event) => setBranchCode(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
            )}
          {method === 'Havale' &&
            role_yonetim_listesi['Manuel İşlemler'] === '1' && (
              <Row width="100%">
                <Column width="50%">
                  <LabeledContainer label="Doğum Tarihi">
                    <input
                      value={dogum_tarihi}
                      onChange={(event) => setBirthDate(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
                <Column width="50%">
                  <LabeledContainer label="Tc Kimlik No">
                    <input
                      value={tc_kimlik_no}
                      onChange={(event) => setIDCode(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
            )}
          {method === 'Havale' && (
            <Row width="100%">
              <Column width="50%">
                <LabeledContainer label="İban">
                  <input
                    value={iban}
                    onChange={(event) => setIban(event.target.value)}
                  />
                </LabeledContainer>
              </Column>
              <Column width="50%">
                <LabeledContainer label="Banka">
                  <Select
                    value={banka_adi}
                    onChange={(entry) => setBank(entry)}
                    list={bankSelectList}
                  />
                </LabeledContainer>
              </Column>
            </Row>
          )}
          {role_yonetim_listesi['Manuel İşlemler'] === '1' && (
            <Row width="100%">
              <Column width="50%">
                <LabeledContainer label="TRX">
                  <input
                    value={trx}
                    onChange={(event) => setTrx(event.target.value)}
                  />
                </LabeledContainer>
              </Column>
              <Column width="50%">
                <LabeledContainer label="Data">
                  <input
                    value={data}
                    onChange={(event) => setData(event.target.value)}
                  />
                </LabeledContainer>
              </Column>
            </Row>
          )}
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Miktar">
                <input
                  type="number"
                  value={miktar}
                  onChange={(event) => setAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%"></Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await createManuelWithdraw();
                setLoading(false);
              }}
              color={Color.green}
              disabled={
                method === 'Havale'
                  ? submitDisabledDeposit
                  : submitDisabledPapara
              }
            >
              Oluştur
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default CreateManuelWithdraw;
