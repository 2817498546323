import React, { useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { RoleInfoFields, RoleInfoInitial } from './types';
import { sliceIntoChunks } from '../../utils/utils';
import { RoleList, RoleListType } from '../../utils/roles';
import { makeRequest } from '../../utils/request';

const EditRole: React.FC = () => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [roleObject, setRoleObject] = useState<RoleInfoFields>(RoleInfoInitial);
  const [filteredRoleList, setFilteredRoleList] =
    useState<RoleListType>(RoleList);
  const { id } = useParams();

  const { getRoleInfoThunk, showMessage, setLoading } = useActions();

  const { roleInfo } = useSelector((state) => state.global);

  const turkishToEnglish = (text:string) => {
    return text.replace('Ğ','g')
        .replace('Ü','u')
        .replace('Ş','s')
        .replace('I','i') 
        .replace('İ','i')
        .replace('Ö','o')
        .replace('Ç','c')
        .replace('ğ','g')
        .replace('ü','u')
        .replace('ş','s')
        .replace('ı','i')
        .replace('ö','o')
        .replace('ç','c');
  }

  useEffect(() => {
    if (!searchText.trim()) {
      setFilteredRoleList(RoleList);
      return;
    }
    const filteredList = Object.keys(
      RoleInfoInitial.role_yonetim_listesi
    ).filter((role) =>
      turkishToEnglish(role).toLowerCase().includes(turkishToEnglish(searchText.trim()).toLowerCase())
    );
    setFilteredRoleList(sliceIntoChunks(filteredList, 4));
  }, [searchText]);

  useEffect(() => {
    setRoleObject({
      isim: roleInfo.isim,
      role_yonetim_listesi: { ...roleInfo.role_yonetim_listesi },
    });
  }, [roleInfo]);

  const getRoleInfo = async () => {
    setLoading(true);
    await getRoleInfoThunk({ id });
    setIsLoaded(true);
    setLoading(false);
  };

  const editRole = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'put',
        url: `/api/v1/roles/${id}`,
        data: {
          isim: roleInfo.isim,
          role_yonetim_listesi: roleObject?.role_yonetim_listesi,
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/yetkiler');
    }
  };

  useEffect(() => {
    getRoleInfo();
  }, []);

  if (!isLoaded) return null;

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Rol #{roleObject.isim}</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <input
                value={searchText}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                style={{ width: '15%', marginBottom: '10px' }}
                placeholder="Filtrele..."
              />
            </div>
          </Row>
          {filteredRoleList.map((row, idx) => {
            return (
              <Row key={idx} width="100%">
                {row.map((column) => {
                  return (
                    <Column key={column} width="25%">
                      <LabeledContainer
                        label={column}
                        className={`${
                          roleObject.role_yonetim_listesi[column] === '1'
                            ? 'active'
                            : 'passive'
                        }`}
                      >
                        <select
                          value={
                            roleObject.role_yonetim_listesi[column] === '1'
                              ? '1'
                              : '0'
                          }
                          onChange={(event) => {
                            const value = event.target.value;

                            setRoleObject((prevState) => {
                              let newState = { ...prevState };
                              newState.role_yonetim_listesi[column] = value;
                              return newState;
                            });
                          }}
                        >
                          <option value="1">Aktif</option>
                          <option value="0">Pasif</option>
                        </select>
                      </LabeledContainer>
                    </Column>
                  );
                })}
              </Row>
            );
          })}
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await editRole();
                setLoading(false);
              }}
              color={Color.green}
            >
              Düzenle
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default EditRole;
