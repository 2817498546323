import React from 'react';
import Column from '../../components/utils/Column/Column';
import { CurrentPaparaFields } from './types';

interface CurrentPaparaDetailProps {
  detail: CurrentPaparaFields;
}

const CurrentPaparaDetail: React.FC<CurrentPaparaDetailProps> = ({
  detail,
}) => {
  return (
    <Column width="25%">
      <div>{detail.price_range.join(' - ')} TL</div>
      <div>
        Toplam <strong>{detail.toplam_kasa}</strong> kasa
      </div>
      <div>
        Sıradaki <strong>{detail.siradaki_kasa}</strong> kasa
      </div>
      <div>
        Ayrılmış <strong>{detail.ayrilmis_kasa}</strong> kasa
      </div>
    </Column>
  );
};

export default CurrentPaparaDetail;
