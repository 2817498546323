import { createAsyncThunk } from '@reduxjs/toolkit';
import { WithdrawFilterParams } from '../../../pages/WithdrawsMFT/types';
import { DataTableValue } from '../../../components/DataTable/types';
import { omitIfEmpty } from '../../../utils/utils';
import { makeRequestThunk } from '../../../utils/request';

export const getWithdrawsMFTThunk = createAsyncThunk(
  'global/getWithdrawsMFT',
  async (
    { page, ...params }: WithdrawFilterParams,
    { rejectWithValue }
  ): Promise<DataTableValue> => {
    const { data, rejected } = await makeRequestThunk<DataTableValue>(
      {
        method: 'get',
        url: '/api/v1/cekim',
        params: {
          page,
          ...omitIfEmpty({ ...params }),
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;
    return data;
  }
);
