import './InfoMessage.scss';
import React from 'react';
import Modal from '../Modal/Modal';
import { useActions, useSelector } from '../../redux/hooks';

export type InfoMessageIconKey = 'success' | 'warning' | 'information';

enum InfoMessageIconEnum {
  success = 'check',
  warning = 'times',
  information = 'exclamation',
}

const InfoMessage: React.FC = () => {
  const { message, showMessage, icon } = useSelector(
    (state) => state.notification
  );

  const { setMessageStatus } = useActions();

  return (
    <Modal
      height="auto"
      width="auto"
      onChange={(isOpen) => {
        setMessageStatus(isOpen);
      }}
      open={showMessage ? showMessage : false}
      zIndex={52}
    >
      <div className={`info-message`}>
        <div className={`info-message-icon ${icon}`}>
          <i className={`las la-${InfoMessageIconEnum[icon]}`} />
        </div>
        <div className={`info-message-text`}>{message}</div>
      </div>
    </Modal>
  );
};

export default InfoMessage;
