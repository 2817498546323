import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreditCardRegisterInfoFields,
  CreditCardRegisterInfoParams,
} from '../../../pages/CreditCardRegisters/types';
import { makeRequestThunk } from '../../../utils/request';

export const getCreditCardRegisterInfoThunk = createAsyncThunk(
  'global/getCreditCardRegisterInfo',
  async (
    { id }: CreditCardRegisterInfoParams,
    { rejectWithValue }
  ): Promise<CreditCardRegisterInfoFields> => {
    const { data, rejected } = await makeRequestThunk<CreditCardRegisterInfoFields>(
      {
        method: 'get',
        url: `/api/v1/creditCardKasa/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;
    
    return data;
  }
);
