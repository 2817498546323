import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  WithdrawBankInfoFields,
  WithdrawBankInfoParams,
} from '../../../pages/WithdrawsBank/types';
import { makeRequestThunk } from '../../../utils/request';

export const getWithdrawBankInfoThunk = createAsyncThunk(
  'global/getWithdrawBankInfo',
  async (
    { id, hidden }: WithdrawBankInfoParams,
    { rejectWithValue }
  ): Promise<WithdrawBankInfoFields> => {
    const { data, rejected } = await makeRequestThunk<WithdrawBankInfoFields>(
      {
        method: 'get',
        url: `/api/v1/cekim/${id}`,
        params: {
          hidden,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
