import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  MFTRegisterInfoFields,
  MFTRegisterInfoParams,
} from '../../../pages/MFTRegisters/types';
import { makeRequestThunk } from '../../../utils/request';

export const getMFTRegisterInfoThunk = createAsyncThunk(
  'global/getMFTRegisterInfo',
  async (
    { id }: MFTRegisterInfoParams,
    { rejectWithValue }
  ): Promise<MFTRegisterInfoFields> => {
    const { data, rejected } = await makeRequestThunk<MFTRegisterInfoFields>(
      {
        method: 'get',
        url: `/api/v1/mftKasa/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
