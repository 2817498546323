import React, { ReactNode, useEffect, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import useDataTable from '../../components/DataTable/DataTable';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Button, { Color, Icon } from '../../components/Button/Button';
import { OrderType } from '../../components/DataTable/types';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

const Users: React.FC = () => {
  const { fraudList, fraudListLoading } = useSelector((state) => state.global);

  const { getFraudsThunk, showMessage, setLoading } = useActions();

  const [secenek, setOption] = useState('Tam isim');
  const [deger, setValue] = useState('');
  const [aciklama, setExplanation] = useState('');
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');

  const [backuser, setBackuser] = useState('');
  const [icerik, setContent] = useState('');
  const [note, setNote] = useState('');

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);

  const submitDisabled = isMissing([deger]);

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    if (index === 5)
      return (
        <Row width="50px" padding="0" height="40px" margin="0 auto">
          <Button
            onClick={async () => {
              const result = window.confirm(
                'Bu fraudu silmek istediğinize emin misiniz?'
              );
              if (result) {
                await deleteFraud(row[0] as string);
              }
            }}
            color={Color.red}
            icon={Icon.close}
          />
        </Row>
      );
  };

  const fetchFraudList = () => {
    getFraudsThunk({
      page,
      pageCount: pagination,
      column,
      order,
      backuser,
      icerik,
      note,
    });
  };

  const createFraud = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'post',
        url: `/api/v1/frauds`,
        data: {
          secenek,
          deger,
          aciklama,
        },
      },
      showMessage
    );
    if (!rejected) {
      fetchFraudList();
      setValue('');
      setExplanation('');
    }
  };

  const deleteFraud = async (id: string) => {
    const { rejected } = await makeRequest(
      {
        method: 'delete',
        url: `/api/v1/frauds/${id}`,
      },
      showMessage
    );
    if (!rejected) {
      fetchFraudList();
    }
  };

  useEffect(() => {
    fetchFraudList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination, backuser, icerik, note]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Fraud Listesi</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="25%">
              <LabeledContainer label="Seçenek">
                <select
                  value={secenek}
                  onChange={(event) => setOption(event.target.value)}
                >
                  <option value="Tam isim">Tam isim</option>
                  <option value="Ip Adresi">IP adresi</option>
                </select>
              </LabeledContainer>
            </Column>
            <Column width="30%">
              <LabeledContainer label="Değer">
                <input
                  value={deger}
                  onChange={(event) => setValue(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="30%">
              <LabeledContainer label="Açıklama">
                <input
                  value={aciklama}
                  onChange={(event) => setExplanation(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="15%">
              <Row padding="0" margin="auto 0" height="50px">
                <Button
                  onClick={async () => {
                    setLoading(true);
                    await createFraud();
                    setLoading(false);
                  }}
                  color={Color.green}
                  disabled={submitDisabled}
                >
                  Ekle
                </Button>
              </Row>
            </Column>
          </Row>
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination,
                searchText
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
                setBackuser(searchText);
                setContent(searchText);
                setNote(searchText);
              }}
              value={fraudList}
              loading={fraudListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              extraColumns={['İşlem']}
              search={true}
              download={true}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default Users;
