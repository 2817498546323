import React from 'react';
import { SelectEntry } from './types';

interface SelectBoxProps {
  id: string | number;
  text: string;
  toggleSelect: (param: SelectEntry) => void;
}

const SelectBox: React.FC<SelectBoxProps> = ({ text, id, toggleSelect }) => {
  return (
    <div
      className="multiselect-box"
      onClick={(event) => event.stopPropagation()}
    >
      <span className="multiselect-box-text">{text}</span>
      <span
        className="multiselect-box-close"
        onClick={(event) => {
          event.stopPropagation();
          toggleSelect({ id, text });
        }}
      >
        ×
      </span>
    </div>
  );
};

export default SelectBox;
