import React, { useEffect, useState } from 'react';
import { Chart, registerables, Ticks } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from '../../redux/hooks';

const Graph: React.FC = () => {
  Chart.register(...registerables);

  const {
    menu: { darkThemeEnabled },
    global: {
      dashboardInfo: {
        context: { grafik_info },
      },
    },
  } = useSelector((state) => state);

  const [graphLabels, setGraphLabels] = useState<string[]>([]);
  const [totalDeposit, setTotalDeposit] = useState<number[]>([]);
  const [totalWithdraw, setTotalWithdraw] = useState<number[]>([]);
  const [totalCommission, setTotalCommission] = useState<number[]>([]);
  const [remaining, setRemaining] = useState<number[]>([]);

  useEffect(() => {
    const totalDeposit: number[] = [];
    const totalWithdraw: number[] = [];
    const totalCommission: number[] = [];
    const remaining: number[] = [];
    setGraphLabels(Object.keys(grafik_info));
    Object.values(grafik_info).forEach((info) => {
      totalDeposit.push(info.toplam_yatirim);
      totalWithdraw.push(info.toplam_cekim);
      totalCommission.push(info.toplam_komisyon);
      remaining.push(info.toplam_kalan);
    });
    setTotalDeposit(totalDeposit);
    setTotalWithdraw(totalWithdraw);
    setTotalCommission(totalCommission);
    setRemaining(remaining);
  }, [grafik_info]);

  return (
    <Line
      options={{
        scales: {
          y: {
            ticks: {
              callback: function (value, index, ticks) {
                return (
                  Ticks.formatters.numeric.apply(this, [
                    value as number,
                    index,
                    ticks,
                  ]) + ' TL'
                );
              },
              color: darkThemeEnabled ? 'white' : '#666666',
            },
            title: {
              display: true,
              text: 'Miktar',
              color: darkThemeEnabled ? 'white' : '#666666',
            },
          },
          x: {
            ticks: {
              color: darkThemeEnabled ? 'white' : '#666666',
            },
            title: {
              display: true,
              text: 'Gün',
              color: darkThemeEnabled ? 'white' : '#666666',
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('tr-TR').format(
                    context.parsed.y
                  );
                }
                return label + ' TL';
              },
            },
          },
          legend: {
            display: true,
            labels: {
              color: darkThemeEnabled ? 'white' : '#666666',
            },
          },
        },
      }}
      data={{
        labels: graphLabels,
        datasets: [
          {
            label: 'Toplam Yatırım',
            backgroundColor: '#28d094',
            borderColor: '#28d094',
            data: totalDeposit,
            tension: 0.2,
          },
          {
            label: 'Toplam Çekim',
            backgroundColor: '#ff4961',
            borderColor: '#ff4961',
            data: totalWithdraw,
            tension: 0.2,
          },
          {
            label: 'Toplam Komisyon',
            backgroundColor: '#1e9ff2',
            borderColor: '#1e9ff2',
            data: totalCommission,
            tension: 0.2,
          },
          {
            label: 'Kalan',
            backgroundColor: '#ff9f40',
            borderColor: '#ff9f40',
            data: remaining,
            tension: 0.2,
          },
        ],
      }}
    />
  );
};

export default Graph;
