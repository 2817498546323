import React, { ReactNode, useEffect, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import useDataTable from '../../components/DataTable/DataTable';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Button, { Color, Icon } from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { OrderType } from '../../components/DataTable/types';

const Roles: React.FC = () => {
  const {
    global: { roleList, roleListLoading },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);

  const { getRolesThunk } = useActions();

  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    if (index === 2 && role_yonetim_listesi['Yetki Rol Düzenleme'] === '1')
      return (
        <Row width="50px" padding="0" height="40px" margin="0 auto">
          <Button
            onClick={() => {
              navigate(`/yetki-duzenle/${row[0]}`);
            }}
            color={Color.orange}
            icon={Icon.edit}
          />
        </Row>
      );
  };

  const fetchRoleList = () => {
    getRolesThunk({ page, pageCount: pagination, column, order });
  };

  useEffect(() => {
    fetchRoleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Yetki Rolleri</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={roleList}
              loading={roleListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              extraColumns={
                role_yonetim_listesi['Yetki Rol Düzenleme'] === '1'
                  ? ['İşlem']
                  : []
              }
              download={true}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default Roles;
