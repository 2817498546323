import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  MerchantInfoFields,
  MerchantInfoParams,
} from '../../../pages/Merchants/types';
import { makeRequestThunk } from '../../../utils/request';

export const getMerchantInfoThunk = createAsyncThunk(
  'global/getMerchantInfo',
  async (
    { id }: MerchantInfoParams,
    { rejectWithValue }
  ): Promise<MerchantInfoFields> => {
    const { data, rejected } = await makeRequestThunk<MerchantInfoFields>(
      {
        method: 'get',
        url: `/api/v1/merchants/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
