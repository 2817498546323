import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewWithdrawCryptoTransferCheck: React.FC = () => {
  const {
    global: { newWithdrawCryptoTransferCreated, newWithdrawCryptoTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewWithdrawCryptoTransfersThunk,
    setNewWithdrawCryptoTransferCreated,
    setNewWithdrawCryptoTransferStatusChanged,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni Kripto Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewWithdrawCryptoTransfersThunk();
        restartTimeout();
      }
    },
    3000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      (newWithdrawCryptoTransferCreated || newWithdrawCryptoTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Kripto Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      if (newWithdrawCryptoTransferStatusChanged) {
        setSoundPlaying('update');
        document.title = 'Müşteri Yatırdım butonuna bastı...';
      } else if (newWithdrawCryptoTransferStatusChanged) {
        setSoundPlaying('bip');
        document.title = 'Yeni Yatırım isteği...';
      }
    }
  }, [
    newWithdrawCryptoTransferCreated,
    newWithdrawCryptoTransferStatusChanged,
    role_yonetim_listesi,
  ]);


  useAsyncTimeout(
    () => {
      if (newWithdrawCryptoTransferCreated || newWithdrawCryptoTransferStatusChanged){
        setNewWithdrawCryptoTransferCreated(false);
        setNewWithdrawCryptoTransferStatusChanged(false);
      }
      document.title = 'Backoffice | Kralpay';
    },
    2000,
    [newWithdrawCryptoTransferCreated, newWithdrawCryptoTransferStatusChanged]
  );  
  
  return <></>;
};

export default NewWithdrawCryptoTransferCheck;
