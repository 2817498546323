import React from 'react';

interface OverlayProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setClosed: React.Dispatch<React.SetStateAction<boolean>>;
  zIndex?: number | undefined;
}

const Overlay: React.FC<OverlayProps> = ({ setIsOpen, setClosed, zIndex }) => {
  return (
    <div
      id="overlay"
      onClick={() => {
        setIsOpen(false);
        setClosed(true);
      }}
      style={{ ...(zIndex ? { zIndex } : {}) }}
    />
  );
};

export default Overlay;
