import './styles.scss';
import React, { ReactNode, useEffect, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { OrderType } from '../../components/DataTable/types';
import useDataTable from '../../components/DataTable/DataTable';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color, Icon } from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import PaparaRegisterIncreaseBalance from './PaparaRegisterIncreaseBalance';
import PaparaRegisterDecreaseBalance from './PaparaRegisterDecreaseBalance';
import { makeRequest } from '../../utils/request';

const PaparaRegisters: React.FC = () => {
  const {
    global: { paparaRegisterList, paparaRegisterListLoading },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);

  const { getPaparaRegistersThunk, showMessage } = useActions();

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);

  const [isim, setName] = useState('');
  const [hesap_no, setAccount] = useState('');
  const [iban, setIban] = useState('');
  const [durum, setStatus] = useState('');
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedName, setSelectedName] = useState('');

  const [isIncreaseModalOpen, setIsIncreaseModalOpen] = useState(false);
  const [isDecreaseModalOpen, setIsDecreaseModalOpen] = useState(false);

  const navigate = useNavigate();

  const disablePaparaRegister = async (id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/paparaKasa/${id}`,
        data: {
          durum: false,
          type: 'durum',
        },
      },
      showMessage
    );
    if (!rejected) {
      fetchPaparaRegisterList();
    }
  };

  const enablePaparaRegister = async (id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/paparaKasa/${id}`,
        data: {
          durum: true,
          type: 'durum',
        },
      },
      showMessage
    );
    if (!rejected) {
      fetchPaparaRegisterList();
    }
  };

  const deletePaparaRegister = async (id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'delete',
        url: `/api/v1/paparaKasa/${id}`,
      },
      showMessage
    );
    if (!rejected) {
      fetchPaparaRegisterList();
    }
  };

  const copyPaparaRegisterInfo = async (row: React.ReactNode[]) => {
    await window.navigator.clipboard.writeText(
       `Hesap sahibi: ${row[1]}
        Hesap No: ${row[2]}
        Iban: ${row[3]}
        Method: Papara`
    );
    showMessage({
      message: 'Bilgiler panoya kopyalandı',
      icon: 'information',
    });
  };

  const showProcessColumn =
    role_yonetim_listesi['Papara Kasa Bakiye Azaltma'] === '1' ||
    role_yonetim_listesi['Papara Kasa Bakiye Ekleme'] === '1' ||
    role_yonetim_listesi['Papara Kasa Ekstresi'] === '1' ||
    role_yonetim_listesi['Papara Kasa Düzenle'] === '1' ||
    role_yonetim_listesi['Papara Kasa Sil'] === '1';

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    if ([3, 7, 8, 9].includes(index)) {
      return value + ' TL';
    }
    if (index === 10) {
      if (value === true) {
        return 'Aktif';
      } else if (value === false) return 'Pasif';
    }
    if (index === 12 && showProcessColumn) {
      return (
        <Column padding="0" margin="0">
          <Row width="100%" padding="0" height="40px">
            <Column width="50%">
              <Button
                onClick={() => {
                  setSelectedId(row[0] as string);
                  setSelectedName(row[1] as string);
                  setIsDecreaseModalOpen(true);
                }}
                color={Color.red}
                icon={Icon.minus}
                tooltip="Bakiye azalt"
                disabled={
                  role_yonetim_listesi['Papara Kasa Bakiye Azaltma'] === '0'
                }
              />
            </Column>
            <Column width="50%" margin="0">
              <Button
                onClick={() => {
                  setSelectedId(row[0] as string);
                  setSelectedName(row[1] as string);
                  setIsIncreaseModalOpen(true);
                }}
                color={Color.green}
                icon={Icon.plus}
                tooltip="Bakiye ekle"
                disabled={
                  role_yonetim_listesi['Papara Kasa Bakiye Ekleme'] === '0'
                }
              />
            </Column>
          </Row>

          <Row width="100%" padding="0" height="40px">
            <Column width="50%">
              <Button
                onClick={() => {
                  navigate(`/papara-kasa-detay/${row[0]}`);
                }}
                color={Color.blue}
                icon={Icon.list}
                tooltip="Ekstreyi göster"
                disabled={role_yonetim_listesi['Papara Kasa Ekstresi'] === '0'}
              />
            </Column>
            <Column width="50%" margin="0">
              <Button
                onClick={() => {
                  navigate(`/papara-kasa-duzenle/${row[0]}`);
                }}
                color={Color.orange}
                icon={Icon.edit}
                tooltip="Düzenle"
                disabled={role_yonetim_listesi['Papara Kasa Düzenle'] === '0'}
              />
            </Column>
          </Row>
          <Row width="100%" padding="0" height="40px">
            <Column width="50%">
              {row[10] === true && (
                <Button
                  onClick={async () => {
                    const result = window.confirm(
                      'Papara Kasayı pasife almak istediğinize emin misiniz?'
                    );
                    if (result) {
                      await disablePaparaRegister(row[0] as number);
                    }
                  }}
                  color={Color.purple}
                  icon={Icon.close}
                  tooltip="Pasif yap"
                  disabled={role_yonetim_listesi['Papara Kasa Düzenle'] === '0'}
                />
              )}
              {row[10] === false && (
                <Button
                  onClick={async () => {
                    const result = window.confirm(
                      'Papara Kasayı aktife almak istediğinize emin misiniz?'
                    );
                    if (result) {
                      await enablePaparaRegister(row[0] as number);
                    }
                  }}
                  color={Color.green}
                  icon={Icon.sync}
                  tooltip="Aktif yap"
                  disabled={role_yonetim_listesi['Papara Kasa Düzenle'] === '0'}
                />
              )}
            </Column>
            <Column width="50%" margin="0">
              <Button
                onClick={async () => {
                  const result = window.confirm(
                    'Papara Kasayı silmek istediğinize emin misiniz?'
                  );
                  if (result) {
                    await deletePaparaRegister(row[0] as number);
                  }
                }}
                color={Color.red}
                icon={Icon.trash}
                tooltip="Kalıcı olarak sil"
                disabled={role_yonetim_listesi['Papara Kasa Sil'] === '0'}
              />
            </Column>
          </Row>
        </Column>
      );
    }
    if (
      (showProcessColumn && index === 13) ||
      (!showProcessColumn && index === 12)
    ) {
      return (
        <Row width="100%" margin="0" padding="0">
          <Column width="35px" height="35px" margin="0" padding="0">
            <Button
              onClick={async () => {
                await copyPaparaRegisterInfo(row);
              }}
              color={Color.gray}
              icon={Icon.copy}
            />
          </Column>
        </Row>
      );
    }
  };

  const fetchPaparaRegisterList = () => {
    getPaparaRegistersThunk({
      isim,
      hesap_no,
      iban,
      durum,
      page,
      pageCount: pagination,
      order,
      column,
    });
  };

  useEffect(() => {
    fetchPaparaRegisterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination]);

  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
    // reference must be same for addEventListener and removeEventListener
    // = can't use inline arrow function!
    function listenerPaparaRegisters(event: { code: string; }) {
        if (event.code === 'Enter') {
            setItWorks(!itWorks);
            fetchPaparaRegisterList();
        }
    }
    fetchPaparaRegisterList();

    document.addEventListener('keypress', listenerPaparaRegisters);
    return () => {
    document.removeEventListener('keypress', listenerPaparaRegisters);
    }
  }, [!itWorks]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Modal
        onChange={(isOpen) => {
          setIsIncreaseModalOpen(isOpen);
        }}
        open={isIncreaseModalOpen}
        width="60vw"
      >
        <PaparaRegisterIncreaseBalance
          id={selectedId}
          name={selectedName}
          callback={() => {
            setIsIncreaseModalOpen(false);
            fetchPaparaRegisterList();
          }}
        />
      </Modal>
      <Modal
        onChange={(isOpen) => {
          setIsDecreaseModalOpen(isOpen);
        }}
        open={isDecreaseModalOpen}
        width="60vw"
      >
        <PaparaRegisterDecreaseBalance
          id={selectedId}
          name={selectedName}
          callback={() => {
            setIsDecreaseModalOpen(false);
            fetchPaparaRegisterList();
          }}
        />
      </Modal>
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Papara Kasalar</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="23%">
              <LabeledContainer label="İsim">
                <input
                  value={isim}
                  onChange={(event) => setName(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="23%">
              <LabeledContainer label="Hesap">
                <input
                  value={hesap_no}
                  type="number"
                  onChange={(event) => setAccount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="23%">
              <LabeledContainer label="Durum">
                <select
                  value={durum}
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <option value="">Hepsi</option>
                  <option value="true">Aktif</option>
                  <option value="false">Pasif</option>
                </select>
              </LabeledContainer>
            </Column>
            <Column width="31%">
              <Row height="40px" padding="0" margin="0">
                <Button
                  onClick={() => {
                    setItWorks(!itWorks);
                    if (page === 1) fetchPaparaRegisterList();
                    setCurrentPage(1);
                  }}
                  color={Color.orange}
                  icon={Icon.search}
                  loading={paparaRegisterListLoading}
                >
                  Filtrele
                </Button>
              </Row>
              <Row height="40px" padding="0" margin="5px 0">
                {role_yonetim_listesi['Papara Kasa Oluştur'] === '1' && (
                  <Column width="100%">
                    <Button
                      onClick={() => {
                        navigate('/papara-kasa-olustur');
                      }}
                      color={Color.green}
                      icon={Icon.plus}
                    >
                      Yeni Kasa
                    </Button>
                  </Column>
                )}
                {role_yonetim_listesi['Papara Kasa Transfer'] === '1' && (
                  <Column width="100%">
                    <Button
                      onClick={() => {
                        navigate('/papara-kasa-transfer');
                      }}
                      color={Color.blue}
                      icon={Icon.sync}
                    >
                      Transfer
                    </Button>
                  </Column>
                )}
              </Row>
            </Column>
          </Row>
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={paparaRegisterList}
              loading={paparaRegisterListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              extraColumns={
                showProcessColumn ? ['İşlem', 'Kopyala'] : ['Kopyala']
              }
              download={true}
              addRows={(rows) => {
                if (rows.length === 0) return [];
                const rowsSum = rows.reduce((acc, row, index, arr) => {
                  return row.map((e, i) => {
                    return (e as number) + (acc[i] as number);
                  });
                }, new Array(rows[0].length).fill(0));
                return showProcessColumn
                  ? [
                      [
                        '',
                        '',
                        '',
                        <b>
                          <i>{rowsSum[3].toFixed(2) + ' TL'}</i>
                        </b>,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                      ],
                    ]
                  : [
                      [
                        '',
                        '',
                        '',
                        <b>
                          <i>{rowsSum[3] + ' TL'}</i>
                        </b>,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                      ],
                    ];
              }}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default PaparaRegisters;
