import './LoginPage.scss';
import React, { useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Button, { Color, Icon } from '../../components/Button/Button';
import Column from '../../components/utils/Column/Column';
import { isMissing } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const { loginThunk } = useActions();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { darkThemeEnabled } = useSelector((state) => state.menu);

  const [loading, setLoading] = useState(false);

  const submitDisabled = isMissing([email, password]);

  const doLogin = async () => {
    setLoading(true);
    await loginThunk({ email, password });
    setLoading(false);
   
  };


  if (!isLoggedIn) {
    return (
      <div className="login-page">
        <Box
          float={true}
          style={{ width: '500px', height: 'auto', padding: '50px 0' }}
        >
          <Column style={{ justifyContent: 'center' }} width="80%">
            <Row width="100%">
              {/* <img
                src={
                  process.env.PUBLIC_URL +
                  '/logo-' +
                  (darkThemeEnabled ? 'light' : 'dark') +
                  '.png'
                }
              /> */}
            </Row>

            <Row width="100%">
              <input
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onKeyUp={(event) => {
                  if (event.key === 'Enter' && !submitDisabled) doLogin();
                }}
                placeholder="Email"
                disabled={loading}
              />
            </Row>
            <Row width="100%">
              <input
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyUp={(event) => {
                  if (event.key === 'Enter') doLogin();
                }}
                placeholder="Şifre"
                disabled={loading}
              />
            </Row>
            <Row height="40px" width="100%">
              <Button
                onClick={() => {
                  doLogin();
                }}
                color={Color.red}
                icon={Icon.lock}
                loading={loading}
                disabled={submitDisabled}
              >
                Giriş Yap
              </Button>
            </Row>
          </Column>
        </Box>
      </div>
    );
  } else {
    return null;
  }
};

export default LoginPage;
