import './Loader.scss';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Overlay from './Overlay';
import { useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const Loader: React.FC = () => {
  const { isLoading } = useSelector((state) => state.notification);

  useAsyncTimeout(
    () => {
      setStartLoading(isLoading);
    },
    250,
    [isLoading]
  );

  useEffect(() => {
    if (!isLoading) setStartLoading(isLoading);
  }, [isLoading]);

  const [startLoading, setStartLoading] = useState(false);

  if (!startLoading) return null;

  return createPortal(
    <Overlay>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Overlay>,
    document.body
  );
};

export default Loader;
