import { configureStore } from '@reduxjs/toolkit';
import menuReducer from './slices/menuSlice';
import authReducer from './slices/authSlice';
import notificationReducer from './slices/notificationSlice';
import globalReducer from './slices/globalSlice';

export const store = configureStore({
  reducer: {
    menu: menuReducer,
    auth: authReducer,
    notification: notificationReducer,
    global: globalReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
