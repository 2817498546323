import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequestThunk } from '../../../utils/request';

export interface CryptoRegisterEntry {
  id: number;
  isim: string;
  bakiye: string;
  durum: boolean;
  yatirim_adet: string;
  cekim_adet: string;
}

export const getCryptoRegisterListThunk = createAsyncThunk(
  'global/getCryptoRegisterList',
  async (_, { rejectWithValue }): Promise<CryptoRegisterEntry[]> => {
    const {
      data: { results },
      rejected,
    } = await makeRequestThunk<{ results: CryptoRegisterEntry[] }>(
      {
        method: 'get',
        url: '/api/v1/cryptoKasa',
        params: {
          pageCount: 100000,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return results;
  }
);
