import React, { useState } from 'react';
import Box from '../../components/Box/Box';
import Row from '../../components/utils/Row/Row';
import {
  TransferItemEntry,
  TransferItemStatus,
  TransferItemStatusEnum,
} from './types';
import Column from '../../components/utils/Column/Column';
import Modal from '../../components/Modal/Modal';
import NewCreditCardTransferDetail from './NewCreditCardTransferDetail';
import { useActions } from '../../redux/hooks';
import Button, { Color, Icon } from '../../components/Button/Button';

interface TransferItemProps {
  transferItem: TransferItemEntry;
}

const CreditCardTransferItem: React.FC<TransferItemProps> = ({
  transferItem: {
    id,
    merchant,
    durum,
    musteri,
    tam_adi,
    miktar,
    sure,
    backuser,
    creditCardNumber,
    expirationMonth,
    expirationYear,
    birthDate,
    securityNumber,
    idNumber,
    verilen_credit_card_no
  },
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { getNewCreditCardTransferInfoThunk, setLoading, showMessage } = useActions();

  const closeModal = () => {
    setIsDetailsOpen(false);
  };

  const copyCreditCardRegisterInfo = async (merchant: string, tam_adi: string, miktar: string, verilen_credit_card_no: string, method: string) => {
    await window.navigator.clipboard.writeText(
      `Tam Adı: ${tam_adi}
Miktar: ${miktar}
Verilen Hesap: ${verilen_credit_card_no}`
    );
    showMessage({
      message: 'Bilgiler panoya kopyalandı',
      icon: 'information',
    });
  };

  return (
    <Row width="100%">
      <Modal
        onChange={(isOpen) => {
          setIsDetailsOpen(isOpen);
        }}
        open={isDetailsOpen}
        width="80vw"
      >
        <NewCreditCardTransferDetail id={id} closeModal={closeModal} />
      </Modal>
      <Box
        onClick={async () => {
          setLoading(true);
          await getNewCreditCardTransferInfoThunk({ id });
          setLoading(false);
          setIsDetailsOpen(true);
        }}
      >
        <Row
          width="100%"
          className={`transfer-item-row ${TransferItemStatusEnum[durum]}`}
        >
          <Column width="33%" className={`transfer-item`}>
            <Row className="transfer-item-field">
              <div>
                Merchant: <strong>{merchant}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Müşteri: <strong>{musteri}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Tam adı: <strong>{tam_adi}</strong>
              </div>
            </Row>
          </Column>
          <Column width="33%" className={`transfer-item`}>
            <Row className="transfer-item-field">
              <div>
                Musteri Kredi Karti No: <strong>{creditCardNumber}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Musteri TC Kimlik No: <strong>{idNumber}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Müşteri Dogum Yil: <strong>{birthDate}</strong>
              </div>
            </Row>
            
          </Column>
          <Column width="33%" className={`transfer-item`}>
          <Row className="transfer-item-field">
              <div>
                Kredi Karti Ay: <strong>{expirationMonth}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Kredi Karti Yil: <strong>{expirationYear}</strong>
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Kredi Karti Guvenlik Kod: <strong>{securityNumber}</strong>
              </div>
            </Row>
          </Column>
          <Column width="33%" className={`transfer-item`}>
            <Row className="transfer-item-field">
              <div>
                İşlem <strong>{sure}</strong> dk önce başladı...
              </div>
            </Row>
            <Row className="transfer-item-field">
              <div>
                Miktar: <strong>{miktar} TL</strong>
              </div>
            </Row>
          </Column>
          <Column width="33%" className={`transfer-item`}>
            <Row className="transfer-item-field">
              <div>
                Durum: <strong>{TransferItemStatus[durum]}</strong>
              </div>
            </Row>
            {verilen_credit_card_no && (
              <Row className="transfer-item-field">
                <div>
                  Verilen Kredi Karti Numarasi: <strong>{verilen_credit_card_no}</strong>
                </div>
              </Row>
            )}
            {backuser && (
              <Row className="transfer-item-field">
                <div>
                  Backuser: <strong>{backuser}</strong>
                </div>
              </Row>
            )}
          </Column>
        </Row>
      </Box>
      <Box style={{ width: '10%', boxShadow: 'none'}}>
        <Row
          width="100%"
        >
            <Column width="35px" height="35px" margin="0" padding="0">
            <Button
              className={`transfer-item-row ${TransferItemStatusEnum[durum]}`}
              style={{color: 'black'}}
              onClick={async () => {
                setLoading(true);
                await copyCreditCardRegisterInfo(merchant, tam_adi, miktar, verilen_credit_card_no, 'Kredit Kart');
                setLoading(false);
              }}
              icon={Icon.copy}
            />
            </Column>
        </Row>
      </Box>
    </Row>
  );
};

export default CreditCardTransferItem;
