import React, { useMemo } from 'react';
import { PageButtonProps, PageButtonsProps } from './types';

const PageButton: React.FC<PageButtonProps> = ({
  className = '',
  onClick,
  children,
}) => (
  <div className={`data-table-page-button ${className}`} onClick={onClick}>
    {children}
  </div>
);

const PageButtons: React.FC<PageButtonsProps> = ({
  pageCount,
  currentPage,
  loading,
  setCurrentPage,
}) => {
  const pageIndexes = useMemo(() => {
    const indexes = [];
    const factor = 3;
    for (let page = 1; page <= pageCount; page++) {
      if (
        (page >= currentPage - factor && page <= currentPage + factor) ||
        (currentPage + factor < pageCount && page === pageCount) ||
        (currentPage - factor > 1 && page === 1)
      )
        indexes.push(page);
      else if (indexes[indexes.length - 1] !== -1) indexes.push(-1);
    }
    return indexes;
  }, [currentPage, pageCount]);

  const previousPage = () => {
    if (loading) return;
    const newPageNumber = currentPage > 1 ? currentPage - 1 : currentPage;
    if (newPageNumber !== currentPage) {
      setCurrentPage(newPageNumber);
    }
  };

  const nextPage = () => {
    if (loading) return;
    const newPageNumber =
      currentPage < pageCount ? currentPage + 1 : currentPage;
    if (newPageNumber !== currentPage) {
      setCurrentPage(newPageNumber);
    }
  };

  return (
    <React.Fragment>
      <PageButton className="prev" onClick={() => previousPage()}>
        Önceki
      </PageButton>
      {pageIndexes.map((pageNumber, index) =>
        pageNumber === -1 ? (
          <PageButton key={index}>...</PageButton>
        ) : (
          <PageButton
            key={index}
            className={pageNumber === currentPage ? 'selected' : ''}
            onClick={(event) => {
              event.stopPropagation();
              if (loading) return;
              if (pageNumber !== currentPage) {
                setCurrentPage(pageNumber);
              }
            }}
          >
            {pageNumber}
          </PageButton>
        )
      )}
      <PageButton className="next" onClick={() => nextPage()}>
        Sonraki
      </PageButton>
    </React.Fragment>
  );
};

export default PageButtons;
