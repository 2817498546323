import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  BankRegisterInfoFields,
  BankRegisterInfoParams,
} from '../../../pages/BankRegisters/types';
import { makeRequestThunk } from '../../../utils/request';

export const getBankRegisterInfoThunk = createAsyncThunk(
  'global/getBankRegisterInfo',
  async (
    { id }: BankRegisterInfoParams,
    { rejectWithValue }
  ): Promise<BankRegisterInfoFields> => {
    const { data, rejected } = await makeRequestThunk<BankRegisterInfoFields>(
      {
        method: 'get',
        url: `/api/v1/bankaKasa/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
