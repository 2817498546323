import React from 'react';

interface ModalFrameProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setClosed: React.Dispatch<React.SetStateAction<boolean>>;
  width?: string | undefined;
  height?: string | undefined;
  zIndex?: number | undefined;
}

const ModalFrame: React.FC<ModalFrameProps> = ({
  children,
  setIsOpen,
  width,
  height,
  setClosed,
  zIndex,
}) => {
  return (
    <div
      style={{
        ...(width ? { width, minWidth: 'unset' } : {}),
        ...(height ? { height, minHeight: 'unset' } : {}),
        ...(zIndex ? { zIndex } : {}),
        maxWidth: '100vw',
        maxHeight: '100vh',
        overflow: 'auto',
      }}
      className="modal-frame"
    >
      <div
        className="modal-close-button"
        onClick={() => {
          setIsOpen(false);
          setClosed(true);
        }}
      >
        <i className="las la-times-circle" />
      </div>
      {children}
    </div>
  );
};

export default ModalFrame;
