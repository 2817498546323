import { useEffect, useRef, useState } from 'react';

interface ObjectType {
  [key: string]: any;
}

export const omitIfEmpty = (obj: ObjectType) => {
  const newObject = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value) {
      Object.assign(newObject, { [key]: value });
    }
  }
  return newObject;
};

export const isMissing = (array: (string | number | undefined)[]) => {
  return (
    array.filter((e) => e !== undefined && e.toString().trim()).length !==
    array.length
  );
};

export const sliceIntoChunks = (arr: any[], chunkSize: number) => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const asyncWait = async (delay: number) => {
  return await new Promise((resolve, reject) => {
    window.setTimeout(() => {
      resolve(true);
    }, delay);
  });
};

interface AsyncTimeout {
  (callback: () => void, delay: number, deps: any[], isInterval?: boolean): [
    number,
    () => void,
    () => void
  ];
}

export const useAsyncTimeout: AsyncTimeout = (
  callback,
  delay,
  deps,
  isInterval?
) => {
  const unmounted = useRef<boolean>(false);
  const idCounter = useRef<number>(1);
  const processList = useRef<number[]>([]);
  const aborted = useRef<boolean>(false);
  const [executionCount, setExecutionCount] = useState(0);

  const addProcess = () => {
    const id = idCounter.current;
    processList.current.push(id);
    idCounter.current++;
    return id;
  };

  const removeProcess = (id: number) => {
    const index = processList.current.indexOf(id);
    processList.current = processList.current.filter((e, i) => i !== index);
  };

  const abortProcess = () => {
    aborted.current = true;
  };

  const restartProcess = () => {
    aborted.current = false;
    processList.current = [];
    execute();
  };

  const execute = async () => {
    const id = addProcess();
    await asyncWait(delay);
    if (
      processList.current.includes(id) &&
      !unmounted.current &&
      !aborted.current
    ) {
      await callback();
      removeProcess(id);
      setExecutionCount((prevState) => prevState + 1);
      if (isInterval) await execute();
    }
  };

  useEffect(() => {
    processList.current = [];
    execute();
  }, [...deps]);

  useEffect(() => {
    return () => {
      processList.current = [];
      unmounted.current = true;
    };
  }, []);

  return [executionCount, abortProcess, restartProcess];
};

export const useAsyncInterval: AsyncTimeout = (
  callback: () => void,
  delay: number,
  deps: any[]
) => {
  return useAsyncTimeout(callback, delay, deps, true);
};
