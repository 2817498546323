import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  MFTTransferItemInfoFields,
  TransferItemInfoParams,
} from '../../../pages/NewMFTTransfer/types';
import { makeRequestThunk } from '../../../utils/request';

export const getNewMFTTransferInfoThunk = createAsyncThunk(
  'global/getNewMFTTransferInfo',
  async (
    { id }: TransferItemInfoParams,
    { rejectWithValue }
  ): Promise<MFTTransferItemInfoFields> => {
    const { data, rejected } =
      await makeRequestThunk<MFTTransferItemInfoFields>(
        {
          method: 'get',
          url: `/api/v1/transactions/mftDeposits/${id}`,
        },
        rejectWithValue
      );

    if (rejected) return rejected;

    return data;
  }
);
