import './styles.scss';
import React, { useEffect } from 'react';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Row from '../../components/utils/Row/Row';
import { useActions, useSelector } from '../../redux/hooks';
import Container from '../../components/utils/Container/Container';
import CurrentPaparaCard from './CurrentPaparaCard';
import CurrentPaparaDetail from './CurrentPaparaDetail';

const CurrentPaparaList: React.FC = () => {
  const { getCurrentPaparaListThunk, setLoading } = useActions();

  const { currentPaparaList } = useSelector((state) => state.global);

  const fetchCurrentPaparaList = async () => {
    setLoading(true);
    await getCurrentPaparaListThunk();
    setLoading(false);
  };

  useEffect(() => {
    fetchCurrentPaparaList();
  }, []);

  return (
    <Container>
      <Row width="100%">
        <Box
          boxTitle={
            <div style={{ textDecoration: 'underline' }}>
              <h3>
                <strong>Güncel Paparalar</strong>
              </h3>
            </div>
          }
        >
          <Column width="100%" margin="0">
            <Row width="100%" style={{ justifyContent: 'flex-start' }}>
              <Column width="25%">
                <div style={{ margin: '0 auto' }}>Toplam</div>
                <div style={{ margin: '0 auto' }}>
                  <strong>{currentPaparaList.toplam}</strong> kasa
                </div>
              </Column>
              {currentPaparaList.siradaki1.info.length > 0 && (
                <CurrentPaparaDetail detail={currentPaparaList.siradaki1} />
              )}
              {currentPaparaList.siradaki2.info.length > 0 && (
                <CurrentPaparaDetail detail={currentPaparaList.siradaki2} />
              )}
              {currentPaparaList.siradaki3.info.length > 0 && (
                <CurrentPaparaDetail detail={currentPaparaList.siradaki3} />
              )}
            </Row>
          </Column>
        </Box>
      </Row>
      {currentPaparaList.siradaki1.info.length > 0 && (
        <Container>
          <Row
            className="current-papara-list-segment"
            margin="40px 0 0 0"
            width="100%"
          >
            Sıradaki ({currentPaparaList.siradaki1.price_range.join(' - ')} TL)
          </Row>
          <Row width="100%" style={{ justifyContent: 'flex-start' }}>
            {[...currentPaparaList.siradaki1.info]
              .sort((a, b) => a.id - b.id)
              .map((info) => (
                <CurrentPaparaCard key={info.id} info={info} />
              ))}
          </Row>
        </Container>
      )}
      {currentPaparaList.siradaki2.info.length > 0 && (
        <Container>
          <Row
            className="current-papara-list-segment"
            margin="40px 0 0 0"
            width="100%"
          >
            Sıradaki ({currentPaparaList.siradaki2.price_range.join(' - ')} TL)
          </Row>
          <Row width="100%" style={{ justifyContent: 'flex-start' }}>
            {[...currentPaparaList.siradaki2.info]
              .sort((a, b) => a.id - b.id)
              .map((info) => (
                <CurrentPaparaCard key={info.id} info={info} />
              ))}
          </Row>
        </Container>
      )}
      {currentPaparaList.siradaki3.info.length > 0 && (
        <Container>
          <Row
            className="current-papara-list-segment"
            margin="40px 0 0 0"
            width="100%"
          >
            Sıradaki ({currentPaparaList.siradaki3.price_range.join(' - ')} TL)
          </Row>
          <Row width="100%" style={{ justifyContent: 'flex-start' }}>
            {currentPaparaList.siradaki3.info.map((info) => (
              <CurrentPaparaCard key={info.id} info={info} />
            ))}
          </Row>
        </Container>
      )}
      {currentPaparaList.ayrilmisHesaplar.info.length > 0 && (
        <Container>
          <Row
            className="current-papara-list-segment"
            margin="40px 0 0 0"
            width="100%"
          >
            Ayrılmış Hesaplar
          </Row>
          <Row width="100%" style={{ justifyContent: 'flex-start' }}>
            {[...currentPaparaList.ayrilmisHesaplar.info]
              .sort((a, b) => a.id - b.id)
              .map((info) => (
                <CurrentPaparaCard key={info.id} info={info} />
              ))}
          </Row>
        </Container>
      )}
    </Container>
  );
};

export default CurrentPaparaList;
