import React, { useEffect, useState } from 'react';
import { useAsyncTimeout } from '../../utils/utils';
import Sound from './Sound';

const SoundLoader: React.FC = () => {
  const [interacted, setInteracted] = useState(false);
  const [loadSound, setLoadSound] = useState(false);

  const onInteraction = () => {
    setInteracted(true);
    window.removeEventListener('focus', onInteraction);
  };

  useEffect(() => {
    window.addEventListener('focus', onInteraction);
    return () => {
      window.removeEventListener('focus', onInteraction);
    };
  }, []);

  const [_, __, restartTimeout] = useAsyncTimeout(
    () => {
      if (interacted) setLoadSound(true);
      else restartTimeout();
    },
    500,
    [interacted]
  );

  if (!loadSound) return null;

  return <Sound />;
};

export default SoundLoader;
