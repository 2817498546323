import axios, { AxiosRequestConfig } from 'axios';
import { showMessageParams } from '../redux/slices/notificationSlice';

//TODO: See examples on getUsersThunk and CreateManuelWithdraw

export interface CustomResponse<T> {
  data: T;
  status: number;
  rejected?: any;
  code?: 'ERR_NETWORK';
}

export const makeRequestThunk = async <T>(
  config: AxiosRequestConfig,
  rejectWithValue: (value: any) => any
): Promise<CustomResponse<T>> => {
  try {
    const { data, status }: CustomResponse<T> = await axios(config);
    return { data, status };
  } catch (error: any) {
    if (error && error.response) {
      const { code } = error;
      const { data, status } = error?.response;
      return {
        data: data ? data : {},
        status,
        code,
        rejected: rejectWithValue({ data, status, code, rejected: true }),
      };
    }
    throw error;
  }
};

export const makeRequest = async <T = any>(
  config: AxiosRequestConfig,
  showMessage: (params: showMessageParams) => void
): Promise<CustomResponse<T>> => {
  try {
    const { data, status }: CustomResponse<T> = await axios(config);
    return { data, status };
  } catch (error: any) {
    if (error && error.response) {
      const { code } = error;
      const { data, status } = error?.response;
      if (code === 'ERR_NETWORK') {
        showMessage({
          message: 'İnternet bağlantınızı kontrol edip lütfen tekrar deneyin.',
          icon: 'warning',
        });
      } else if (data.error_message) {
        showMessage({
          message: data.error_message,
          icon: 'warning',
        });
      } else {
        showMessage({
          message: 'Bir hata oluştu',
          icon: 'warning',
        });
      }
      return { data: data ? data : {}, status, code, rejected: true };
    }
    throw error;
  }
};
