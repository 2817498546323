import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  WithdrawCreditCardInfoFields,
  WithdrawCreditCardInfoParams,
} from '../../../pages/WithdrawsCreditCard/types';
import { makeRequestThunk } from '../../../utils/request';

export const getWithdrawCreditCardInfoThunk = createAsyncThunk(
  'global/getWithdrawCreditCardInfo',
  async (
    { id, hidden }: WithdrawCreditCardInfoParams,
    { rejectWithValue }
  ): Promise<WithdrawCreditCardInfoFields> => {
    const { data, rejected } = await makeRequestThunk<WithdrawCreditCardInfoFields>(
      {
        method: 'get',
        url: `/api/v1/cekim/${id}`,
        params: {
          hidden,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
