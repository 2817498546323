import React, { ReactNode, useEffect, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import useDataTable from '../../components/DataTable/DataTable';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Button, { Color, Icon } from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { OrderType } from '../../components/DataTable/types';

const Users: React.FC = () => {
  const {
    global: { userList, userListLoading },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);

  const { getUsersThunk } = useActions();

  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');
  const [kullanici_adi, setUserName] = useState('');

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);

  const navigate = useNavigate();

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    if (index === 8) {
      if (value === true) {
        return 'Aktif';
      } else if (value === false) return 'Pasif';
    }
    if (role_yonetim_listesi['Kullanıcı düzenleme'] === '1' && index === 9)
      return (
        <Row width="50px" padding="0" height="40px" margin="0 auto">
          <Button
            onClick={() => {
              navigate(`/kullanici-duzenle/${row[0]}`);
            }}
            color={Color.orange}
            icon={Icon.edit}
          />
        </Row>
      );
  };

  const fetchUserList = () => {
    getUsersThunk({
      kullanici_adi,
      page,
      pageCount: pagination,
      column,
      order,
    });
  };

  useEffect(() => {
    fetchUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination, kullanici_adi]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Kullanıcılar</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          {role_yonetim_listesi['Yeni Kullanıcı Oluşturma'] === '1' && (
            <Row width="280px" height="50px" margin="0 0 70px 0">
              <Button
                onClick={() => {
                  navigate('/kullanici-olustur');
                }}
                color={Color.green}
              >
                Yeni Kullanıcı Oluştur
              </Button>
            </Row>
          )}
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination,
                searchText
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
                setUserName(searchText);
              }}
              value={userList}
              loading={userListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              extraColumns={
                role_yonetim_listesi['Kullanıcı düzenleme'] === '1'
                  ? ['İşlem']
                  : []
              }
              search={true}
              download={true}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default Users;
