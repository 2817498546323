import React, { useEffect, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { OrderType } from '../../components/DataTable/types';
import useDataTable from '../../components/DataTable/DataTable';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color, Icon } from '../../components/Button/Button';
import { useParams } from 'react-router-dom';
import DatePicker from '../../components/DatePicker/DatePicker';
import Select from '../../components/Select/Select';
import { SelectEntry } from '../../components/MultiSelect/types';

const PaparaRegisterDetails: React.FC = () => {
  const {
    paparaRegisterDetailsList,
    paparaRegisterDetailsLoading,
    merchantSelectList,
  } = useSelector((state) => state.global);

  const { getPaparaRegisterDetailsThunk } = useActions();

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);
  const [merchant, setSelectedMerchant] = useState<SelectEntry>();
  const [tutar, setAmount] = useState('');
  const [note, setNote] = useState('');

  const [islem_zamani, setUpdateDate] = useState('');
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');

  const { id } = useParams();

  const fetchPaparaRegisterDetailsList = () => {
    getPaparaRegisterDetailsThunk({
      paparaKasaDetayId: id,
      islem_zamani: islem_zamani ? islem_zamani.split(' - ').join(',') : '',
      page,
      pageCount: pagination,
      order,
      column,
      merchant: merchant?.id,
      tutar,
      note,
    });
  };

  useEffect(() => {
    fetchPaparaRegisterDetailsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination]);

  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
    // reference must be same for addEventListener and removeEventListener
    // = can't use inline arrow function!
    function listenerPaparaRegisterDetails(event: { code: string; }) {
        if (event.code === 'Enter') {
            setItWorks(!itWorks);
            fetchPaparaRegisterDetailsList();
        }
    }
    fetchPaparaRegisterDetailsList();

    document.addEventListener('keypress', listenerPaparaRegisterDetails);
    return () => {
    document.removeEventListener('keypress', listenerPaparaRegisterDetails);
    }
  }, [!itWorks]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Papara Kasa Ekstresi</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="20%">
              <LabeledContainer label="Merchant">
                <Select
                  value={merchant}
                  onChange={(entry) => setSelectedMerchant(entry)}
                  list={merchantSelectList}
                />
              </LabeledContainer>
            </Column>
            <Column width="20%">
              <LabeledContainer label="Tutar">
                <input
                  type="number"
                  value={tutar}
                  onChange={(event) => setAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="20%">
              <LabeledContainer label="Note">
                <input
                  value={note}
                  onChange={(event) => setNote(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="20%">
              <LabeledContainer label="Tarih Aralığı">
                <DatePicker
                  value={islem_zamani}
                  onChange={(startDate, endDate) => {
                    if (startDate && endDate)
                      setUpdateDate(startDate + ' - ' + endDate);
                    else setUpdateDate('');
                  }}
                />
              </LabeledContainer>
            </Column>
            <Column width="20%">
              <Row height="40px" padding="0" margin="0">
                <Button
                  onClick={() => {
                    setItWorks(!itWorks);
                    if (page === 1) fetchPaparaRegisterDetailsList();
                    setCurrentPage(1);
                  }}
                  color={Color.green}
                  icon={Icon.search}
                  loading={paparaRegisterDetailsLoading}
                >
                  Filtrele
                </Button>
              </Row>
            </Column>
          </Row>
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={paparaRegisterDetailsList}
              loading={paparaRegisterDetailsLoading}
              page={page}
              pagination={pagination}
              download={true}
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default PaparaRegisterDetails;
