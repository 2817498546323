import { createAsyncThunk } from '@reduxjs/toolkit';
import { makeRequestThunk } from '../../../utils/request';

export interface PayFixRegisterEntry {
  id: number;
  isim: string;
  bakiye: string;
  durum: boolean;
  yatirim_adet: string;
  cekim_adet: string;
}

export const getPayFixRegisterListThunk = createAsyncThunk(
  'global/getPayFixRegisterList',
  async (_, { rejectWithValue }): Promise<PayFixRegisterEntry[]> => {
    const {
      data: { results },
      rejected,
    } = await makeRequestThunk<{ results: PayFixRegisterEntry[] }>(
      {
        method: 'get',
        url: '/api/v1/payfixKasa',
        params: {
          pageCount: 100000,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return results;
  }
);
