import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewMFTTransferCheck: React.FC = () => {
  const {
    global: { newMFTTransferCreated, newMFTTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewMFTTransfersThunk,
    setNewMFTTransferCreated,
    setNewMFTTransferStatusChanged,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni MFT Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewMFTTransfersThunk();
        restartTimeout();
      }
    },
    3000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      role_yonetim_listesi['Yeni MFT Yatırımları / Sonuçlandırma'] === '1' &&
      userLoaded
    ) {
      getNewMFTTransfersThunk();
    }
  }, [role_yonetim_listesi, userLoaded]);

  useEffect(() => {
    if (
      (newMFTTransferCreated || newMFTTransferStatusChanged) &&
      role_yonetim_listesi['Yeni MFT Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      if (newMFTTransferStatusChanged) {
        setSoundPlaying('update');
        document.title = 'Müşteri Yatırdım butonuna bastı...';
      } else if (newMFTTransferCreated) {
        setSoundPlaying('bip');
        document.title = 'Yeni Yatırım isteği...';
      }
    }
  }, [
    newMFTTransferCreated,
    newMFTTransferStatusChanged,
    role_yonetim_listesi,
  ]);

    useAsyncTimeout(
      () => {
        if (newMFTTransferCreated || newMFTTransferStatusChanged){
          setNewMFTTransferCreated(false);
          setNewMFTTransferStatusChanged(false);
        }
        document.title = 'Backoffice | Kralpay';
      },
      2000,
      [newMFTTransferCreated, newMFTTransferStatusChanged]
    );
  

  return <></>;
};

export default NewMFTTransferCheck;
