import { createAsyncThunk } from '@reduxjs/toolkit';
import { TransferItemEntry } from '../../../pages/NewTransfer/types';
import { makeRequestThunk } from '../../../utils/request';

export const getNewTransfersThunk = createAsyncThunk(
  'global/getNewTransfers',
  async (_, { rejectWithValue }): Promise<TransferItemEntry[]> => {
    const {
      data: { results },
      rejected,
    } = await makeRequestThunk<{ results: TransferItemEntry[] }>(
      {
        method: 'get',
        url: '/api/v1/transactions/havaleDeposits',
        params: {
          pageCount: 100000,
        },
      },
      rejectWithValue
    );
    
    if (rejected) return rejected;
    return results;
  }
);
