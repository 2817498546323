import { OrderType } from '../../components/DataTable/types';

export interface PaparaRegisterFilterParams {
  isim: string;
  hesap_no: string;
  iban: string;
  durum: string;
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export interface PaparaRegisterDetailsParams {
  note: string;
  tutar: string;
  merchant?: string | number;
  islem_zamani: string;
  paparaKasaDetayId: string | undefined;
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export interface PaparaRegisterInfoFields {
  isim: string;
  hesap_no: string;
  iban: string;
  yatirim_adet: string;
  cekim_adet: string;
  gelen: string;
  giden: string;
  manuel: string;
  mail_adresi: string;
  mail_sifresi: string;
  min_yatirim: string;
  max_yatirim: string;
  durum: string;
  ozel_not: string;
  pasife_alinma_nedeni: string;
  mail_server: string;
  odeme_alma_durumu: string;
  aylik_komisyonsuz_max_tutar: string;
  kullanan_kisi_sayisi: string;
  kullanim_yatirim_sayisi: string;
  merchants: string[];
  users: string[];
}

export const PaparaRegisterInfoInitial = {
  isim: '',
  hesap_no: '',
  iban: '',
  yatirim_adet: '',
  cekim_adet: '',
  gelen: '',
  giden: '',
  manuel: '',
  mail_adresi: '',
  mail_sifresi: '',
  min_yatirim: '',
  max_yatirim: '',
  durum: '',
  ozel_not: '',
  pasife_alinma_nedeni: '',
  mail_server: '',
  odeme_alma_durumu: '',
  aylik_komisyonsuz_max_tutar: '',
  kullanan_kisi_sayisi: '',
  kullanim_yatirim_sayisi: '',
  merchants: [],
  users: []
};

export interface PaparaRegisterInfoParams {
  id: string | undefined;
}
