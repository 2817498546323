import { useActions, useSelector } from '../../redux/hooks';
import { makeRequest } from '../../utils/request';
import { object, string, TypeOf } from "zod";
import { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Link, useNavigate } from "react-router-dom";
import Button from '../../components/Button/Button';
import Cookies from 'js-cookie';


const validate2faSchema = object({
  token: string().min(1, "Authentication code is required"),
});

export type Validate2faInput = TypeOf<typeof validate2faSchema>;

const Validate2faPage = () => {
  const navigate = useNavigate();
  const {
    auth: {
      currentUser,
    },
  } = useSelector((state) => state);

  const { setIsLoggedIn, setLoading, showMessage } = useActions();

  const {
    handleSubmit,
    setFocus,
    register,
    formState: { errors },
  } = useForm<Validate2faInput>({
    resolver: zodResolver(validate2faSchema),
  });

  const logOut = async () => {
    await makeRequest(
      {
        method: 'post',
        url: `/api/logout`,
        data: {
          user_id: currentUser.id
        }
      },
      showMessage
    );
    Cookies.remove('jwt');
    setIsLoggedIn(false);
    navigate('/login');
  };

  const validate2fa = async (token: string) => {
    try {
      const { data } = await makeRequest<{ otp_valid: boolean }>({
        method: "POST",
        url: "/api/otp/validate",
        data: {
          token,
          user_id: currentUser.id.toString(),
        },
      }, showMessage);

      const otp_valid = data.otp_valid;

      if (otp_valid) {
        navigate("/dashboard");
        window.location.reload();
      } else {
        navigate("/login");
      }
    } catch (error: any) {

      const resMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.response.data.detail ||
        error.message ||
        error.toString();

    }
  };

  const boxStyles: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px',
    width: '400px',
    height: 'auto',
    transition: 'height 0.25s ease',
    position: 'relative', // You can set this explicitly
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#2b2c3c',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)'
  };

  const formStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  };

  const inputField = {
    marginTop: '20px',
    padding: '10px',
    fontSize: '1rem',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    width: '100%',
    color: '#333'
  };

  const redButtonStyle = {
    color: 'white',
    textDecoration: 'none',
    transition: 'color 0.3s',
    backgroundColor: '#ff6347',
    width: '100%',
    padding: '10px 0',
    borderRadius: '0.35rem',
    border: '1px solid #ff6347',
    outline: 'none',
    borderStyle: 'none',
  };

  const greenButtonStyle = {
    color: 'white',
    textDecoration: 'none',
    transition: 'color 0.3s',
    backgroundColor: 'green',
    width: '100%',
    padding: '10px 0',
    borderRadius: '0.35rem',
    border: '1px solid green',
    outline: 'none',
    borderStyle: 'none',
  }

  const onSubmitHandler: SubmitHandler<Validate2faInput> = (values) => {
    validate2fa(values.token);
  };

  const handleButtonClick = () => {
    // Add your custom logic here
  };

  useEffect(() => {
    setFocus("token");
  }, [setFocus]);


  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, []);

  return (
    <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="box" style={boxStyles}>
        <form
          style={formStyle}
          onSubmit={handleSubmit(onSubmitHandler)}
          className="max-w-md w-full mx-auto overflow-hidden shadow-lg bg-ct-dark-200 rounded-2xl p-8 space-y-5"
        >
          <h2 style={{ color: 'white' }}>
            İki Faktörlü Kimlik Doğrulama
          </h2>
          <input style={inputField}
            {...register("token")}
            placeholder="Doğrulama Kodunuzu Giriniz"
          />
          <p className="mt-2 text-xs text-red-600">
            {errors.token ? errors.token.message : null}
          </p>

          <Button onClick={handleButtonClick} style={greenButtonStyle} className="text-ct-approve-600">
            Doğrulama Kodu Girin
          </Button>
          <p className="mt-1 text-xs text-red-600">
          </p>
          <Button
            className="text-ct-blue-600"
            style={redButtonStyle}
            onClick={async () => {
              await logOut();
            }}
          >
            Login Kısmına Dön
          </Button>
        </form>
      </div>
    </section>
  );
};

export default Validate2faPage;