import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PaparaRegisterInfoFields,
  PaparaRegisterInfoParams,
} from '../../../pages/PaparaRegisters/types';
import { makeRequestThunk } from '../../../utils/request';

export const getPaparaRegisterInfoThunk = createAsyncThunk(
  'global/getPaparaRegisterInfo',
  async (
    { id }: PaparaRegisterInfoParams,
    { rejectWithValue }
  ): Promise<PaparaRegisterInfoFields> => {
    const { data, rejected } = await makeRequestThunk<PaparaRegisterInfoFields>(
      {
        method: 'get',
        url: `/api/v1/paparaKasa/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
