import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { loginThunk } from '../thunks/loginThunk';
import { CurrentUserFields, CurrentUserInitial } from '../../pages/Users/types';
import { getCurrentUserThunk } from '../thunks/User/getCurrentUserThunk';
import { routes } from '../../routes/routes';


export interface authState {
  isLoggedIn: boolean;
  currentUser: CurrentUserFields;
  userLoaded: boolean;
  userLoading: boolean;
  availableRoutes: string[];
  routesLoaded: boolean;
  routeExists: boolean;
  currentRoute: string;
}

const initialState: authState = {
  isLoggedIn: false,
  currentUser: CurrentUserInitial,
  userLoaded: false,
  userLoading: true,
  availableRoutes: Object.values(routes),
  routesLoaded: false,
  routeExists: false,
  currentRoute: '',
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
      if (!action.payload) {
        state.userLoaded = false;
        state.currentUser = CurrentUserInitial;
        state.routesLoaded = false;
        state.availableRoutes = Object.values(routes);
        state.routeExists = false;
      }
    },
    setAvailableRoutes: (state, action: PayloadAction<string[]>) => {
      state.availableRoutes = action.payload;
      state.routesLoaded = true;
    },
    setRouteExists: (state, action: PayloadAction<boolean>) => {
      state.routeExists = action.payload;
    },
    setCurrentRoute: (state, action: PayloadAction<string>) => {
      state.currentRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUserThunk.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.userLoaded = true;
      state.userLoading = false;
    });
    builder.addCase(getCurrentUserThunk.pending, (state, action) => {
      state.userLoading = true;
    });
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      state.isLoggedIn = true; 
    });
    builder.addCase(loginThunk.pending, (state, action) => {
      state.isLoggedIn = false;
    });
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.isLoggedIn = false;
    });
  },
});

export const actions = authSlice.actions;

export default authSlice.reducer;
