import React, { ReactNode, useEffect, useState } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import useDataTable from '../../components/DataTable/DataTable';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Button, { Color, Icon } from '../../components/Button/Button';
import { OrderType } from '../../components/DataTable/types';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal/Modal';
import BankRegisterIncreaseBalance from './BankRegisterIncreaseBalance';
import BankRegisterDecreaseBalance from './BankRegisterDecreaseBalance';
import { SelectEntry } from '../../components/MultiSelect/types';
import { makeRequest } from '../../utils/request';

interface PaparaRegisterIncreaseBalanceProps {
    name: string;
}

const BankRegistersInfo: React.FC<
  PaparaRegisterIncreaseBalanceProps
> = ({ name }) => {
    console.log('erey fikin: ', name);
    const {
    global: { bankRegisterList, bankRegisterListLoading, bankSelectList },
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);

  const { getBankRegistersThunk, showMessage } = useActions();

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);

  const [isim, setName] = useState('');
  const [iban, setIban] = useState('');
  const [banka, setBank] = useState<SelectEntry>();
  const [durum, setStatus] = useState('');
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedName, setSelectedName] = useState('');

  const [isIncreaseModalOpen, setIsIncreaseModalOpen] = useState(false);
  const [isDecreaseModalOpen, setIsDecreaseModalOpen] = useState(false);

  const navigate = useNavigate();

  const disableBankRegister = async (id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/bankaKasa/${id}`,
        data: {
          durum: false,
          type: 'durum',
        },
      },
      showMessage
    );
    if (!rejected) {
      fetchBankRegisterList();
    }
  };

  const enableBankRegister = async (id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/bankaKasa/${id}`,
        data: {
          durum: true,
          type: 'durum',
        },
      },
      showMessage
    );
    if (!rejected) {
      fetchBankRegisterList();
    }
  };

  const deleteBankRegister = async (id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'delete',
        url: `/api/v1/bankaKasa/${id}`,
      },
      showMessage
    );
    if (!rejected) {
      fetchBankRegisterList();
    }
  };

  const showProcessColumn =
    role_yonetim_listesi['Banka Set Listesi'] === '1';

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    if (index === 10) {
      if (value === true) {
        return 'Aktif';
      } else if (value === false) return 'Pasif';
    }
    if (index === 11 && showProcessColumn) {
      return (
        <Column padding="0" margin="0">
          <Row width="100%" padding="0" height="40px">
            <Column width="50%">
              <Button
                onClick={() => {
                  setSelectedId(row[0] as string);
                  setSelectedName(row[2] as string);
                  setIsDecreaseModalOpen(true);
                }}
                color={Color.red}
                icon={Icon.minus}
                tooltip="Bakiye azalt"
                disabled={
                  role_yonetim_listesi['Banka Havalesi Bakiye Azaltma'] === '0'
                }
              />
            </Column>
            <Column width="50%" margin="0">
              <Button
                onClick={() => {
                  setSelectedId(row[0] as string);
                  setSelectedName(row[2] as string);
                  setIsIncreaseModalOpen(true);
                }}
                color={Color.green}
                icon={Icon.plus}
                tooltip="Bakiye ekle"
                disabled={
                  role_yonetim_listesi['Banka Havalesi Bakiye Ekleme'] === '0'
                }
              />
            </Column>
          </Row>
          <Row width="100%" padding="0" height="40px">
            <Column width="50%">
              <Button
                onClick={() => {
                  navigate(`/banka-kasa-detay/${row[0]}`);
                }}
                color={Color.blue}
                icon={Icon.list}
                tooltip="Ekstreyi göster"
                disabled={
                  role_yonetim_listesi['Banka Set Listesi'] === '0'
                }
              />
            </Column>
            <Column width="50%" margin="0">
              <Button
                onClick={() => {
                  navigate(`/banka-kasa-duzenle/${row[0]}`);
                }}
                color={Color.orange}
                icon={Icon.edit}
                tooltip="Düzenle"
                disabled={
                  role_yonetim_listesi['Banka Set Listesi'] === '0'
                }
              />
            </Column>
          </Row>
          <Row width="100%" padding="0" height="40px">
            <Column width="50%">
              {row[10] === true && (
                <Button
                  onClick={async () => {
                    const result = window.confirm(
                      'Banka Kasayı pasife almak istediğinize emin misiniz?'
                    );
                    if (result) {
                      await disableBankRegister(row[0] as number);
                    }
                  }}
                  color={Color.purple}
                  icon={Icon.close}
                  tooltip="Pasif yap"
                  disabled={
                    role_yonetim_listesi['Banka Set Listesi'] === '0'
                  }
                />
              )}
              {row[10] === false && (
                <Button
                  onClick={async () => {
                    const result = window.confirm(
                      'Banka Kasayı aktife almak istediğinize emin misiniz?'
                    );
                    if (result) {
                      await enableBankRegister(row[0] as number);
                    }
                  }}
                  color={Color.green}
                  icon={Icon.sync}
                  tooltip="Aktif yap"
                  disabled={
                    role_yonetim_listesi['Banka Havalesi Kasa Düzenle'] === '0'
                  }
                />
              )}
            </Column>
            <Column width="50%" margin="0">
              <Button
                onClick={async () => {
                  const result = window.confirm(
                    'Banka Kasayı silmek istediğinize emin misiniz?'
                  );
                  if (result) {
                    await deleteBankRegister(row[0] as number);
                  }
                }}
                color={Color.red}
                icon={Icon.trash}
                tooltip="Kalıcı olarak sil"
                disabled={
                  role_yonetim_listesi['Banka Havalesi Kasa Sil'] === '0'
                }
              />
            </Column>
          </Row>
        </Column>
      );
    }
  };

  const fetchBankRegisterList = () => {
    getBankRegistersThunk({
      isim: name,
      iban,
      banka: banka?.id as number,
      durum,
      bankType: 'set',
      page,
      pageCount: pagination,
      order,
      column,
    });
  };

  useEffect(() => {
    fetchBankRegisterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, column, pagination]);

  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
      // reference must be same for addEventListener and removeEventListener
      // = can't use inline arrow function!
      function listenerBankRegisters(event: { code: string; }) {
          if (event.code === 'Enter') {
            setItWorks(!itWorks);
            fetchBankRegisterList();
          }
      }
      fetchBankRegisterList();

      document.addEventListener('keypress', listenerBankRegisters);
      return () => {
        document.removeEventListener('keypress', listenerBankRegisters);
      }
  }, [!itWorks]);

  const DataTable = useDataTable<12>();

  return (
    <Row width="100%">
      <Modal
        onChange={(isOpen) => {
          setIsIncreaseModalOpen(isOpen);
        }}
        open={isIncreaseModalOpen}
        width="60vw"
      >
        <BankRegisterIncreaseBalance
          id={selectedId}
          name={selectedName}
          callback={() => {
            setIsIncreaseModalOpen(false);
            fetchBankRegisterList();
          }}
        />
      </Modal>
      <Modal
        onChange={(isOpen) => {
          setIsDecreaseModalOpen(isOpen);
        }}
        open={isDecreaseModalOpen}
        width="60vw"
      >
        <BankRegisterDecreaseBalance
          id={selectedId}
          name={selectedName}
          callback={() => {
            setIsDecreaseModalOpen(false);
            fetchBankRegisterList();
          }}
        />
      </Modal>
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Banka Havalesi Kasalar</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <DataTable
              onPageUpdate={(
                pageNumber,
                orderColumn,
                orderType,
                pagination
              ) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={bankRegisterList}
              loading={bankRegisterListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              extraColumns={
                showProcessColumn ? ['İşlem'] : []
              }
            />
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default BankRegistersInfo;
