import React, { useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import Select from '../../components/Select/Select';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

const CreateUser: React.FC = () => {
  const navigate = useNavigate();

  const { roleSelectList } = useSelector((state) => state.global);

  const { showMessage, setLoading } = useActions();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isim, setName] = useState('');
  const [soyisim, setSurname] = useState('');
  const [kullanici_adi, setUsername] = useState('');
  const [role, setRole] = useState<SelectEntry>();

  const submitDisabled = isMissing([
    role?.id,
    email,
    isim,
    soyisim,
    kullanici_adi,
    password,
  ]);

  const createUser = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'post',
        url: '/api/userCreate',
        data: {
          email,
          password,
          isim,
          soyisim,
          kullanici_adi,
          role: role?.id,
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/kullanicilar');
    }
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Yeni Kullanıcı Oluşturma</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="30%">
              <LabeledContainer label="Role">
                <Select
                  value={role}
                  onChange={(entry) => setRole(entry)}
                  list={roleSelectList}
                />
              </LabeledContainer>
            </Column>
            <Column width="30%">
              <LabeledContainer label="Kullanıcı Adı(Benzersiz)">
                <input
                  value={kullanici_adi}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="30%">
              <LabeledContainer label="Şifre">
                <input
                  type="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="30%">
              <LabeledContainer label="Email(Benzersiz)">
                <input
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="30%">
              <LabeledContainer label="İsim">
                <input
                  value={isim}
                  onChange={(event) => setName(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="30%">
              <LabeledContainer label="Soy İsim">
                <input
                  value={soyisim}
                  onChange={(event) => setSurname(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await createUser();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Oluştur
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default CreateUser;
