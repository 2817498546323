import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewCryptoTransferCheck: React.FC = () => {
  const {
    global: { newCryptoTransferCreated, newCryptoTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewCryptoTransfersThunk,
    setNewCryptoTransferCreated,
    setNewCryptoTransferStatusChanged,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni Kripto Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewCryptoTransfersThunk();
        restartTimeout();
      }
    },
    3000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      role_yonetim_listesi['Yeni Kripto Yatırımları / Sonuçlandırma'] === '1' &&
      userLoaded
    ) {
      getNewCryptoTransfersThunk();
    }
  }, [role_yonetim_listesi, userLoaded]);

  useEffect(() => {
    if (
      (newCryptoTransferCreated || newCryptoTransferStatusChanged) &&
      role_yonetim_listesi['Yeni Kripto Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      if (newCryptoTransferStatusChanged) {
        setSoundPlaying('update');
        document.title = 'Müşteri Yatırdım butonuna bastı...';
      } else if (newCryptoTransferCreated) {
        setSoundPlaying('bip');
        document.title = 'Yeni Yatırım isteği...';
      }
    }
  }, [
    newCryptoTransferCreated,
    newCryptoTransferStatusChanged,
    role_yonetim_listesi,
  ]);

  useAsyncTimeout(
    () => {
      if (newCryptoTransferCreated || newCryptoTransferStatusChanged) {
        setNewCryptoTransferCreated(false);
        setNewCryptoTransferStatusChanged(false);
      }
      document.title = 'Backoffice | Kralpay';
    },
    2000,
    [newCryptoTransferCreated, newCryptoTransferStatusChanged]
  );
  return <></>;
};

export default NewCryptoTransferCheck;
