import React, { useState } from 'react';
import Column from '../../components/utils/Column/Column';
import Box from '../../components/Box/Box';
import { CurrentPaparaInfo } from './types';
import Row from '../../components/utils/Row/Row';
import Button, { Color } from '../../components/Button/Button';
import Container from '../../components/utils/Container/Container';
import Modal from '../../components/Modal/Modal';
import { useActions } from '../../redux/hooks';
import { makeRequest } from '../../utils/request';

interface CurrentPaparaCardProps {
  info: CurrentPaparaInfo;
}

const CurrentPaparaCard: React.FC<CurrentPaparaCardProps> = ({ info }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { getCurrentPaparaListThunk, showMessage, setLoading } = useActions();

  type UpdateParam =
    | 'Kullanim Arttir'
    | 'Kullanim Azalt'
    | 'Yatirim Sayisini Arttir'
    | 'Yatirim Sayisini Azalt'
    | 'Pasif Yap';

  const updateCurrentPapara = async (param: UpdateParam, id: number) => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/guncelPapara/${id}`,
        data: {
          type: param,
        },
      },
      showMessage
    );
    if (!rejected) {
      if (param !== 'Pasif Yap') {
        setIsModalOpen(false);
      }
      getCurrentPaparaListThunk();
    }
  };

  return (
    <Container>
      <Modal
        onChange={(isOpen) => {
          setIsModalOpen(isOpen);
        }}
        open={isModalOpen}
      >
        <div
          style={{
            textAlign: 'center',
            fontSize: '1.3rem',
            marginBottom: '20px',
          }}
        >
          <strong>Liste</strong>
        </div>
        <div
          style={{
            width: '420px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {info.kisiler.map((kisi, idx) => (
            <div
              key={idx}
              style={{
                width: '200px',
                margin: '5px',
                display: 'flex',
                justifyContent: 'center',
                textTransform: 'uppercase',
              }}
            >
              {idx + 1}. {kisi}
            </div>
          ))}
        </div>
        <div style={{ textAlign: 'center', margin: '25px 0' }}>
          Bu kasanın Kişi: {info.max_kullanici_sayisi} / Yatırım:{' '}
          {info.yatirim_kullanim_sayisi} hakkı var.
        </div>
        <Row width="420px" margin="15px auto">
          <Column width="50%" height="40px">
            <Button
              className="current-papara-card-button"
              onClick={async () => {
                setLoading(true);
                await updateCurrentPapara('Kullanim Arttir', info.id);
                setLoading(false);
              }}
              color={Color.green}
              textColor={Color.green}
            >
              +1 Kişi kullanım arttır
            </Button>
          </Column>
          <Column width="50%" height="40px">
            <Button
              className="current-papara-card-button"
              onClick={async () => {
                setLoading(true);
                await updateCurrentPapara('Yatirim Sayisini Arttir', info.id);
                setLoading(false);
              }}
              color={Color.green}
              textColor={Color.green}
            >
              +1 Yatırım kullanım arttır
            </Button>
          </Column>
        </Row>
        <Row width="420px" margin="15px auto">
          <Column width="50%" height="40px">
            <Button
              className="current-papara-card-button"
              onClick={async () => {
                setLoading(true);
                await updateCurrentPapara('Kullanim Azalt', info.id);
                setLoading(false);
              }}
              color={Color.red}
              textColor={Color.red}
            >
              -1 Kişi kullanım azalt
            </Button>
          </Column>
          <Column width="50%" height="40px">
            <Button
              className="current-papara-card-button"
              onClick={async () => {
                setLoading(true);
                await updateCurrentPapara('Yatirim Sayisini Azalt', info.id);
                setLoading(false);
              }}
              color={Color.red}
              textColor={Color.red}
            >
              -1 Yatırım kullanım azalt
            </Button>
          </Column>
        </Row>
      </Modal>
      <Box
        style={{
          width: '250px',
          paddingTop: '30px',
          marginTop: '0',
        }}
      >
        <Column margin="0">
          <div style={{ margin: '0 auto' }}>
            Kasa ID: <strong>{info.kasa_id}</strong>
          </div>
          <div style={{ margin: '0 auto' }}>
            Limit: <strong>{info.limit}</strong>
          </div>
          <div style={{ margin: '0 auto' }}>
            Hesap: <strong>{info.hesap}</strong>
          </div>
          <div style={{ margin: '0 auto' }}>
            Bakiye: <strong>{info.bakiye}</strong>
          </div>
          <div style={{ margin: '0 auto' }}>
            Gelen: <strong>{info.gelen}</strong>
          </div>
          <div style={{ margin: '0 auto' }}>
            İşlem adedi: <strong>{info.islem_adedi}</strong>
          </div>
          <div style={{ margin: '0 auto' }}>
            Kullanım: <strong>{info.kullanim}</strong>
          </div>
          <div style={{ margin: '0 auto' }}>
            Farklı <strong>{info.onay_sayisi}</strong> kişi onaylı
          </div>
          <Row width="250px" margin="15px auto">
            <Column width="50%">
              <Button
                style={{ fontSize: '0.9rem', backgroundColor: 'white' }}
                onClick={() => {
                  setIsModalOpen(true);
                }}
                color={Color.blue}
                textColor={Color.blue}
                disabled={info.kisiler.length === 0}
              >
                Liste
              </Button>
            </Column>
            <Column width="50%">
              <Button
                style={{ fontSize: '0.9rem', backgroundColor: 'white' }}
                onClick={async () => {
                  const result = window.confirm(
                    'Papara kasayı pasife almak istediğinize emin misiniz?'
                  );
                  if (result) {
                    setLoading(true);
                    await updateCurrentPapara('Pasif Yap', info.id);
                    setLoading(false);
                  }
                }}
                color={Color.red}
                textColor={Color.red}
              >
                Pasif Yap
              </Button>
            </Column>
          </Row>
        </Column>
      </Box>
    </Container>
  );
};

export default CurrentPaparaCard;
