import './styles.scss';
import React, { useState, useMemo, useEffect } from 'react';
import { DataTableProps, OrderType } from './types';
import PageButtons from './PageButtons';
import { useAsyncTimeout } from '../../utils/utils';
import Button, { Color, Icon } from '../Button/Button';
import { utils, writeFileXLSX } from 'xlsx';

function humanize(str: string) {
  let i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

// Todo: use columnCount to render missing header and body columns
const useDataTable = <ColumnCount extends number>() => {
  const DataTable: React.FC<DataTableProps<ColumnCount>> = ({
    onPageUpdate,
    value,
    loading,
    page,
    pagination,
    onColumnRender,
    onRowClick,
    onHeaderRender,
    extraColumns,
    addRows,
    columnsToIgnore,
    search,
    download,
    type,
    durum,
    guncelleme,
    method,
    merchants
  }) => {
    const [rows, setRows] = useState<React.ReactNode[][]>([]);
    const [extraRows, setExtraRows] = useState<React.ReactNode[][]>([]);
    const [searchText, setSearchText] = useState('');
    const [searchParam, setSearchParam] = useState('');
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [currentRowCount, setCurrentRowCount] = useState(0);
    const [pageCount, setPageCount] = useState(1);
    const [paginationCount, setPagination] = useState(
      pagination ? pagination : 10
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [orderColumn, setOrderColumn] = useState('');
    const [orderType, setOrderType] = useState<OrderType>('');
    const [header, setHeader] = useState<string[]>([]);
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
    const [noRecord, setNoRecord] = useState(false);
    const [description, setDescription] = useState('');
    
    const setOrdering = (columnName: string) => {
      if (columnName === orderColumn) {
        if (orderType === 'asc') setOrderType('desc');
        if (orderType === 'desc') {
          setOrderType('');
          setOrderColumn('');
        }
      } else {
        setOrderColumn(columnName);
        setOrderType('asc');
      }
    };

    useAsyncTimeout(
      () => {
        setNoRecord(totalRowCount === 0 && !loading);
      },
      500,
      [totalRowCount, loading]
    );

    useEffect(() => {
      if (totalRowCount !== 0 || loading) setNoRecord(false);
    }, [totalRowCount, loading]);

    useAsyncTimeout(
      () => {
        setShowLoadingAnimation(loading);
      },
      1000,
      [loading]
    );

    useEffect(() => {
      if (!loading) setShowLoadingAnimation(loading);
    }, [loading]);

    useEffect(() => {
      if (value) {
        if (value.results.length > 0) setHeader(Object.keys(value.results[0]));
        setRows(value.results.map((e) => Object.values(e)));
        setCurrentRowCount(value.results.length);
        setPageCount(value.total_pages);
        setTotalRowCount(value.count);
      }
    }, [value]);

    useAsyncTimeout(
      () => {
        let value = '';
        if (!loading && totalRowCount !== 0) {
          value = `${totalRowCount} kayıttan ${
            paginationCount * (currentPage - 1) + 1
          } -
            ${paginationCount * (currentPage - 1) + currentRowCount} arasındaki
            kayıtlar gösteriliyor`;
        }
        setDescription(value);
      },
      500,
      [totalRowCount, paginationCount, currentPage, currentRowCount, loading]
    );

    useEffect(() => {
      if (page) setCurrentPage(page);
    }, [page]);

    useEffect(() => {
      if (pagination) {
        setPagination(pagination);
      }
    }, [pagination]);

    useEffect(() => {
      onPageUpdate(
        currentPage,
        orderColumn,
        orderType,
        paginationCount,
        searchParam
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, orderColumn, orderType, searchParam]);

    useEffect(() => {
      setCurrentPage(1);
      onPageUpdate(1, orderColumn, orderType, paginationCount, searchParam);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationCount]);

    useAsyncTimeout(
      () => {
        setSearchParam(searchText.trim());
      },
      500,
      [searchText]
    );

    useEffect(() => {
      if (addRows) {
        setExtraRows(addRows(rows));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows]);

    return (
      <div
        style={{ width: '100%' }}
        onClick={(event) => event.stopPropagation()}
      >
        {search && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <input
              style={{ width: '15%', marginBottom: '10px' }}
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              placeholder="Ara..."
            />
          </div>
        )}
        <table style={{ width: '100%' }} className={'data-table'}>
          <thead>
            <tr className="data-table-headers">
              {header
                .filter((e, i) =>
                  columnsToIgnore ? !columnsToIgnore.includes(i) : true
                )
                .map((e, i) => {
                  let newValue: string | undefined = undefined;
                  if (onHeaderRender) {
                    newValue = onHeaderRender(e, i);
                  }
                  return (
                    <th
                      key={i}
                      className="data-table-header"
                      onClick={() => {
                        if (!newValue && !loading) {
                          setOrdering(e as string);
                        }
                      }}
                    >
                      {humanize(newValue ? newValue : e)}
                      {!newValue && (
                        <div className="data-table-header-sort">
                          <span>
                            <i
                              className={`las la-arrow-down ${
                                e === orderColumn && orderType === 'desc'
                                  ? 'active'
                                  : ''
                              }`}
                            />
                          </span>
                          <span>
                            <i
                              className={`las la-arrow-up ${
                                e === orderColumn && orderType === 'asc'
                                  ? 'active'
                                  : ''
                              }`}
                            />
                          </span>
                        </div>
                      )}
                    </th>
                  );
                })}
              {header.length > 0 &&
                extraColumns
                  ?.filter((e, i) =>
                    columnsToIgnore
                      ? !columnsToIgnore.includes(i + header.length)
                      : true
                  )
                  .map((e, i) => {
                    return (
                      <th key={i} className="data-table-header">
                        {e}
                      </th>
                    );
                  })}
            </tr>
          </thead>
          <tbody
            className={`${loading ? 'loading' : ''}`}
            style={{ position: 'relative' }}
          >
            {(loading || totalRowCount === 0) && (
              <tr className="data-table-loader">
                <td>
                  <div>
                    {showLoadingAnimation && (
                      <div className="lds-facebook">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                    {noRecord && <span>Kayıt bulunamadı</span>}
                  </div>
                </td>
              </tr>
            )}
            {rows.map((row, i) => {
              return (
                <tr
                  key={i}
                  className={
                    'data-table-row ' +
                    ((i + 1) % 2 === 0 ? 'even' : 'odd') +
                    (onRowClick ? ' onhover' : '')
                  }
                  onClick={() => {
                    if (onRowClick) {
                      onRowClick(i, row);
                    }
                  }}
                >
                  {row
                    .concat(
                      new Array(extraColumns ? extraColumns.length : 0).fill('')
                    )
                    .filter((e, i) =>
                      columnsToIgnore ? !columnsToIgnore.includes(i) : true
                    )
                    .map((e, i) => {
                      if (onColumnRender) {
                        const newValue = onColumnRender(e, i, row, header[i]);
                        if (newValue) e = newValue;
                      }
                      return (
                        <td key={i} className="data-table-column">
                          {e}
                        </td>
                      );
                    })}
                </tr>
              );
            })}
            {extraRows.map((row, i) => {
              return (
                <tr key={i} className={'data-table-row odd'}>
                  {row.map((e, i) => {
                    return (
                      <td key={i} className="data-table-column">
                        {e}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="data-table-pagination">
          <div>{description}</div>
          {type && (
            <div>
            <Button
              style={{ marginRight: '10px', width: '50px' }}
              onClick={async () => {
                try {
                  // Send a request to your backend API
                  const response = await fetch(`/api/v1/download/data/?type=${type}&durum=${durum}&guncelleme=${guncelleme}&method=${method}&merchants=${merchants}`, {
                    method: 'GET', // or 'POST' depending on your API
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  });
          
                  // Check if the request was successful
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  
                  const data = await response.json();
                  const rows = data.rows.map((row: any) => 
                    Object.values(row).map((value: any) => 
                      value instanceof Number ? parseFloat(value.toString()) : value
                    )
                  );
          
                  // Transform the data into a format suitable for excel
                  const ws = utils.json_to_sheet(
                    [
                      data.header.map(humanize), // Assuming your API returns a 'header' key
                      ...rows,
                    ],
                    {
                      skipHeader: true,
                    }
                  );
          
                  // Create a new workbook and append the worksheet
                  const wb = utils.book_new();
                  utils.book_append_sheet(wb, ws, 'Data');
          
                  // Trigger the file download
                  writeFileXLSX(wb, 'Table_Export.xlsx');
                } catch (error) {
                  console.error('Error:', error);
                }
              }}
              color={Color.purple}
              icon={Icon.download}
              tooltip="İndir"
            />
          </div>
          )}
          {download && (
            <div>
              <Button
                style={{ marginRight: '10px', width: '50px' }}
                onClick={() => {
                  const ws = utils.json_to_sheet(
                    [
                      header.map(humanize),
                      ...rows.map((row) => {
                        return row.map((e, i) => {
                          if (onColumnRender) {
                            const value = onColumnRender(e, i, row, header[i]);
                            if (value) return value;
                            else return e;
                          }
                        });
                      }),
                    ],
                    {
                      skipHeader: true,
                    }
                  );
                  const wb = utils.book_new();
                  utils.book_append_sheet(wb, ws, 'Data');
                  writeFileXLSX(wb, 'Table_Export.xlsx');
                }}
                color={Color.green}
                icon={Icon.download}
                tooltip="İndir"
              />
            </div>
          )}
          <select
            className="default"
            value={paginationCount}
            onChange={(event) => setPagination(parseInt(event.target.value))}
          >
            <option>10</option>
            <option>20</option>
            <option>50</option>
            <option>100</option>
            <option>250</option>
            <option>500</option>
            <option>1000</option>
            <option>5000</option>
            <option>10000</option>
          </select>
          <PageButtons
            pageCount={pageCount}
            currentPage={currentPage}
            loading={loading}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => DataTable, []);
};

export default useDataTable;
