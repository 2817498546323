import React, { useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import Select from '../../components/Select/Select';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

const CreditCardRegisterTransfer: React.FC = () => {
  const { merchantSelectList, creditCardRegisterSelectList } = useSelector(
    (state) => state.global
  );

  const navigate = useNavigate();

  const { showMessage, setLoading } = useActions();

  const [miktar, setAmount] = useState('');
  const [masraf, setCost] = useState('');
  const [aciklama, setDescription] = useState('');

  const [fromMerchant, setSelectedMerchant] = useState<SelectEntry>();
  const [toMerchant, setSelectedMerchant2] = useState<SelectEntry>();

  const [fromKasa, setSelectedRegister] = useState<SelectEntry>();
  const [toKasa, setSelectedRegister2] = useState<SelectEntry>();

  const submitDisabled = isMissing([
    fromMerchant?.id,
    toMerchant?.id,
    fromKasa?.id,
    toKasa?.id,
    miktar,
    masraf,
  ]);

  const transferCreditCardRegister = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'post',
        url: '/api/v1/transfer',
        data: {
          type: 'Kredi Kart',
          miktar: parseFloat(miktar),
          masraf: parseFloat(masraf),
          fromKasa: fromKasa?.id,
          toKasa: toKasa?.id,
          fromMerchant: fromMerchant?.id,
          toMerchant: toMerchant?.id,
          aciklama,
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/kripto-kasalar');
    }
  };

  return (
    <Row width="100%" className="credit-card-register-transfer">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Kredi Kart Kasa Transfer</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <span className="to">Nereden</span>
              <LabeledContainer label="Merchant">
                <Select
                  value={fromMerchant}
                  onChange={(entry) => setSelectedMerchant(entry)}
                  list={merchantSelectList}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <span className="where">Nereye</span>
              <LabeledContainer label="Merchant">
                <Select
                  value={toMerchant}
                  onChange={(entry) => setSelectedMerchant2(entry)}
                  list={merchantSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Kasa">
                <Select
                  value={fromKasa}
                  onChange={(entry) => setSelectedRegister(entry)}
                  list={creditCardRegisterSelectList}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Kasa">
                <Select
                  value={toKasa}
                  onChange={(entry) => setSelectedRegister2(entry)}
                  list={creditCardRegisterSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Miktar">
                <input
                  type="number"
                  value={miktar}
                  onChange={(event) => setAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Masraf">
                <input
                  type="number"
                  value={masraf}
                  onChange={(event) => setCost(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="100%">
              <LabeledContainer label="Açıklama">
                <input
                  value={aciklama}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await transferCreditCardRegister();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Transfer Et
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default CreditCardRegisterTransfer;
